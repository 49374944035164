import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-prospect-detail-renew',
  templateUrl: './prospect-detail-renew.component.html',
  styleUrls: ['./prospect-detail-renew.component.css']
})
export class ProspectDetailRenewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private domSanitizer: DomSanitizer
  ) { }

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  viewMode = 'tab1';
  idDetail: any;
  dataProspectDetail: any;
  dateToday: any;

  inputContractNumber: any;
  inputMobileNo2: any;

  hasBeenCalledLov: any;
  connectivityLov: any;
  contactivityLov: any;
  probingActionLov: any;
  resultLov: any;
  interestedStatusLov: any;
  resultReasonLov: any;
  tenorPerubahanAsuransiLov: any;
  skemaPembayaranLov: any;

  hasBeenCalledSelected: any;
  hasBeenCalledKeySelected: any = "";
  hasBeenCalledReason: any = "";

  connectivitySelected: any;
  connectivityKeySelected: any = "";

  contactivitySelected: any;
  contactivityKeySelected: any = "";

  probingActionSelected: any;
  probingActionKeySelected: any = "";

  resultSelected: any;
  resultKeySelected: any = "";
  interestedStatusSelected: any;
  interestedStatusKeySelected: any = "";
  resultReasonSelected: any;
  resultReasonKeySelected: any = "";

  promiseDate: NgbDateStruct;
  promiseDateString: any = "";

  tenorPerubahanAsuransiSelected: any;
  tenorPerubahanAsuransiKeySelected: any = "";

  skemaPembayaranSelected: any;
  skemaPembayaranKeySelected: any = "";

  description: any = "";

  srcImgTemp: any = '../../../assets/images/drop-file.png';
  base64ImgString: any = "";
  srcFileName: any = "";

  isPopulated: any = 0;

  showButtonShowFileExisting: any = false;
  showButtonShowImageExisting: any = false;

  formProspect: any = {
    id: null,
    userCrt: null,
    prospectResult: {
      isDraft: null,
      isFinal: null,
      hasBeenCalled: null,
      connectivity: null,
      contactivity: null,
      probingAction: null,
      result: null,
      resultReason: null,
      interestedStatus: null,
      tenorPerubahanAsuransi: null,
      skemaPembayaran: null,
      promiseDate: null,
      description: null,
      attachment: null
    }
  };

  minDatePicker: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, 
    day: new Date().getDate()
  }

  ngOnInit(): void {
    this.idDetail = this.route.snapshot.params.id;

    let tmpRequest = {
      id: this.idDetail
    };

    this.apiUtil.invokeAPI("PROSPECT_TM_DETAIL", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.dataProspectDetail = res.data;
        // console.log("Prospect Detail", this.dataProspectDetail);
        this.getLovHasBeenCalled('L013');
        this.getLovConnectivity('L002');
        this.getLovContactivity('L007');
        this.getLovProbingAction();
        this.getLovResult('L009');
        this.getLovInterestedStatus('L011');        
        this.getLovResultReason('L005');
        this.getLovTenorPerubahanAsuransi('L015');
        this.getLovSkemaPembayaran('L016');
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovHasBeenCalled(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.hasBeenCalledLov = res.data;
        // this.hasBeenCalledKeySelected = this.hasBeenCalledLov[0].key;
        // this.hasBeenCalledSelected = this.hasBeenCalledLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovConnectivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.connectivityLov = res.data;
        // this.connectivityKeySelected = this.connectivityLov[0].key;
        // this.connectivitySelected = this.connectivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovContactivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.contactivityLov = res.data;
        // this.contactivityKeySelected = this.contactivityLov[0].key;
        // this.contactivitySelected = this.contactivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovProbingAction(){
    this.spinner.show();

    let tmpRequest = {
      type: "L008"
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.probingActionLov = res.data;
        // this.probingActionKeySelected = this.probingActionLov[0].key;
        // this.probingActionSelected = this.probingActionLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResult(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultLov = res.data;
        // this.resultKeySelected = this.resultLov[0].key;
        // this.resultSelected = this.resultLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovInterestedStatus(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.interestedStatusLov = res.data;
        // this.interestedStatusKeySelected = this.interestedStatusLov[0].key;
        // this.interestedStatusSelected = this.interestedStatusLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResultReason(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultReasonLov = res.data;
        // this.resultReasonKeySelected = this.resultReasonLov[0].key;
        // this.resultReasonSelected = this.resultReasonLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovTenorPerubahanAsuransi(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.tenorPerubahanAsuransiLov = res.data;
        // this.tenorPerubahanAsuransiKeySelected = this.tenorPerubahanAsuransiLov[0].key;
        // this.tenorPerubahanAsuransiSelected = this.tenorPerubahanAsuransiLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovSkemaPembayaran(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.skemaPembayaranLov = res.data;
        // this.skemaPembayaranKeySelected = this.skemaPembayaranLov[0].key;
        // this.skemaPembayaranSelected = this.skemaPembayaranLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  hasBeenCalledOnChange(key, value){
    this.hasBeenCalledKeySelected = key
    this.hasBeenCalledSelected = value;

    //Reset value if not has been called
    if(key != 'L013-1' ){
      this.connectivityKeySelected = null;
      this.connectivitySelected = null;

      this.contactivityKeySelected = null;
      this.contactivitySelected = null;

      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.tenorPerubahanAsuransiKeySelected = null;
      this.tenorPerubahanAsuransiSelected = null;

      this.skemaPembayaranKeySelected = null;
      this.skemaPembayaranSelected = null;

      this.promiseDate = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  connectivityOnChange(key, value){
    this.connectivityKeySelected = key;
    this.connectivitySelected = value;

    //Reset value if not connected
    if(key != 'L001-1' && key != 'L002-1'){
      this.contactivityKeySelected = null;
      this.contactivitySelected = null;

      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.tenorPerubahanAsuransiKeySelected = null;
      this.tenorPerubahanAsuransiSelected = null;

      this.skemaPembayaranKeySelected = null;
      this.skemaPembayaranSelected = null;

      this.promiseDate = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  contactivityOnChange(key, value){
    this.contactivityKeySelected = key
    this.contactivitySelected = value;

    //Reset value if not contacted
    if(key != 'L006-1' && key != 'L007-1'){
      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.tenorPerubahanAsuransiKeySelected = null;
      this.tenorPerubahanAsuransiSelected = null;

      this.skemaPembayaranKeySelected = null;
      this.skemaPembayaranSelected = null;

      this.promiseDate = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  probingActionOnChange(key, value){
    this.probingActionKeySelected = key
    this.probingActionSelected = value;
  }

  resultOnChange(key, value){
    this.resultKeySelected = key
    this.resultSelected = value;

    if(key == "L009-3"){
      this.promiseDate = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;
      
      this.tenorPerubahanAsuransiKeySelected = null;
      this.tenorPerubahanAsuransiSelected = null;

      this.skemaPembayaranKeySelected = null;
      this.skemaPembayaranSelected = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    else if(key == "L009-1"){
      this.promiseDate = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;
    }
    else if(key == "L009-2"){
      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;
    }
  }

  resultReasonOnChange(key, value){
    this.resultReasonKeySelected = key
    this.resultReasonSelected = value;
  }

  interestedStatusOnChange(key, value){
    this.interestedStatusKeySelected = key
    this.interestedStatusSelected = value;
  }

  tenorPerubahanAsuransiOnChange(key, value){
    this.tenorPerubahanAsuransiKeySelected = key
    this.tenorPerubahanAsuransiSelected = value;
  }

  skemaPembayaranOnChange(key, value){
    this.skemaPembayaranKeySelected = key
    this.skemaPembayaranSelected = value;
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.base64ImgString = reader.result;
      if(this.base64ImgString.includes("application/pdf")){
        this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
        this.srcFileName = file.name;
      }
      else{
        this.srcImgTemp = (this.domSanitizer.bypassSecurityTrustResourceUrl(this.base64ImgString) as any).changingThisBreaksApplicationSecurity;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
       console.log("No file selected!");
       return
    }
    let file: File = event.target.files[0];

    if(file.size < (5000 * 1024)){
      await this.getBase64(file);
    }
    else{
      this.toastr.error("Attachment more than 5 MB");
    }
  }

  clearImgUpload(){
    this.srcImgTemp = "../../../assets/images/drop-file.png"; 
    this.base64ImgString = null;
    this.srcFileName = "";

    this.showButtonShowFileExisting = false;
    this.showButtonShowImageExisting = false;
  }

  compareDate(dateSla){
    let dateSlaRaw = new Date(dateSla);
    let dateSlaa = moment(dateSlaRaw).format('YYYY-MM-DD HH:mm:ss');

    let dateToday = moment().format('YYYY-MM-DD HH:mm:ss');

    if(dateSlaa < dateToday){
      return true;
    }
    else{
      return false;
    }
  }

  changeContractNo(){
    let tmpRequest = {
      id: this.idDetail,
      type: "contractNumber",
      value: this.inputContractNumber
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  checkMobileNo2(){
    if (this.inputMobileNo2[0] != '0'){
      this.toastr.error("Invalid Format Mobile Phone");
    }
    else if ((this.inputMobileNo2[0] == '0') && (this.inputMobileNo2.length >= '5')){ 
      this.changeMobileNo2();
    }
    else if(this.inputMobileNo2.length < '5' ) {
      this.toastr.error("Mobile number error at least 5 digits");
    }
    else if(this.inputMobileNo2 == "" ) {
      this.toastr.error("Please Fill Mobile Phone ");
    }
  }

  changeMobileNo2(){
    let tmpRequest = {
      id: this.idDetail,
      type: "mobilePhone2",
      value: this.inputMobileNo2
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  openFileExisting(){
    let base64String  = this.dataProspectDetail.prospectResult.attachment.split(',')[1];
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  openImage(){
    let base64String  = this.dataProspectDetail.prospectResult.attachment;
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName + '.' + base64String.split('/')[1].split(';')[0];
    downloadLink.click();
  }

  populateData(){
    if(this.isPopulated == 0){
      //Set Prospect Result if exist
      if(this.hasBeenCalledLov){
        this.hasBeenCalledKeySelected = this.dataProspectDetail.prospectResult.hasBeenCalled != null ? this.dataProspectDetail.prospectResult.hasBeenCalled : null;
        if(this.hasBeenCalledKeySelected != null){
          this.hasBeenCalledSelected = this.hasBeenCalledLov.find(el => el.key === this.hasBeenCalledKeySelected).value ? this.hasBeenCalledLov.find(el => el.key === this.hasBeenCalledKeySelected).value : null;
        }
        else{
          this.hasBeenCalledSelected = null;
        }
      }

      if(this.interestedStatusLov){
        this.interestedStatusKeySelected = this.dataProspectDetail.prospectResult.interestedStatus != null ? this.dataProspectDetail.prospectResult.interestedStatus : null;
        if(this.interestedStatusKeySelected != null){
          this.interestedStatusSelected = this.interestedStatusLov.find(el => el.key === this.interestedStatusKeySelected).value ? this.interestedStatusLov.find(el => el.key === this.interestedStatusKeySelected).value : null;
        }
        else{
          this.interestedStatusSelected = null;
        }
      }

      if(this.resultReasonLov){
        this.resultReasonKeySelected = this.dataProspectDetail.prospectResult.resultReason != null ? this.dataProspectDetail.prospectResult.resultReason : null;
        if(this.resultReasonKeySelected != null){
          this.resultReasonSelected = this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value ? this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value : null;
        }
        else{
          this.resultReasonSelected = null;
        }
      }

      this.connectivityKeySelected = this.dataProspectDetail.prospectResult.connectivity != null ? this.dataProspectDetail.prospectResult.connectivity : null;
      if(this.connectivityKeySelected != null){
        this.connectivitySelected = this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value ? this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value : null;
      }
      else{
        this.connectivitySelected = null;
      }

      this.contactivityKeySelected = this.dataProspectDetail.prospectResult.contactivity != null ? this.dataProspectDetail.prospectResult.contactivity : null;
      if(this.contactivityKeySelected != null){
        this.contactivitySelected = this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value ? this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value : null;
      }
      else{
        this.contactivitySelected = null;
      }

      this.probingActionKeySelected = this.dataProspectDetail.prospectResult.probingAction != null ? this.dataProspectDetail.prospectResult.probingAction : null;
      if(this.probingActionKeySelected != null){
        this.probingActionSelected = this.probingActionLov.find(el => el.key === this.probingActionKeySelected).value ? this.probingActionLov.find(el => el.key === this.probingActionKeySelected).value : null;
      }
      else{
        this.probingActionSelected = null;
      }

      this.resultKeySelected = this.dataProspectDetail.prospectResult.result != null ? this.dataProspectDetail.prospectResult.result : null;
      if(this.resultKeySelected != null){
        this.resultSelected = this.resultLov.find(el => el.key === this.resultKeySelected).value ? this.resultLov.find(el => el.key === this.resultKeySelected).value : null;
      }
      else{
        this.resultSelected = null;
      }

      this.tenorPerubahanAsuransiKeySelected = this.dataProspectDetail.prospectResult.tenorPerubahanAsuransi != null ? this.dataProspectDetail.prospectResult.tenorPerubahanAsuransi : null;
      if(this.tenorPerubahanAsuransiKeySelected != null){
        this.tenorPerubahanAsuransiSelected = this.tenorPerubahanAsuransiLov.find(el => el.key === this.tenorPerubahanAsuransiKeySelected).value ? this.tenorPerubahanAsuransiLov.find(el => el.key === this.tenorPerubahanAsuransiKeySelected).value : null;
      }
      else{
        this.tenorPerubahanAsuransiSelected = null;
      }

      this.skemaPembayaranKeySelected = this.dataProspectDetail.prospectResult.skemaPembayaran != null ? this.dataProspectDetail.prospectResult.skemaPembayaran : null;
      if(this.skemaPembayaranKeySelected != null){
        this.skemaPembayaranSelected = this.skemaPembayaranLov.find(el => el.key === this.skemaPembayaranKeySelected).value ? this.skemaPembayaranLov.find(el => el.key === this.skemaPembayaranKeySelected).value : null;
      }
      else{
        this.skemaPembayaranSelected = null;
      }

      if(this.dataProspectDetail.prospectResult.promiseDate != null){
        //Assign value to ngbDate
        let dateCustomer = moment(this.dataProspectDetail.prospectResult.promiseDate, "YYYY-MM-DD").format("YYYY-M-D");
        let yearSt = Number(dateCustomer.split('-')[0]);
        let monthSt = Number(dateCustomer.split('-')[1]);
        let daySt = Number(dateCustomer.split('-')[2]);
        this.promiseDate = new NgbDate(yearSt, monthSt, daySt);
      }
      else{
        this.promiseDate = null;
      }


      this.description = this.dataProspectDetail.prospectResult.description != null ? this.dataProspectDetail.prospectResult.description : null;

      if(this.dataProspectDetail.prospectResult.attachment != null){
        if(this.dataProspectDetail.prospectResult.attachment.split(',')[0].includes("image")){
          this.srcImgTemp = this.dataProspectDetail.prospectResult.attachment != null ? this.dataProspectDetail.prospectResult.attachment : "../../../assets/images/drop-file.png"; 
          this.showButtonShowImageExisting = true;
        }
        else{
          this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
          this.showButtonShowFileExisting = true;
        }
      }
      else{
        this.srcImgTemp = "../../../assets/images/drop-file.png"; 
      }
      //End

      this.isPopulated = 1;
    }
    else{

    }
  }

  submitForm(){
    this.formProspect.id = this.idDetail;
    this.formProspect.userCrt = this.userInfo.userId;
    this.formProspect.prospectResult.isDraft = "N";
    this.formProspect.prospectResult.isFinal = "Y";

    if(this.hasBeenCalledKeySelected != null && this.hasBeenCalledKeySelected != ""){
      if(this.hasBeenCalledKeySelected == "L013-1"){
        //If Has Been Called
        if(this.connectivityKeySelected != null && this.connectivityKeySelected != ""){
          if(this.connectivityKeySelected == "L002-1"){
            //If Connected
            if(this.contactivityKeySelected != null && this.contactivityKeySelected != ""){
              if(this.contactivityKeySelected == "L007-1"){
                //If Connected & Contacted
                if(this.probingActionKeySelected == null || this.probingActionKeySelected == ""){
                  this.toastr.error("Please Fill Probing Action");
                }
                //Check Result Status if null
                if(this.resultKeySelected == null || this.resultKeySelected == ""){
                  this.toastr.error("Please Fill Result");
                }
                if(this.resultKeySelected != null || this.resultKeySelected != ""){
                  if(this.resultKeySelected == "L009-1"){
                    //Check Interested Status if null
                    if(this.interestedStatusKeySelected == null || this.interestedStatusKeySelected == ""){
                      this.toastr.error("Please Fill Interested Status");
                    }
                    //Check Tenor Perubahan Asuransi if null
                    if(this.tenorPerubahanAsuransiKeySelected == null || this.tenorPerubahanAsuransiKeySelected == ""){
                      this.toastr.error("Please Fill Tenor Perubahan Asuransi");
                    }
                    //Check Skema Pembayaran if null
                    if(this.skemaPembayaranKeySelected == null || this.skemaPembayaranKeySelected == ""){
                      this.toastr.error("Please Fill Skema Pembayaran");
                    }
                    else if(
                      (this.interestedStatusKeySelected != null && this.interestedStatusKeySelected != "") &&
                      (this.tenorPerubahanAsuransiKeySelected != null && this.tenorPerubahanAsuransiKeySelected != "") &&
                      (this.skemaPembayaranKeySelected != null && this.skemaPembayaranKeySelected != "")
                    ){
                      //Pass hit submit
                      this.hitSubmitIR();
                    }
    
                  }
                  if(this.resultKeySelected == "L009-2"){
                    //Check fu Date if null
                    if(this.promiseDate == null || this.promiseDate == undefined){
                      this.toastr.error("Please Fill Promise Date");
                    }
                    //Check Tenor Perubahan Asuransi if null
                    if(this.tenorPerubahanAsuransiKeySelected == null || this.tenorPerubahanAsuransiKeySelected == ""){
                      this.toastr.error("Please Fill Tenor Perubahan Asuransi");
                    }
                    //Check Skema Pembayaran if null
                    if(this.skemaPembayaranKeySelected == null || this.skemaPembayaranKeySelected == ""){
                      this.toastr.error("Please Fill Skema Pembayaran");
                    }
                    else if(
                      (this.promiseDate != null && this.promiseDate != undefined) &&
                      (this.tenorPerubahanAsuransiKeySelected != null && this.tenorPerubahanAsuransiKeySelected != "") &&
                      (this.skemaPembayaranKeySelected != null && this.skemaPembayaranKeySelected != "")
                    ){
                      //Pass hit submit
                      this.hitSubmitIR();
                    }              
                  }
                  if(this.resultKeySelected == "L009-3"){
                    //Check Result Reason if null
                    if(this.resultReasonKeySelected == null || this.resultReasonKeySelected == ""){
                      this.toastr.error("Please Fill Result Reason");
                    }   
                    //Check Tenor Perubahan Asuransi if null
                    if(this.tenorPerubahanAsuransiKeySelected == null || this.tenorPerubahanAsuransiKeySelected == ""){
                      this.toastr.error("Please Fill Tenor Perubahan Asuransi");
                    }
                    //Check Skema Pembayaran if null
                    if(this.skemaPembayaranKeySelected == null || this.skemaPembayaranKeySelected == ""){
                      this.toastr.error("Please Fill Skema Pembayaran");
                    }
                    else if(
                      (this.resultReasonKeySelected != null && this.resultReasonKeySelected != "") &&
                      (this.tenorPerubahanAsuransiKeySelected != null && this.tenorPerubahanAsuransiKeySelected != "") &&
                      (this.skemaPembayaranKeySelected != null && this.skemaPembayaranKeySelected != "")
                    ){
                      //Pass hit submit
                      this.hitSubmitIR();
                    }  
                  }
                }
              }
              else{
                //Contactivity != contacted
                this.hitSubmitIR();
              }
            }
            else{
              this.toastr.error("Please Fill Contactivity");
            }
          }
          else{
            //Connectivity != connected
            this.hitSubmitIR();
          }
        }
        else{
          this.toastr.error("Please Fill Connectivity");
        }
      }
      else{
        //Has Been Caled != has been called
        this.hitSubmitIR();
      }
    }
    else{
      this.toastr.error("Please Fill Has Been Called");
    }
  }

  saveForm(){
    this.formProspect.id = this.idDetail;
    this.formProspect.userCrt = this.userInfo.userId;
    this.formProspect.prospectResult.isDraft = "Y";
    this.formProspect.prospectResult.isFinal = "N";
    this.formProspect.prospectResult.hasBeenCalled = this.hasBeenCalledKeySelected != "" ? this.hasBeenCalledKeySelected : null;
    this.formProspect.prospectResult.connectivity = this.connectivityKeySelected != "" ? this.connectivityKeySelected : null;
    this.formProspect.prospectResult.contactivity = this.contactivityKeySelected != "" ? this.contactivityKeySelected : null; 
    this.formProspect.prospectResult.probingAction = this.probingActionKeySelected != "" ? this.probingActionKeySelected : null;
    this.formProspect.prospectResult.result = this.resultKeySelected != "" ? this.resultKeySelected : null;
    this.formProspect.prospectResult.interestedStatus = this.interestedStatusKeySelected != "" ? this.interestedStatusKeySelected : null;
    this.formProspect.prospectResult.resultReason = this.resultReasonKeySelected != "" ? this.resultReasonKeySelected : null;
    
    if(this.promiseDate && this.promiseDate != undefined){
      let yearString = this.promiseDate.year;
      let monthString = this.promiseDate.month.toString().length == 1 ? "0" + this.promiseDate.month : this.promiseDate.month;
      let dayString =  this.promiseDate.day.toString().length == 1 ? "0" + this.promiseDate.day : this.promiseDate.day;
      this.promiseDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.promiseDate = this.promiseDateString ? this.promiseDateString : null;
    }

    this.formProspect.prospectResult.tenorPerubahanAsuransi = this.tenorPerubahanAsuransiKeySelected != "" ? this.tenorPerubahanAsuransiKeySelected : null;
    this.formProspect.prospectResult.skemaPembayaran = this.skemaPembayaranKeySelected != "" ? this.skemaPembayaranKeySelected : null;
    this.formProspect.prospectResult.description = this.description != "" ? this.description : null;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;

    this.apiUtil.invokeAPI("PROSPECT_SUBMIT", this.formProspect).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        this.router.navigate(['/homepage/prospect-insurance']);
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  hitSubmitIR(){
    this.formProspect.prospectResult.hasBeenCalled = this.hasBeenCalledKeySelected != "" ? this.hasBeenCalledKeySelected : null;
    this.formProspect.prospectResult.connectivity = this.connectivityKeySelected != "" ? this.connectivityKeySelected : null;
    this.formProspect.prospectResult.contactivity = this.contactivityKeySelected != "" ? this.contactivityKeySelected : null; 
    this.formProspect.prospectResult.probingAction = this.probingActionKeySelected != "" ? this.probingActionKeySelected : null;
    this.formProspect.prospectResult.result = this.resultKeySelected != "" ? this.resultKeySelected : null;
    this.formProspect.prospectResult.interestedStatus = this.interestedStatusKeySelected != "" ? this.interestedStatusKeySelected : null;
    this.formProspect.prospectResult.resultReason = this.resultReasonKeySelected != "" ? this.resultReasonKeySelected : null;

    if(this.promiseDate && this.promiseDate != undefined){
      let yearString = this.promiseDate.year;
      let monthString = this.promiseDate.month.toString().length == 1 ? "0" + this.promiseDate.month : this.promiseDate.month;
      let dayString =  this.promiseDate.day.toString().length == 1 ? "0" + this.promiseDate.day : this.promiseDate.day;
      this.promiseDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.promiseDate = this.promiseDateString ? this.promiseDateString : null;
    }
    
    this.formProspect.prospectResult.tenorPerubahanAsuransi = this.tenorPerubahanAsuransiKeySelected != "" ? this.tenorPerubahanAsuransiKeySelected : null;
    this.formProspect.prospectResult.skemaPembayaran = this.skemaPembayaranKeySelected != "" ? this.skemaPembayaranKeySelected : null;
    this.formProspect.prospectResult.description = this.description != "" ? this.description : null;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;
    
    this.apiUtil.invokeAPI("PROSPECT_SUBMIT", this.formProspect).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        this.router.navigate(['/homepage/prospect-insurance']);
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  editPhone(){
    $(document).ready(function(){
      $('.editPhone').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editPhone').addClass('d-none'); 
      console.log('yes')
    });
  }

  editContractNum(){
    $(document).ready(function(){
      $('.editContractNum').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editContractNum').addClass('d-none'); 
      console.log('yes')
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  

}
