import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from './ApiConfig';
import { Config } from './Config';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Api {
	constructor(
		public http: HttpClient,
		private config: ApiConfig,
		private appConfig: Config
	) {
		// console.log('Loading Api...');
	}

	invokeAPI(API, DATA){
		var method  	= this.config.API_DEFINITION[API].METHOD;
		var apifullurl 	= this.config.API_URL + this.config.API_DEFINITION[API].ENDPOINT;
		var apifullurllogin = this.config.API_URL_LOGIN + this.config.API_DEFINITION[API].ENDPOINT;
		var type 		= this.config.API_DEFINITION[API].TYPE;

		var tmpHeader = this.config.API_DEFINITION[API].HEADER;

		if(!this.appConfig.NON_AUTHORIZATION_URL.includes(API)){
			if(localStorage.getItem("userData")){
				var tmpData = JSON.parse(localStorage.getItem("userData"));
				tmpHeader["authorization"] = tmpData.token_type + " " + tmpData.access_token;
			}
		}
		else{
			// var clientId = "ifabula-client";
			// var clientSecret = "ifabula-secret";
			// var authorizationBasic = window.btoa(clientId + ':' + clientSecret);
			// tmpHeader["Authorization"] = 'Basic ' + authorizationBasic;	
		}

		var headers 	= new HttpHeaders(this.config.API_DEFINITION[API].HEADER);
		
		// console.log("Using headers : ", headers);
		// console.log("Using data : ", DATA);

		if(method == "GET"){
			if(type == "QUERY_PARAM"){
				let dataSerialized = Object.keys(DATA)
				.map(key => `${key}=${DATA[key]}`)
				.join('&');

				apifullurl += '?' + dataSerialized;
			}
			
			else if(type == "PATH_PARAM"){
				for (var key in DATA) {
					apifullurl += "/" + DATA[key];
				}
			}

			// console.log("link to hit", apifullurl);

			return this.http.get<any>(apifullurl, { headers:headers }).
				map(res => res);
		}
		
		else if(method == "POST"){
			if(type == "QUERY_PARAM"){
				let dataSerialized = Object.keys(DATA)
					.map(key => `${key}=${DATA[key]}`)
					.join('&');

				if(API == 'LOGIN' || API == 'FORGOT_PASSWORD' || API == 'RESET_PASSWORD' || API == 'CHANGE_PASSWORD'){					
					apifullurllogin += '?' + dataSerialized;
				}
				else{
					apifullurl += '?' + dataSerialized;
				}
			}
			
			else if(type == "PATH_PARAM"){
				for (var key in DATA) {
					apifullurl += "/" + DATA[key];
				}
			}
			
			else if(type == "HYBRID_PARAM"){
				for (var key in DATA) {
					if(key == "id"){
						apifullurl += "/" + DATA[key];
						break;
					}
				}
			}

			else if(type == "BINARY"){
				return this.http.post<any>(
					apifullurl,
					DATA,
					{ headers:headers, responseType: "arraybuffer" as "json" }
				);
			}else if(type == "FORM_DATA"){
				const formData = new FormData();
				formData.append('file', DATA.file);
				formData.append('owner', DATA.owner);
				formData.append('key', DATA.key);

				return this.http.post<any>(
					apifullurl,
					formData,
					{ headers:headers }
				).map(res => res);
			}

			else{
				return API == 'LOGIN' || API == 'FORGOT_PASSWORD' || API == 'RESET_PASSWORD' || API == 'CHANGE_PASSWORD' ? this.http.post<any>(
					apifullurllogin,
					DATA,
					{ headers:headers }
				).map(res => res) : this.http.post<any>(
					apifullurl,
					DATA,
					{ headers:headers }
				).map(res => res);
				// return this.http.post<any>(
				// 	apifullurl,
				// 	DATA,
				// 	{ headers:headers }
				// ).map(res => res);
			}

			// console.log("link to hit", apifullurllogin);
			// console.log("link to hit", apifullurl);

			return API == 'LOGIN' || API == 'FORGOT_PASSWORD' || API == 'RESET_PASSWORD' || API == 'CHANGE_PASSWORD' ? this.http.post<any>(
				apifullurllogin,
				DATA,
				{ headers:headers }
			).map(res => res) : this.http.post<any>(
				apifullurl,
				DATA,
				{ headers:headers }
			).map(res => res);
		}

				
		else if(method == "DELETE"){
			let options:any = { headers:headers }
			if(type == "QUERY_PARAM"){
				apifullurl += DATA;
			}
			
			else if(type == "PATH_PARAM"){
				for (var key in DATA) {
					apifullurl += "/" + DATA[key];
				}
			}
			
			else if(type == "HYBRID_PARAM"){
				for (var key in DATA) {
					if(key == "id"){
						apifullurl += "/" + DATA[key];
						break;
					}
				}

				options.body = DATA;
			}

			return this.http.delete<any>(
				apifullurl,
				options
			).map(res => res);
		}
	}
}
