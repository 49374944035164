import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
@Component({
  selector: 'app-prospect-new-tcso-nc',
  templateUrl: './prospect-new-tcso-nc.component.html',
  styleUrls: ['./prospect-new-tcso-nc.component.css']
})
export class ProspectNewTcsoNcComponent implements OnInit {
  constructor(
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  errorFlag: any = false;
  errorMsg: any;
  branchDealerList : any;
  typeList : any;
  modelList : any;
  brandList : any;
  typeLov : any;
  branchProspect : any;
  resultExisting: any;
  resultExis: any;
  resultMobile : any;
  resultMobile2 : any;
  resultName : any;
  resultAddress : any;
  resultBranch : any;
  resultType : any;
  resultEmail: any;
  resultDealerName: any;
  resultVehicleYear: any;
  resultVehicleType: any;
  resultVehicleModel: any;
  resultVehicleBrand: any;

  branchCustSelected : any;
  branchCustIdSelected : any;

  modelSelected : any;
  modelKeySelected : any;

  brandSelected : any;
  brandKeySelected : any;

  branchDealerSelected : any;
  branchDealerKeySelected : any;

  existingTafSelected : any;
  branchSelected : any;
  branchIdSelected : any = "";
  verificationSelected : any;
  verificationKeySelected : any = "";
  typeSelected : any;
  typeKeySelected : any = "";
  typeCustSelected : any;
  typeCustKeySelected : any = "";
  custName: any = "";
  custId: any = "";
  custNo: any = "";
  custAppNoSelected: any;
  custAppNoKeySelected: any = "";
  custAppNo: any;
  isExisting: any;
  vehicleLicensePlate: any = "";
  vehicleEngineNo: any = "";
  vehicleIdentificationNo: any = "";
  custAgreementNo: any = "";
  custHomeAddress: any = "";
  custMobileNo1: any = "";
  custMobileNo2: any = "";
  product: any = "";
  email: any = "";
  notes1: any = "";
  notes2: any = "";
  vehicleYear: any = "";

  base64ImgString : any = "";
  srcImgTemp: any = '../../../assets/images/drop-file.png';
  srcFileName: any = "";

  formNewCar : any = {
    id : "",
    userCrt : "",
    product : "",
    branchId: "",
    prospectResult : {
      attachment: ""
    },
    prospectDetail : {
      custId: "",
      custNo: "",
      custName: "",
      vehicleEngineNo: "",
      vehicleIdentificationNo: "",
      isExisting: "",
      custAppNo: "",
      custAgreementNo: "",
      custType: "",
      custHomeAddress: "",
      custMobileNo1: "",
      custMobileNo2: "",
      email: "",
      notes1: "",
      notes2: "",
      vehicleBrand: "",
      vehicleModel: "",
      vehicleType: "",
      dealerName: "",
      vehicleLicensePlate: "",
      vehicleYear : ""
    }
  } 

  formNewCarCheck : any = {
    prospectDetail : {
        custId: "",
        custNo: "",
        custName: "",
        vehicleEngineNo: "",
        vehicleIdentificationNo: "",
        isExisting: "",
        custAppNo: ""
    }
  }

  ngOnInit(): void {
    this.verificationSelected = "- Customer Verification -";
    this.existingTafSelected = "No";
    this.getLovNonDataBase();
    this.getBranchData();
    this.getListBrand();

    this.branchCustSelected = "Branch";
    this.branchCustIdSelected = "Branch";

    this.brandKeySelected = 'Car Brand';
    this.brandSelected = 'Car Brand';

    this.modelKeySelected = "Car Model";
    this.modelSelected = "Car Model";

    this.typeKeySelected = 'Car Type';
    this.typeSelected = 'Car Type';

    this.branchDealerKeySelected = "Branch Dealer";
    this.branchDealerSelected = "Branch Dealer";
    // this.branchesOnChange(this.userInfo.branch.id, this.userInfo.branch.name);
  }

  getListBrand(){
    this.spinner.show();

    let tmpRequest = {
    };

    this.apiUtil.invokeAPI("LIST_BRAND", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.brandList = res.data;
        // this.brandKeySelected = this.brandList[0];
        // this.brandSelected = this.brandList[0];
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getListModel(brand){
    this.spinner.show();

    let tmpRequest = {
      brand: brand
    };

    this.apiUtil.invokeAPI("LIST_MODEL", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.modelList = res.data;
        // this.modelKeySelected = this.modelList[0];
        // this.modelSelected = this.modelList[0];
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getListType(brand, model){
    this.spinner.show();

    let tmpRequest = {
      brand: brand,
      model: model
    };

    this.apiUtil.invokeAPI("LIST_TYPE", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.typeList = res.data;
        // this.typeKeySelected = this.typeList[0];
        // this.typeSelected = this.typeList[0];
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getBranchData() {
    this.spinner.show();

    let tmpRequest = {
    };

    this.apiUtil.invokeAPI("PROSPECT_BRANCHES", tmpRequest).subscribe(res => {
      if (res.state.status == '200'){
        this.branchProspect = res.data;
        // this.branchCustIdSelected  = this.branchProspect[0].id;
        // this.branchCustSelected = this.branchProspect[0].name;
        this.spinner.hide();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });

  }

  getLovNonDataBase(){
    this.spinner.show();

    let tmpRequest = {
      type: "L012"
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.typeLov = res.data;
        this.typeCustKeySelected = this.typeLov[0].key;
        this.typeCustSelected = this.typeLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getBranchDealerList(branchId, brand) {
    this.spinner.show();

    let tmpRequest = {
      branchId : branchId,
      brand : brand
    };

    this.apiUtil.invokeAPI("PROSPECT_BRANCH_DEALER", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.branchDealerList = res.data;
        // if (this.branchDealerList) {
        //   this.branchDealerSelected = this.branchDealerList[0].name;
        //   this.branchDealerKeySelected = this.branchDealerList[0].id;
        // }
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      this.toastr.error(err);
      this.spinner.hide();
	  });

  }

  verificationOnChange(key, value){
    this.verificationSelected = value;
    this.verificationKeySelected = key;
  }

  typeCustOnChange(key, value){
    this.typeCustSelected = value;
    this.typeCustKeySelected = key;
  }

  branchesOnChange(key, value){
    this.branchCustSelected = value;
    this.branchCustIdSelected = key;

    this.getBranchDealerList( this.branchCustIdSelected, this.brandKeySelected);

    this.branchDealerSelected = 'Branch Dealer';
    this.branchDealerKeySelected = 'Branch Dealer';

  }

  brandOnChange(key, value){
    this.brandKeySelected = key;
    this.brandSelected = value;

    this.getListModel(this.brandSelected);
    this.getBranchDealerList(this.branchCustIdSelected, this.brandKeySelected);

    this.branchDealerList = [];
    this.branchDealerSelected = 'Branch Dealer';
    this.branchDealerKeySelected = 'Branch Dealer';

    this.modelList = [];
    this.modelKeySelected = 'Car Model';
    this.modelSelected = 'Car Model';

    this.typeList = [];
    this.typeKeySelected = ' Car Type ';
    this.typeSelected = ' Car Type ';
  }

  modelOnChange(key, value){
    this.modelKeySelected = key;
    this.modelSelected = value;

    this.getListType(this.brandSelected, this.modelSelected);

    this.typeList = [];
    this.typeKeySelected = ' Car Type ';
    this.typeSelected = ' Car Type ';

    this.branchDealerSelected = 'Branch Dealer';
    this.branchDealerKeySelected = 'Branch Dealer';
  }

  typeOnChange(key, value){
    this.typeKeySelected = key;
    this.typeSelected = value;
  }

  branchDealerOnChange(key, value) {
    this.branchDealerKeySelected = key;
    this.branchDealerSelected = value; 
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.base64ImgString = reader.result;

      if(this.base64ImgString.includes("application/pdf")){
        this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
        this.srcFileName = file.name;
      }
      else{
        this.srcImgTemp = (this.domSanitizer.bypassSecurityTrustResourceUrl(this.base64ImgString) as any).changingThisBreaksApplicationSecurity;
      }    
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
       console.log("No file selected!");
       return
    }
    let file: File = event.target.files[0];

    if(file.size < (5000 * 1024)){
      await this.getBase64(file);
    }
    else{
      this.toastr.error("Attachment more than 5 MB");
    }
  }

  clearImgUpload(){
    this.srcImgTemp = "../../../assets/images/drop-file.png"; 
    this.base64ImgString = null;
    this.srcFileName = "";
  }

  checkPhone1(){
    if(this.custMobileNo1 == this.custMobileNo2){
      this.errorFlag = true;
      this.errorMsg = "Phone cant not same";
    }
    else{
      this.errorFlag = false;      
    }
  }

  checkPhone2(){
    if(this.custMobileNo2 == this.custMobileNo1){
      this.errorFlag = true;
      this.errorMsg = "Phone cant not same";
    }
    else{
      this.errorFlag = false;      
    }
  }

  check(){
    this.spinner.show();

    if (this.verificationKeySelected == '2') {
      console.log(this.verificationKeySelected);
      this.formNewCarCheck.prospectDetail.custId = null;
      this.formNewCarCheck.prospectDetail.custNo = this.custNo != "" ? this.custNo : null;
      this.formNewCarCheck.prospectDetail.vehicleEngineNo = null;
      this.formNewCarCheck.prospectDetail.vehicleIdentificationNo = null;
    }
    else if (this.verificationKeySelected == '1') {
      console.log(this.verificationKeySelected);
      this.formNewCarCheck.prospectDetail.custId = this.custId != "" ? this.custId : null;
      this.formNewCarCheck.prospectDetail.custNo = null;
      this.formNewCarCheck.prospectDetail.vehicleEngineNo = null;
      this.formNewCarCheck.prospectDetail.vehicleIdentificationNo = null;
    }
    else if (this.verificationKeySelected == '3') {
      console.log(this.verificationKeySelected);
      this.formNewCarCheck.prospectDetail.custId = null;
      this.formNewCarCheck.prospectDetail.custNo = null;
      this.formNewCarCheck.prospectDetail.vehicleEngineNo = this.vehicleEngineNo != "" ? this.vehicleEngineNo: null;
      this.formNewCarCheck.prospectDetail.vehicleIdentificationNo = null;
    }
    else if (this.verificationKeySelected == '4') {
      console.log(this.verificationKeySelected);
      this.formNewCarCheck.prospectDetail.custId = null;
      this.formNewCarCheck.prospectDetail.custNo = null;
      this.formNewCarCheck.prospectDetail.vehicleEngineNo = null;
      this.formNewCarCheck.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo != "" ? this.vehicleIdentificationNo: null;
    }

    this.apiUtil.invokeAPI("PROSPECT_CHECK", this.formNewCarCheck).subscribe(res => {
      console.log(res);
      if(res.state.status == '200') {
        console.log("BERHASIL");
        this.resultExisting = res.data.prospectDetailList[0].isExisting;
        this.resultExis = res.data.prospectDetailList[0].custAppNo;
        this.resultMobile = res.data.prospectDetailList[0].custMobileNo1;
        this.resultMobile2 = res.data.prospectDetailList[0].custMobileNo2;
        this.resultName = res.data.prospectDetailList[0].custName;
        this.resultAddress = res.data.prospectDetailList[0].custHomeAddress;
        this.resultBranch = res.data.prospectDetailList[0].branchName;
        this.resultType = res.data.prospectDetailList[0].custType;
        this.resultEmail = res.data.prospectDetailList[0].custEmail;
        this.resultDealerName = res.data.prospectDetailList[0].dealerName;
        this.resultVehicleYear = res.data.prospectDetailList[0].vehicleYear;
        this.resultVehicleType = res.data.prospectDetailList[0].vehicleType;
        this.resultVehicleModel = res.data.prospectDetailList[0].vehicleModel;
        this.resultVehicleBrand = res.data.prospectDetailList[0].vehicleBrand;
        

        this.existingTafSelected = this.resultExisting;
        this.custAppNoSelected = this.resultExis;
        this.custMobileNo1 = this.resultMobile;
        this.custMobileNo2 = this.resultMobile2;
        this.custName = this.resultName;
        this.custHomeAddress = this.resultAddress;
        this.branchCustIdSelected = this.resultBranch;
        this.typeCustKeySelected = this.resultType;
        this.email = this.resultEmail;
        this.branchDealerSelected = this.resultDealerName;
        this.vehicleYear = this.resultVehicleYear;
        this.typeSelected = this.resultVehicleType;
        this.modelSelected = this.resultVehicleModel;
        this.brandSelected = this.resultVehicleBrand;
        this.formNewCarCheck.prospectDetail.custAppNo = this.resultExis;
        if (this.existingTafSelected == "true") {
          this.toastr.success("Existing TAF Customer");
          this.existingTafSelected = "Yes";
          if(this.branchCustIdSelected) {
            this.branchCustSelected = this.branchCustIdSelected;
          }
          if (this.typeCustKeySelected == "P") {
            this.typeCustKeySelected = "Personal";
          } else if (this.typeCustKeySelected == "C"){
            this.typeCustKeySelected = "Company";
          }
        }
        else {
          this.toastr.error("Not Existing TAF Customer");
          this.existingTafSelected = "No";
          this.getLovNonDataBase();
          this.branchCustSelected = "Branch";
          this.branchCustIdSelected = "Branch";

          this.brandKeySelected = 'Car Brand';
          this.brandSelected = 'Car Brand';

          this.modelKeySelected = "Car Model";
          this.modelSelected = "Car Model";

          this.typeKeySelected = 'Car Type';
          this.typeSelected = 'Car Type';

          this.branchDealerKeySelected = "Branch Dealer";
          this.branchDealerSelected = "Branch Dealer";
        }
      }
      this.spinner.hide();
      
    },(err) => {
      console.log(err);
      this.toastr.error("Backend API Connection is Offline");
      this.spinner.hide();
    });
  }

  submitForm(){
    if(this.custName == "" && (this.custMobileNo1 == "" || this.custMobileNo2 == "") ){
      this.toastr.error("Please Fill Customer Name, Mobile Phone");
    }
    else if(this.custName == "") {
      this.toastr.error("Please Fill Customer Name");
    }
    else if(this.custMobileNo1 == "" && this.custMobileNo2 == "") {
      this.toastr.error("Please Fill Mobile Phone 1 or Mobile Phone 2");
    }
    else if(this.custMobileNo1[0] != '0' && this.custMobileNo2[0] != '0') {
      this.toastr.error("Invalid Format Mobile Phone");
    }
    else if(this.custMobileNo1.length < '5' && this.custMobileNo2.length < '5' ) {
      this.toastr.error("Mobile number error at least 5 digits");
    }
    else if(this.custMobileNo2 == this.custMobileNo1){
      this.toastr.error ("Phone cant not same Mobile Phone 1");
    }
    else if(this.custMobileNo2 != this.custMobileNo1 && ((this.custName != "" && ((this.custMobileNo1[0] == "0") && (this.custMobileNo1.length >= '5' && this.custMobileNo1 < '20'))) || (this.custName != "" && ((this.custMobileNo2[0] == "0") && (this.custMobileNo2.length >= '5' && this.custMobileNo2 < '20')))) ) {
      this.submit();
    }
    // else if(this.branchCustSelected == 'Branch') {
    //   this.toastr.error("Please Fill TAF's Branch");
    // }
  }

  submit() {
    this.formNewCar.userCrt = this.userInfo.userId;
    if(this.userInfo.isSiapDana == "Y") {
      this.formNewCar.product = "SD";
    } else if (this.userInfo.isNewCar == "Y") {
      this.formNewCar.product = "NC";
    }
    if (this.formNewCar.product == "NC") {
      this.formNewCar.prospectDetail.custName = this.custName != "" ? this.custName : null;
      if (this.verificationKeySelected == '1' ) {
        this.formNewCar.prospectDetail.custId = this.custId != "" ? this.custId : null;
        this.formNewCar.prospectDetail.custNo = this.custNo ;
        this.formNewCar.prospectDetail.vehicleEngineNo = this.vehicleEngineNo ;
        this.formNewCar.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo ;
      }
      if (this.verificationKeySelected == '2' ) {
        this.formNewCar.prospectDetail.custId = this.custId;
        this.formNewCar.prospectDetail.custNo = this.custNo != "" ? this.custNo : null;
        this.formNewCar.prospectDetail.vehicleEngineNo = this.vehicleEngineNo ;
        this.formNewCar.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo ;
      }
      if (this.verificationKeySelected == '3' ) {
        this.formNewCar.prospectDetail.custId = this.custId;
        this.formNewCar.prospectDetail.custNo = this.custNo;
        this.formNewCar.prospectDetail.vehicleEngineNo = this.vehicleEngineNo != "" ? this.vehicleEngineNo : null ;
        this.formNewCar.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo ;
      }
      if (this.verificationKeySelected == '4' ) {
        this.formNewCar.prospectDetail.custId = this.custId;
        this.formNewCar.prospectDetail.custNo = this.custNo;
        this.formNewCar.prospectDetail.vehicleEngineNo = this.vehicleEngineNo ;
        this.formNewCar.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo != "" ? this.vehicleIdentificationNo : null ;
      }
      this.formNewCar.prospectDetail.isExisting = this.existingTafSelected == "Yes" ? 'Y' : 'N';
      this.formNewCar.prospectDetail.custAppNo = this.formNewCarCheck.prospectDetail.custAppNo;
      this.formNewCar.prospectDetail.custAgreementNo = this.custAgreementNo != "" ? this.custAgreementNo : null;
      this.formNewCar.prospectDetail.custType = this.typeCustKeySelected != "" ? this.typeCustKeySelected: null;
      this.formNewCar.prospectDetail.custHomeAddress = this.custHomeAddress != "" ? this.custHomeAddress : null;
      this.formNewCar.prospectDetail.custMobileNo1 = this.custMobileNo1 != "" ? this.custMobileNo1 : null;
      this.formNewCar.prospectDetail.custMobileNo2 = this.custMobileNo2 != "" ? this.custMobileNo2 : null;
      this.formNewCar.prospectDetail.email = this.email != "" ? this.email : null;
      this.formNewCar.prospectDetail.notes1 = this.notes1 != "" ? this.notes1 : null;
      this.formNewCar.prospectDetail.notes2 = this.notes2 != "" ? this.notes2 : null;
      this.formNewCar.branchId = this.branchCustIdSelected != "" ?  this.branchCustIdSelected : null;
      this.formNewCar.prospectDetail.vehicleBrand = this.brandKeySelected != "" ? this.brandKeySelected : null;
      this.formNewCar.prospectDetail.vehicleModel = this.modelKeySelected != "Car Model" ? this.modelKeySelected : null;
      this.formNewCar.prospectDetail.vehicleType = this.typeKeySelected != "Car Type" ? this.typeKeySelected : null;
      this.formNewCar.prospectDetail.dealerName = this.branchDealerKeySelected != "Branch Dealer" ? this.branchDealerKeySelected : null;
      this.formNewCar.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlate != "" ? this.vehicleLicensePlate : null;
      this.formNewCar.prospectDetail.vehicleYear =  this.vehicleYear != "" ? this.vehicleYear : null;
      this.formNewCar.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;

      this.apiUtil.invokeAPI("PROSPECT_ENTRY_TCSO", this.formNewCar).subscribe(res => {
        if(res.state.status == '200'){
          if(res.state.error.split(";")[0] == "400"){
            this.toastr.error(res.state.error.split(";")[1]);
          }
          else{
            this.toastr.success("Insert Success");
            this.router.navigate(['/homepage/prospect-tcso']);
          }

          this.spinner.hide();
        }
        else {
          console.log(res);
          this.toastr.error(res);
          this.spinner.hide();
        }
      }, 
      (err) => {
        console.log(err);
        this.toastr.error(err);
        this.spinner.hide();
      });
    }
  }

}
