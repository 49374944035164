import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router,RouterLink } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
// import { HomeLayoutRoutes } from 'src/app/layout/home-layout.routing';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';

@Component({
  selector: 'app-customer-handling-sh',
  templateUrl: './customer-handling-sh.component.html',
  styleUrls: ['./customer-handling-sh.component.css']
})
export class CustomerHandlingShComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private spinner: NgxSpinnerService
  ) { }

  viewMode = 'tab1';

  productList: any;

  dtOptionsSHSD: any = {};
  dtOptionsSHNC: any = {};
  dtOptionsSO: any = {};
  dtOptionsSRO: any = {};
  dtOptionsCDE: any = {};
  productSelected: any;
  productKeySelected: any = "";

  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  handlingRoleSH: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }
  ngOnInit(): void {
    this.handlingRoleSH = this.userInfo.roleId;
    this.getListProducts();
    // this.productKeySelected == 'SD';

    if (this.handlingRoleSH == 'SRO') {
      this.dtOptionsSRO = {
        processing: true,
        serverSide: true,
        ajax: {
          url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_" + this.handlingRoleSH + "_LIST"].ENDPOINT + "/" + this.userInfo.userId,
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function (d) {
            return JSON.stringify(d);
          }
        },
        columns: [
          {
            data: "custName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "dtmCrt", 
            render: function(data){
              return moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "branchName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "userName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "dtmLastFu",
            render: function(data){
              return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "custAgreementStatus",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "status",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: null,
            render: function(data, type, row){
              return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
            }
          }
        ],
      }
    }
    else if(this.handlingRoleSH == 'SH' && this.userInfo.isSiapDana == 'Y') {
      // if (this.userInfo.isSiapaDana == 'Y') {
        this.productOnChange("SD", "Siap Dana");
    }
    else if(this.handlingRoleSH == 'SH' && this.userInfo.isNewCar == 'Y') {
      this.productOnChange("NC", "New Car");
    }
    else if ((this.handlingRoleSH == 'SO')) {
      this.dtOptionsSO = {
        processing: true,
        serverSide: true,
        ajax: {
          url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+ this.handlingRoleSH +"_LIST"].ENDPOINT + "/" + this.userInfo.userId,
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function (d) {
            return JSON.stringify(d);
          }
        },
        columns: [
          {
            data: "custName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "dtmCrt", 
            render: function(data){
              return moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "branchName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "userName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "dtmLastFu",
            render: function(data){
              return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
            }
          },
          {
            data: "custAgreementStatus",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "status",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: null,
            render: function(data, type, row){
              return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
            }
          }
        ],
      }
    }
    else if ((this.handlingRoleSH == 'CDE')) {
      this.dtOptionsCDE = {
        processing: true,
        serverSide: true,
        ajax: {
          url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+ this.handlingRoleSH +"_LIST"].ENDPOINT + "/" + this.userInfo.userId,
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function (d) {
            return JSON.stringify(d);
          }
        },
        columns: [
          {
            data: "custName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "dtmCrt", 
            render: function(data){
              return moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "branchName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "userName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "dtmLastFu",
            render: function(data){
              return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "custAgreementStatus",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "status",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: null,
            render: function(data, type, row){
              return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
            }
          }
        ],
      }
    }
  }
  getListProducts(){
    let tmpRequest = {
      currentUserId: this.userInfo.userId
    }
  
    this.apiUtil.invokeAPI("SH_PRODUCT", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.productList = res.data;
        // console.log(this.productList);
        this.productKeySelected = this.productList[0].key;
        this.productSelected = this.productList[0].value;
  
        this.spinner.hide();
      }
  
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
  }
  productOnChange(key, value) {
    this.productSelected = value;
    this.productKeySelected = key;
    
    if (this.handlingRoleSH == 'SH' && this.productKeySelected == 'SD'){
      this.dtOptionsSHSD = {
        processing: true,
        serverSide: true,
        ajax: {
          url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_" + this.handlingRoleSH + "_LIST"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected,
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function (d) {
            return JSON.stringify(d);
          }
        },
        columns: [
          {
            data: "custName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "dtmCrt", 
            render: function(data){
              return moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "branchName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "userName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "dtmLastFu",
            render: function(data){
              return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "custAgreementStatus",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "status",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: null,
            render: function(data, type, row){
              return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
            }
          }
        ],
      }
    } else if (this.handlingRoleSH == 'SH' && this.productKeySelected == 'NC'){
      this.dtOptionsSHNC = {
        processing: true,
        serverSide: true,
        ajax: {
          url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_" + this.handlingRoleSH + "_LIST"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected,
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function (d) {
            return JSON.stringify(d);
          }
        },
        columns: [
          {
            data: "custName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "dtmCrt", 
            render: function(data){
              return moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "branchName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "userName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "dtmLastFu",
            render: function(data){
              return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "custAgreementStatus",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "status",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: null,
            render: function(data, type, row){
              return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
            }
          }
        ],
      }
    }
  }
}
