<main class="prospect-new-car">
    <div class="white-container p-20 mt-40" *ngIf="userInfo.isNewCar == 'Y'">
        <div class="row no-margin w-100">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label *ngIf="custName == ''" class="text-large">Customer Name<h8 class="text-danger">*</h8></label>
                <label *ngIf="custName != ''"class="text-large">Customer Name</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input"> 
                    <input type="text" class="form-control col-lg-6" name="custName" [(ngModel)]="custName" id="custName" placeholder="Customer Name">
                </div>
            </div>    
         </div>

        <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Customer Verification</label> 
            </div>
            <div class="col-lg-9 col-sm-6 div-text-left two-form">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100" id="dropdownBasic1" ngbDropdownToggle style="width: 290px;">{{verificationSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="verificationOnChange('1','Customer ID')">Customer ID</button>
                        <button ngbDropdownItem (click)="verificationOnChange('2', 'Customer No')">Customer No</button>
                        <button ngbDropdownItem (click)="verificationOnChange('3', 'Nomor Mesin')">Nomor Mesin</button>
                        <button ngbDropdownItem (click)="verificationOnChange('4', 'Nomor Rangka')">Nomor Rangka</button>
                    </div>
                </div>
                <div class="col-lg-6 no-padding">
                    
                    <div class="form-group custom-input lpl-10">
                        <!-- <form *ngIf="verificationSelected == 'Customer ID'">
                            <input type="email" class="form-control col-lg-12" name="custId" [(ngModel)]="custId" id="custId" placeholder="ID">
                        </form> -->
                        <form *ngIf="(verificationKeySelected == '1' || verificationSelected == 'Customer ID')">
                            <input *ngIf="typeCustSelected =='Personal'" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="16" class="form-control col-lg-12" name="custId" [(ngModel)]="custId" id="custId" placeholder="No KTP/KITAS">
                            <input *ngIf="typeCustSelected =='Company'" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="15" class="form-control col-lg-12" name="custId" [(ngModel)]="custId" id="custId" placeholder="No NPWP">
                        </form>
                        <form *ngIf="verificationKeySelected == '2'">
                            <input type="text" class="form-control col-lg-12" name="custNo" [(ngModel)]="custNo" id="custNo" placeholder="No">
                        </form>
                        <form *ngIf="verificationKeySelected == '3'">
                            <input type="text" class="form-control col-lg-12" name="vehicleEngineNo" [(ngModel)]="vehicleEngineNo" id="vehicleEngineNo" placeholder="Nomor Mesin">
                        </form>
                        <form *ngIf="verificationKeySelected == '4'">
                            <input type="text" class="form-control col-lg-12" name="vehicleIdentificationNo" [(ngModel)]="vehicleIdentificationNo" id="vehicleIdentificationNo" placeholder="Nomor Rangka">
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Existing TAF ?</label>
            </div>
            <div class="col-lg-9 col-sm-6 div-text-left two-form">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100 text-center" id="dropdownBasic1" ngbDropdownToggle style="width: 290px;">{{existingTafSelected}}</button>
                        <!-- <em class="fas fa-caret-down down-dropdown"></em> -->
                    </div>
                    <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="existingTafOnChange('1','Yes')">Yes</button>
                        <button ngbDropdownItem (click)="existingTafOnChange('2', 'No')">No</button>
                    </div> -->
                </div>
                <div class="btn-custom-left p-relative ml-20">
                    <button class="btn btn-violet div-text-center" type="button" (click)="check();">Check</button>
                </div>
            </div>
         </div>

         <!-- <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Customer App No</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <button type="email" class="form-control col-lg-6 div-text-left" name="custAppNo" [(ngModel)]="custAppNo" id="custAppNo" placeholder="Nomer App">{{custAppNoSelected}}</button>
                    <input type="text" class="form-control col-lg-6" id="exiistingForm" placeholder="Nomor">
                </div>
            </div>    
         </div> -->

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">No Kontrak</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input"> 
                    <input type="text" class="form-control col-lg-6" name="custAgreementNo" [(ngModel)]="custAgreementNo" id="custAgreementNo" placeholder="No Kontrak">
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Customer Type</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100" id="dropdownBasic1" ngbDropdownToggle style="width: 290px;">{{typeCustSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let typeCust of typeLov; let i = index" (click)="typeCustOnChange(typeCust.key, typeCust.value)">{{typeCust.value}}</button>
                    </div>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Address</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <textarea class="form-control" name="custHomeAddress" [(ngModel)]="custHomeAddress" id="custHomeAddress" rows="2" placeholder="Address"></textarea>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label *ngIf="(custMobileNo2 == '')" class="text-large">Mobile Phone No 1<h8 class="text-danger">*</h8></label>
                <label *ngIf="(custMobileNo2 != '')" class="text-large">Mobile Phone No 1</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="20" class="form-control col-lg-6" name="custMobileNo1" [(ngModel)]="custMobileNo1" id="custMobileNo1" [ngDuplicate]="custMobileNo1 == custMobileNo2" placeholder="Mobile Phone No 1" required (keyup)="checkPhone1()"/>
                </div>
                <br>
                <br>
                <h8 *ngIf="(custMobileNo1[0] != '0')" class="text-secondary">&nbsp; Format : 0xxxxxxx</h8>   
            </div>
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label *ngIf="(custMobileNo1 == '')" class="text-large">Mobile Phone No 2<h8 class="text-danger">*</h8></label>
                <label *ngIf="(custMobileNo1 != '')" class="text-large">Mobile Phone No 2</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="20" class="form-control col-lg-6" name="custMobileNo2" [(ngModel)]="custMobileNo2" id="custMobileNo2" [ngDuplicate]="custMobileNo2 == custMobileNo1" placeholder="Mobile Phone No 2" required (keyup)="checkPhone2()"/>
                </div>
                <br>
                <br>
                <h8 *ngIf="(custMobileNo2[0] != '0')" class="text-secondary">&nbsp; Format : 0xxxxxxx</h8>   
            </div>
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Email</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input"> 
                    <input type="text" class="form-control col-lg-6" name="email" [(ngModel)]="email" id="email" placeholder="Email">
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Description 1</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <textarea class="form-control" name="notes1" [(ngModel)]="notes1" id="notes1" rows="2" placeholder="Description 1"></textarea>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Description 2</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <textarea class="form-control" name="notes2" [(ngModel)]="notes2" id="notes2" rows="2" placeholder="Description 2"></textarea>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label *ngIf="(branchCustSelected == 'Branch')" class="text-large">TAF's Branch<h8 class="text-danger">*</h8></label>
                <label *ngIf="(branchCustSelected != 'Branch')" class="text-large">TAF's Branch</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100" id="dropdownBasic1" title="{{userInfo.branch.name}}" ngbDropdownToggle style="width: 290px;">{{branchCustSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let branchCust of branchProspect; let i = index" (click)="branchesOnChange(branchCust.id, branchCust.name)">{{branchCust.name}}</button>
                    </div>
                </div>
            </div>   
        </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Merk Kendaraan</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{brandSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let brand of brandList; let i = index" (click)="brandOnChange(brand, brand)">{{brand}}</button>
                    </div>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Model Kendaraan</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100" id="dropdownBasic1" ngbDropdownToggle style="width: 290px;">{{modelSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let model of modelList; let i = index" (click)="modelOnChange(model, model)">{{model}}</button>
                    </div>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Type Kendaraan</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100" id="dropdownBasic1" ngbDropdownToggle style="width: 290px;">{{typeSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let type of typeList; let i = index" (click)="typeOnChange(type, type)">{{type}}</button>
                    </div>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Nama Dealer</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="custom-dropdown no-padding d-inline-block col-lg-6">
                    <div class="p-relative dropdown w-100">
                        <button class="btn-dropdown dropdown-toogle w-100" id="dropdownBasic1" ngbDropdownToggle style="width: 290px;">{{branchDealerSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let branchDealer of branchDealerList; let i = index" (click)="branchDealerOnChange(branchDealer.id, branchDealer.name)">{{branchDealer.name}}</button>
                    </div>
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Nomor Polisi</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input"> 
                    <input type="text" class="form-control col-lg-6" name="vehicleLicensePlate" [(ngModel)]="vehicleLicensePlate" id="vehicleLicensePlate" placeholder="Nomor Polisi">
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Tahun Kendaraan</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input"> 
                    <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" class="form-control col-lg-6" name="vehicleYear" [(ngModel)]="vehicleYear" id="vehicleYear" placeholder="Tahun Kendaraan">
                </div>
            </div>    
         </div>

         <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Prospect By</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <button type="email" class="form-control col-lg-6 div-text-left" placeholder="Nomer App">{{userInfo.user.name}}</button>
                </div>
            </div>    
         </div>

        <div class="row no-margin w-100 pt-20">
         <div class="row no-margin w-100 pt-20 pb-40">
            <div class="col-lg-3 col-sm-6 mt-10 text-large">
                <label>Attachment</label>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="wrapper-upload div-text-center" style="flex-wrap: wrap;">
                    <div>
                      <img [ngStyle]="{'width': (srcFileName != '') ? '70px' : 'auto'}" [src]="srcImgTemp" alt="drop-file"> 
                    </div>
                    <div class="mt-10 text-center">
                      <span>{{srcFileName}}</span>
                    </div>
                </div>
                <div class="mt-20">
                    <button class="btn btn-purple p-relative mobile-choose-file w-100" (click)="fileUpload.click()">Choose File
                        <img src="../../../assets/images/icons/ic-upload-blue.png" alt="btn-plus" class="ic-upload"/>
                        <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event)" style="display:none" accept="image/*,application/pdf">
                    </button>
                    <button class="btn btn-purple clear-img-btn mobile-clear" (click)="clearImgUpload()" [hidden]="base64ImgString == '' || base64ImgString == null">Clear</button>
                </div>
            </div>    
          </div>
        </div>
          <div class="w-100 div-text-right mb-40">
            <div class="mr-5 mt-40">
                <button class="btn btn-violet div-text-center" (click)="submitForm()">Submit</button> <!--(click)="submitForm()" [disabled]="(((custMobileNo1 == '') || (custMobileNo1.length <= '4')) && ((custMobileNo2 == '') || (custMobileNo2.length <= '4')) || (custName == ''))"-->
              </div>
          </div>
    </div>

</main>
<ngx-spinner></ngx-spinner>