import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import * as moment from 'moment';
import { Api } from 'src/app/utility/Api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiConfig } from 'src/app/utility/ApiConfig';

@Component({
  selector: 'app-costumer-handling-detail',
  templateUrl: './costumer-handling-detail.component.html',
  styleUrls: ['./costumer-handling-detail.component.css']
})
export class CostumerHandlingDetailComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private router: Router,
    private route: ActivatedRoute,
    // private toastr: ToastrService,
    // private spinner: NgxSpinnerService
  ) { }

  viewMode = 'tab1';

  dtOptions: any = {};
  dtOptionsSPV: any = {};

  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  detaildRole: any;
  isDetailCustomer: any;
  dataCustomerDetail: any;
  dataCustomerClosedDetail: any = {};
  trTaskBucketId: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    // this.spinner.show();

    console.log(this.route.snapshot.params.id);
    this.trTaskBucketId = this.route.snapshot.params.id;
    this.detaildRole = this.userInfo.roleId;

    let tmpRequest = {
      id: this.trTaskBucketId
    }

    this.apiUtil.invokeAPI("HANDLING_CUSTOMER_HISTORY", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.dataCustomerDetail = res.data;
        this.dataCustomerDetail.forEach(element => {
          if(element.custAppNo != null){
            this.dataCustomerClosedDetail.custAppNo = element.custAppNo;
          }

          if(element.custAppDate != null){
            this.dataCustomerClosedDetail.custAppDate = element.custAppDate;
          }

          if(element.signingAppointmentDate != null){
            this.dataCustomerClosedDetail.signingAppointmentDate = element.signingAppointmentDate;
          }

        });
        // this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      // this.spinner.hide();
	  });
    this.dtOptions = {
      ajax: {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["HANDLING_" + "CUSTOMER" + "_HISTORY"].ENDPOINT + "/" + this.trTaskBucketId,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function(data){
          return data;
        }
      },
      columns: [ 
        {
          data: "dtmUpd",
          render: function(data, type) {
            if(data == null){
              return '-';
            }
            else{
              if(type === 'sort'){
                return data;
              }
              else{
                return moment(data).format("DD-MMM-YYYY : HH:mm:ss");
              }
            }
            
            // return type === 'sort' ? (data == null ? '-' : data) : moment(data).format("DD-MMM-YYYY : HH:mm:ss");
          }
        }, 
        {
          data: "userId",
          render: function(data) {
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "role.label",
          render: function(data){
            return data == null ? '-' : data;
          }
        },  
        {
          data: "dtmCrt",
          render: function(data, type) {
            if(data == null){
              return '-';
            }
            else{
              if(type === 'sort'){
                return data;
              }
              else{
                return moment(data).format("DD-MMM-YYYY");
              }
            }
            
            // return type === 'sort' ? (data == null ? '-' : data): moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "activity",
          render: function(data) {
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "taskBucket.userCrt",
          render: function(data){
            return data == null ? '-' : data;
          }
        }
      ],
      columnDefs: [
        { className: "bg-darkerblue text-white", targets: 1 },
        { className: "bg-navy text-white", targets: 2 }
      ]
    }
  }

  productName(prodId){
    switch (prodId) {
      case "SD":
        return "Siap Dana";
      case "NC":
        return "New Car";
      case "KI":
        return "Kinto";
      case "IU":
        return "Insurance Upgrade";
      case "IR": 
        return "Insurance Renewal";
      default:
        return prodId;
    }
  }

}
