import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '../utility/Api';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit {
	constructor(
	  private router: Router,
    private apiUtil: Api
  ) {
	}

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  ngOnInit() { 
    //Check Token
    if(this.userInfo != null){
      let tmpRequest = {
        userId: this.userInfo.userId,
      };
  
      this.apiUtil.invokeAPI("GET_ROLE_USER", tmpRequest).subscribe(
        res => {
          if(res.state.status == '200'){
            // this.router.navigate(['/homepage']);
          }
        }, 
        err => {
          if(err.status == '401'){
            this.router.navigate(['/']);
          }
          else{
            this.router.navigate(['/']);
          }
        }
      );
    }
    else{
			this.router.navigate(['/']);
		}
    //End
  }

}
