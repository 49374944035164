import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Config {
	constructor() {
		// console.log("Loading Api Config...")
	}

	STATUS_BE = {
		"01": "Active",
		"00": "Not Active"
	};

	STATUS_AGENT = {
		"01": "Active",
		"00": "Not Active",
		"03": "Pending Register",
		"04": "Pending Changes",
		"05": "Rejected"
	};

	LEVEL_AGENT = {
		"1": "Agent",
		"2": "AM",
		"3": "SAM",
		"4": "RAM"
	};

	NON_AUTHORIZATION_URL = ["LOGIN"];

	INTERVAL_DASHBOARD:any;
}
