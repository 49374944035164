<main class="customer-handling">
    <div class="mt-40" *ngFor="let customer of dataCustomerDetail; let i = index;" [hidden]="i != 0" >
        <div class="" >
            <em class="fas fa-circle text-blue pr-15"></em>
            <span class="text-largest">{{customer.taskBucket.custName}}</span>
        </div>
        <div class="row ">
            <div class="col-lg-6">
                <span class="text-large div-text-left">
                    <div class="wording-ch col-lg-5 no-padding">
                        Customer Number
                    </div>
                     : {{customer.taskBucket.custNo}}
                </span>
                <br>
                <br>
                <span class="text-large div-text-left">
                    <div class="wording-ch col-lg-5 no-padding">
                        Customer App No
                    </div>
                     : {{dataCustomerClosedDetail?.custAppNo ? dataCustomerClosedDetail?.custAppNo : '-'}}
                </span>
                <br>
                <span class="text-large div-text-left">
                    <div class="wording-ch col-lg-5 no-padding">
                        Customer App Date 
                    </div>
                    : {{dataCustomerClosedDetail?.custAppDate ? (dataCustomerClosedDetail?.custAppDate | date : 'dd-MMM-yyyy') : '-'}}
                </span>
                <br>
                <span class="text-large div-text-left">
                    <div class="wording-ch col-lg-5 no-padding">
                        Signing Appointment Date
                    </div>
                     : {{dataCustomerClosedDetail?.signingAppointmentDate ? (dataCustomerClosedDetail?.signingAppointmentDate | date : 'dd-MMM-yyyy') : '-'}}
                </span>
            </div>
            <div class="col-lg-6 row no-padding div-text-right mobile-left">
                <div class="br-grey m-product"> 
                    <span class="pr-20  text-large">
                            Product : {{productName(customer.taskBucket.product)}}
                    </span>
                </div>
                <div class="div-text-right"> 
                    <span class="text-large pl-20">
                            Branch  : {{customer.taskBucket.branch?.name}}
                    </span>
                </div>
            </div>

            <div id="tab1" class="table-custom custom-white tab-slider--body mt-40 w-100 pr-10 pl-10 mobile-pl15">
                <table datatable [dtOptions]="dtOptions" class="row-border hover rounded" id="myTable">
                    <thead class="head-custom">
                        <tr class="rounderleft">
                            <!-- <th class=" ">No</th> -->
                            <th class=" ">Tanggal Activity</th>
                            <th class=" ">Follow up by</th>
                            <th class=" ">Role</th>
                            <!-- <th class=" ">Activity</th> -->
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Result</th>
                            <th class=" ">Referral Name</th>
                        </tr>
                      </thead>
                      <!-- <tbody>
                        <tr>
                            <td class="  ">1</td>
                            <td class="  ">29-Jan-2020</td>
                            <td class="  ">Filbert</td>
                            <td class="  ">SO</td>
                            <td class="  ">Follow Up</td>
                            <td class="  ">-</td>
                            <td class="  ">Not Interested</td>
                            <td class="  ">Su Hoo</td>
                          </tr>
                      </tbody> -->
                    </table>
            </div>
           
        </div>
    </div>
</main>