import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import * as moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard-cde',
  templateUrl: './dashboard-cde.component.html',
  styleUrls: ['./dashboard-cde.component.css']
})
export class DashboardCdeComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private spinner: NgxSpinnerService,
    private apiConfig: ApiConfig,
    private router: Router
  ) { }

  viewMode = 'tab1';

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  dtTriggerCDE: Subject<any> = new Subject();
  dtTriggerCDETL: Subject<any> = new Subject();
  dtTrigger2CDE: Subject<any> = new Subject();
  dtTrigger2CDETL: Subject<any> = new Subject();


  dtOptionsCDE: any = {};
  dtOptions2CDE: any = {};
  dtOptionsCDETL: any = {};
  dtOptions2CDETL: any = {};

  filterDate: any = "";
  filterDateDailyString: any = "";
  filterDateMonthlyString: any = "";
  dataDashboard: any;
  
  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  dashboardCDERole: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.dashboardCDERole = this.userInfo.roleId;

    let dateToday = moment().format('YYYY-MM-DD');
    let dateMonth = moment().format('YYYY/M');
    this.filterDate = "";
    this.filterDateDailyString = "";
    this.filterDateMonthlyString = "";

    if((this.dashboardCDERole == 'CDE')){
    this.dtOptionsCDE = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "connectedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "contactedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "isFinalCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "sendbackCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "assignedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
        // rowCallback: (row: Node, data: any[] | Object, index: number) => {
        //   const self = this;
        //   $('.delete-btn', row).unbind('click');
        //   $('.edit-btn', row).unbind('click');
        //   $('.delete-btn', row).bind('click', () => {
        //     self.deleteData(data, row);
        //   });
        //   $('.edit-btn', row).bind('click', () => {
        //     self.editData(data);
        //   });
        //   return row;
        // }
      }
    } else if ((this.dashboardCDERole == 'CDETL')){
      this.dtOptionsCDETL = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "connectedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "contactedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "isFinalCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "sendbackCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "assignedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
        // rowCallback: (row: Node, data: any[] | Object, index: number) => {
        //   const self = this;
        //   $('.delete-btn', row).unbind('click');
        //   $('.edit-btn', row).unbind('click');
        //   $('.delete-btn', row).bind('click', () => {
        //     self.deleteData(data, row);
        //   });
        //   $('.edit-btn', row).bind('click', () => {
        //     self.editData(data);
        //   });
        //   return row;
        // }
      }
    }
    if ((this.dashboardCDERole == 'CDE')) {
    this.dtOptions2CDE= {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "connectedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "contactedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "isFinalCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "sendbackCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "assignedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
        // rowCallback: (row: Node, data: any[] | Object, index: number) => {
        //   const self = this;
        //   $('.delete-btn', row).unbind('click');
        //   $('.edit-btn', row).unbind('click');
        //   $('.delete-btn', row).bind('click', () => {
        //     self.deleteData(data, row);
        //   });
        //   $('.edit-btn', row).bind('click', () => {
        //     self.editData(data);
        //   });
        //   return row;
        // }
      }
    } else if ((this.dashboardCDERole == 'CDETL')) {
      this.dtOptions2CDETL = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "connectedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "contactedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "isFinalCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "sendbackCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "assignedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
        // rowCallback: (row: Node, data: any[] | Object, index: number) => {
        //   const self = this;
        //   $('.delete-btn', row).unbind('click');
        //   $('.edit-btn', row).unbind('click');
        //   $('.delete-btn', row).bind('click', () => {
        //     self.deleteData(data, row);
        //   });
        //   $('.edit-btn', row).bind('click', () => {
        //     self.editData(data);
        //   });
        //   return row;
        // }
      }
    }
  }

  filterDashboard(){
    //Read Calendar
    let yearString = this.filterDate.year;
    let monthString = this.filterDate.month.toString().length == 1 ? "0" + this.filterDate.month : this.filterDate.month;
    let dayString =  this.filterDate.day.toString().length == 1 ? "0" + this.filterDate.day : this.filterDate.day;
    this.filterDateDailyString = yearString + "-" + monthString + "-" + dayString;
    this.filterDateMonthlyString = yearString + "/" + monthString;
    //End
    //Promise Set URL New Ajax
      let optionPromiseCDE = new Promise((resolve, reject) => {
        this.dtOptionsCDE.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token }
        }

        this.dtOptions2CDE.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
        }

        resolve("success");
      });
      //End
  
      //Finish Promise render datatables again
      optionPromiseCDE.then(() => {
        this.rerenderCDE();
      });

      let optionPromiseCDETL = new Promise((resolve, reject) => {
        this.dtOptionsCDETL.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token }
        }

        this.dtOptions2CDETL.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCDERole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
        }

        resolve("success");
      });
      //End
  
      //Finish Promise render datatables again
      optionPromiseCDETL.then(() => {
        this.rerenderCDETL();
      });
  }

  clearFilter(){
    this.filterDate = "";
    this.filterDateDailyString = "";
    this.filterDateMonthlyString = "";
    window.location.reload();
    this.spinner.show();

    let tmpRequest = {
      size: '10',
      currentUserId: this.userInfo.userId
    }

    this.apiUtil.invokeAPI("DASHBOARD_"+ this.userInfo.roleId +"_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          this.dataDashboard = res.data.content;
        }
        else{
          this.dataDashboard = [];
        }
        
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  ngAfterViewInit() {
    this.dtTriggerCDE.next();
    this.dtTrigger2CDE.next();
    this.dtTriggerCDETL.next();
    this.dtTrigger2CDETL.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerCDE.unsubscribe();
    this.dtTriggerCDETL.unsubscribe();
    this.dtTrigger2CDE.unsubscribe();
    this.dtTrigger2CDETL.unsubscribe();
  }

  rerenderCDE(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
    dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
    });
  });
      this.dtTriggerCDE.next();
      this.dtTrigger2CDE.next();
  }

  rerenderCDETL(): void {
      this.dtTriggerCDETL.next();
      this.dtTrigger2CDETL.next();
  }
}
