import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-prospect-detail-sd-cde',
  templateUrl: './prospect-detail-sd-cde.component.html',
  styleUrls: ['./prospect-detail-sd-cde.component.css']
})
export class ProspectDetailSdCdeComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  viewMode = 'tab1';
  model: NgbDateStruct;
  idDetail: any;
  dataProspectDetail: any;
  dateToday: any;

  inputCustomerNumber: any;
  inputContractNumber: any;
  inputMobileNo2: any;

  connectivityLov: any;
  contactivityLov: any;
  resultLov: any;
  resultReasonLov: any;

  connectivitySelected: any;
  connectivityKeySelected: any = "";

  contactivitySelected: any;
  contactivityKeySelected: any = "";

  resultSelected: any;
  resultKeySelected: any = "";
  resultReasonSelected: any;
  resultReasonKeySelected: any = "";

  promiseDate: NgbDateStruct;
  promiseDateString: any = "";

  custAppNo: any = "";
  custAppDate: NgbDateStruct;
  custAppDateString: any = "";

  description: any = "";

  srcImgTemp: any = '../../../assets/images/drop-file.png';
  srcImgTempDetail: any = '../../../assets/images/drop-file.png';
  base64ImgString: any = "";
  srcFileName: any = "";

  isPopulated: any = 0;
  isPopulatedDetail: any = 0;

  checkedCheckBox: any = false;

  showButtonShowFileExisting: any = false;
  showButtonShowFileExistingDetail: any = false;
  showButtonShowImageExisting: any = false;

  formProspect: any = {
    id: null,
    userCrt: null,
    prospectResult: {
      isDraft: null,
      isFinal: null,
      connectivity: null,
      contactivity: null,
      result: null,
      resultReason: null,
      promiseDate: null,
      isSentToTm: null,
      custAppNo: null,
      custAppDate: null,
      description: null,
      attachment: null
    }
  };

  minDatePicker: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, 
    day: new Date().getDate()
  }

  ngOnInit(): void {
    this.spinner.show();
    this.idDetail = this.route.snapshot.params.id;

    let tmpRequest = {
      id: this.idDetail
    };

    this.apiUtil.invokeAPI("PROSPECT_CDE_DETAIL", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        // console.log("Prospect CDE Detail", res);
        this.dataProspectDetail = res.data;

        this.getLovConnectivity('L001');
        this.getLovContactivity('L006');
        this.getLovResult('L009');
        this.getLovResultReason('L003');

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovConnectivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.connectivityLov = res.data;
        this.connectivityKeySelected = this.connectivityLov[0].key;
        this.connectivitySelected = this.connectivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovContactivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.contactivityLov = res.data;
        this.contactivityKeySelected = this.contactivityLov[0].key;
        this.contactivitySelected = this.contactivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResult(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultLov = res.data;
        this.resultKeySelected = this.resultLov[0].key;
        this.resultSelected = this.resultLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResultReason(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultReasonLov = res.data;
        this.resultReasonKeySelected = this.resultReasonLov[0].key;
        this.resultReasonSelected = this.resultReasonLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  connectivityOnChange(key, value){
    this.connectivityKeySelected = key;
    this.connectivitySelected = value;

    //Reset value if not connected
    if(key != 'L001-1' && key != 'L002-1'){
      this.contactivityKeySelected = null;
      this.contactivitySelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.custAppNo = null;
      this.custAppDate = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  contactivityOnChange(key, value){
    this.contactivityKeySelected = key
    this.contactivitySelected = value;

    //Reset value if not connected
    if(key != 'L006-1' && key != 'L007-1'){
      this.resultKeySelected = null;
      this.resultSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.custAppNo = null;
      this.custAppDate = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  resultOnChange(key, value){
    this.resultKeySelected = key
    this.resultSelected = value;
  }

  resultReasonOnChange(key, value){
    this.resultReasonKeySelected = key
    this.resultReasonSelected = value;
  }

  compareDate(dateSla){
    let dateSlaRaw = new Date(dateSla);
    let dateSlaa = moment(dateSlaRaw).format('YYYY-MM-DD HH:mm:ss');

    let dateToday = moment().format('YYYY-MM-DD HH:mm:ss');

    if(dateSlaa < dateToday){
      return true;
    }
    else{
      return false;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  alphanumericOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // console.log(charCode);
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode > 96 && charCode < 123)) {
      return true;
    }
    return false;
  }

  editPhone(){
    $(document).ready(function(){
      $('.editPhone').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editPhone').addClass('d-none'); 
      console.log('yes')
    });
  }

  editCustNum(){
    $(document).ready(function(){
      $('.editCustNum').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editCustNum').addClass('d-none'); 
      console.log('yes')
    });
  }

  editContractNum(){
    $(document).ready(function(){
      $('.editContractNum').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editContractNum').addClass('d-none'); 
      console.log('yes')
    });
  }

  changeCustNo(){
    let tmpRequest = {
      id: this.idDetail,
      type: "customerNumber",
      value: this.inputCustomerNumber
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  changeContractNo(){
    let tmpRequest = {
      id: this.idDetail,
      type: "contractNumber",
      value: this.inputContractNumber
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  checkMobileNo2(){
    if (this.inputMobileNo2[0] != '0'){
      this.toastr.error("Invalid Format Mobile Phone");
    }
    else if ((this.inputMobileNo2[0] == '0') && (this.inputMobileNo2.length >= '5')){ 
      this.changeMobileNo2();
    }
    else if(this.inputMobileNo2.length < '5' ) {
      this.toastr.error("Mobile number error at least 5 digits");
    }
    else if(this.inputMobileNo2 == "" ) {
      this.toastr.error("Please Fill Mobile Phone ");
    }
  }

  changeMobileNo2(){
    let tmpRequest = {
      id: this.idDetail,
      type: "mobilePhone2",
      value: this.inputMobileNo2
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.base64ImgString = reader.result;
      
      if(this.base64ImgString.includes("application/pdf")){
        this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
        this.srcFileName = file.name;
      }
      else{
        this.srcImgTemp = (this.domSanitizer.bypassSecurityTrustResourceUrl(this.base64ImgString) as any).changingThisBreaksApplicationSecurity;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
       console.log("No file selected!");
       return
    }
    let file: File = event.target.files[0];
    
    if(file.size < (5000 * 1024)){
      await this.getBase64(file);
    }
    else{
      this.toastr.error("Attachment more than 5 MB");
    }
  }

  clearImgUpload(){
    this.srcImgTemp = "../../../assets/images/drop-file.png"; 
    this.base64ImgString = null;
    this.srcFileName = "";

    this.showButtonShowFileExisting = false;
    this.showButtonShowImageExisting = false;
  }

  checkSentTm(){
    if(!this.checkedCheckBox){
      this.checkedCheckBox = true;
    }
    else{
      this.checkedCheckBox = false;
    }
  }

  openFileExisting(){
    let base64String  = this.dataProspectDetail.prospectResult.attachment.split(',')[1];
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  openFileExistingDetail(){
    let base64String  = this.dataProspectDetail.prospectDetail.attachment.split(',')[1];
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  openImage(){
    let base64String  = this.dataProspectDetail.prospectDetail.attachment;
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName + '.' + base64String.split('/')[1].split(';')[0];
    downloadLink.click();
  }

  openImageResult(){
    let base64String  = this.dataProspectDetail.prospectResult.attachment;
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName + '.' + base64String.split('/')[1].split(';')[0];
    downloadLink.click();
  }

  populateData(){
    if(this.isPopulated == 0){
      //Set Prospect Result if exist
      if(this.resultReasonLov){
        this.resultReasonKeySelected = this.dataProspectDetail.prospectResult.resultReason != null ? this.dataProspectDetail.prospectResult.resultReason : null;
        if(this.resultReasonKeySelected != null){
          this.resultReasonSelected = this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value ? this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value : null;
        }
        else{
          this.resultReasonSelected = null;
        }
      }

      this.connectivityKeySelected = this.dataProspectDetail.prospectResult.connectivity != null ? this.dataProspectDetail.prospectResult.connectivity : null;
      if(this.connectivityKeySelected != null){
        this.connectivitySelected = this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value ? this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value : null;
      }
      else{
        this.connectivitySelected = null;
      }

      this.contactivityKeySelected = this.dataProspectDetail.prospectResult.contactivity != null ? this.dataProspectDetail.prospectResult.contactivity : null;
      if(this.contactivityKeySelected != null){
        this.contactivitySelected = this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value ? this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value : null;
      }
      else{
        this.contactivitySelected = null;
      }
      
      this.resultKeySelected = this.dataProspectDetail.prospectResult.result != null ? this.dataProspectDetail.prospectResult.result : null;
      if(this.resultKeySelected != null){
        this.resultSelected = this.resultLov.find(el => el.key === this.resultKeySelected).value ? this.resultLov.find(el => el.key === this.resultKeySelected).value : null;
      }
      else{
        this.resultSelected = null;
      }

      //Assign value to ngbDate
      if(this.dataProspectDetail.prospectResult.promiseDate != null){
        let dateCustomer = moment(this.dataProspectDetail.prospectResult.promiseDate, "YYYY-MM-DD").format("YYYY-M-D");
        let yearSt = Number(dateCustomer.split('-')[0]);
        let monthSt = Number(dateCustomer.split('-')[1]);
        let daySt = Number(dateCustomer.split('-')[2]);
        this.promiseDate = new NgbDate(yearSt, monthSt, daySt);
      }
      else{
        this.promiseDate = null;
      }

      if(this.dataProspectDetail.prospectResult.isSentToTm == "Y"){
        this.checkedCheckBox = true;
      }
      else{
        this.checkedCheckBox = false;
      }

      this.custAppNo = this.dataProspectDetail.prospectResult.custAppNo != null ? this.dataProspectDetail.prospectResult.custAppNo : null;
      //Assign value to ngbDate
      if(this.dataProspectDetail.prospectResult.custAppDate != null){
        let dateCustomer = moment(this.dataProspectDetail.prospectResult.custAppDate, "YYYY-MM-DD").format("YYYY-M-D");
        let yearSt = Number(dateCustomer.split('-')[0]);
        let monthSt = Number(dateCustomer.split('-')[1]);
        let daySt = Number(dateCustomer.split('-')[2]);
        this.custAppDate = new NgbDate(yearSt, monthSt, daySt);
      }
      else{
        this.custAppDate = null;
      }

      this.description = this.dataProspectDetail.prospectResult.description != null ? this.dataProspectDetail.prospectResult.description : null;
      
      if(this.dataProspectDetail.prospectResult.attachment != null){
        if(this.dataProspectDetail.prospectResult.attachment.split(',')[0].includes("image")){
          this.srcImgTemp = this.dataProspectDetail.prospectResult.attachment != null ? this.dataProspectDetail.prospectResult.attachment : "../../../assets/images/drop-file.png"; 
          this.showButtonShowImageExisting = true;
        }
        else{
          this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
          this.showButtonShowFileExisting = true;
        }
      }
      else{
        this.srcImgTemp = "../../../assets/images/drop-file.png"; 
      }      
      //End

      //Populate Detail
      if(this.dataProspectDetail.prospectDetail.attachment != null){
        if(this.dataProspectDetail.prospectDetail.attachment.split(',')[0].includes("image")){
          this.srcImgTempDetail = this.dataProspectDetail.prospectDetail.attachment != null ? this.dataProspectDetail.prospectDetail.attachment : "../../../assets/images/drop-file.png"; 
        }
        else{
          this.srcImgTempDetail = "../../../assets/images/icons/prospect-blue.png";
          this.showButtonShowFileExistingDetail = true;
        }
      }
      else{
        this.srcImgTempDetail = "../../../assets/images/drop-file.png"; 
      }  
      //End

      this.isPopulated = 1;
    }
    else{

    }
  }

  populateDetail(){
    if(this.isPopulatedDetail == 0){
      //Populate Detail
      if(this.dataProspectDetail.prospectDetail.attachment != null){
        if(this.dataProspectDetail.prospectDetail.attachment.split(',')[0].includes("image")){
          this.srcImgTempDetail = this.dataProspectDetail.prospectDetail.attachment != null ? this.dataProspectDetail.prospectDetail.attachment : "../../../assets/images/drop-file.png"; 
        }
        else{
          this.srcImgTempDetail = "../../../assets/images/icons/prospect-blue.png";
          this.srcFileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
          this.showButtonShowFileExistingDetail = true;
        }
      }
      else{
        this.srcImgTempDetail = "../../../assets/images/drop-file.png"; 
      }  
      //End

      this.isPopulatedDetail = 1;
    }
    else{

    }
  }

  submitForm(){
    this.formProspect.id = this.idDetail;
    this.formProspect.userCrt = this.userInfo.userId;
    this.formProspect.prospectResult.isDraft = "N";
    this.formProspect.prospectResult.isFinal = "Y";

    if(this.connectivityKeySelected != null && this.connectivityKeySelected != ""){
      if(this.connectivityKeySelected == "L001-1"){
        //If Connected
        if(this.contactivityKeySelected != null && this.contactivityKeySelected != ""){
          if(this.contactivityKeySelected == "L006-1"){
            //If Connected & Contacted
            //Check Result Status if null
            if(this.resultKeySelected == null || this.resultKeySelected == ""){
              this.toastr.error("Please Fill Result");
            }
            if(this.resultKeySelected != null || this.resultKeySelected != ""){
              if(this.resultKeySelected == "L009-1"){
                //Check cust app no if null
                if(this.custAppNo == null || this.custAppNo == ""){
                  this.toastr.error("Please Fill Cust App No");
                }
                //Check cust app date if null
                if(this.custAppDate == null || this.custAppDate == undefined){
                  this.toastr.error("Please Fill Cust App Date");
                }
                else if(
                  (this.custAppNo != null && this.custAppNo != "") &&
                  (this.custAppDate != null && this.custAppDate != undefined)
                ){
                  //Pass hit submit
                  this.hitSubmitSDCDE();
                }

              }
              if(this.resultKeySelected == "L009-2"){
                if(this.checkedCheckBox){
                  this.hitSubmitSDCDE();
                }
                else{
                  if(this.promiseDate == null || this.promiseDate == undefined){
                    this.toastr.error("Please Fill Promise Date");
                  }
                  else{
                    this.hitSubmitSDCDE();
                  }
                }    
              }
              if(this.resultKeySelected == "L009-3"){
                //Check Result Reason if null
                if(this.resultReasonKeySelected == null || this.resultReasonKeySelected == ""){
                  this.toastr.error("Please Fill Result Reason");
                }   
                else{
                  //Pass hit submit
                  this.hitSubmitSDCDE();
                }  
              }
            }
          }
          else{
            //Contactivity != contacted
            this.hitSubmitSDCDE();
          }
        }
        else{
          this.toastr.error("Please Fill Contactivity");
        }
      }
      else{
        //Connectivity != connected
        this.hitSubmitSDCDE();
      }
    }
    else{
      this.toastr.error("Please Fill Connectivity");
    }

  }

  saveForm(){
    this.formProspect.id = this.idDetail;
    this.formProspect.userCrt = this.userInfo.userId;
    this.formProspect.prospectResult.isDraft = "Y";
    this.formProspect.prospectResult.isFinal = "N";
    this.formProspect.prospectResult.connectivity = this.connectivityKeySelected != "" ? this.connectivityKeySelected : null;
    this.formProspect.prospectResult.contactivity = this.contactivityKeySelected != "" ? this.contactivityKeySelected : null; 
    this.formProspect.prospectResult.result = this.resultKeySelected != "" ? this.resultKeySelected : null;
    this.formProspect.prospectResult.resultReason = this.resultReasonKeySelected != "" ? this.resultReasonKeySelected : null;

    if(this.promiseDate && this.promiseDate != undefined){
      let yearString = this.promiseDate.year;
      let monthString = this.promiseDate.month.toString().length == 1 ? "0" + this.promiseDate.month : this.promiseDate.month;
      let dayString =  this.promiseDate.day.toString().length == 1 ? "0" + this.promiseDate.day : this.promiseDate.day;
      this.promiseDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.promiseDate = this.promiseDateString ? this.promiseDateString : null;
    }

    this.formProspect.prospectResult.isSentToTm = this.checkedCheckBox ? "Y" : "N";

    this.formProspect.prospectResult.custAppNo = this.custAppNo != "" ? this.custAppNo : null;

    if(this.custAppDate && this.custAppDate != undefined){
      let yearString = this.custAppDate.year;
      let monthString = this.custAppDate.month.toString().length == 1 ? "0" + this.custAppDate.month : this.custAppDate.month;
      let dayString =  this.custAppDate.day.toString().length == 1 ? "0" + this.custAppDate.day : this.custAppDate.day;
      this.custAppDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.custAppDate = this.custAppDateString ? this.custAppDateString : null;
    }

    this.formProspect.prospectResult.description = this.description != "" ? this.description : null;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;

    this.apiUtil.invokeAPI("PROSPECT_CDE_SUBMIT", this.formProspect).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        this.router.navigate(['/homepage/prospect-cde']);
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  hitSubmitSDCDE(){
    this.formProspect.prospectResult.connectivity = this.connectivityKeySelected != "" ? this.connectivityKeySelected : null;
    this.formProspect.prospectResult.contactivity = this.contactivityKeySelected != "" ? this.contactivityKeySelected : null; 
    this.formProspect.prospectResult.result = this.resultKeySelected != "" ? this.resultKeySelected : null;
    this.formProspect.prospectResult.resultReason = this.resultReasonKeySelected != "" ? this.resultReasonKeySelected : null;
    this.formProspect.prospectResult.custAppNo = this.custAppNo != "" ? this.custAppNo : null;

    if(this.custAppDate && this.custAppDate != undefined){
      let yearString = this.custAppDate.year;
      let monthString = this.custAppDate.month.toString().length == 1 ? "0" + this.custAppDate.month : this.custAppDate.month;
      let dayString =  this.custAppDate.day.toString().length == 1 ? "0" + this.custAppDate.day : this.custAppDate.day;
      this.custAppDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.custAppDate = this.custAppDateString ? this.custAppDateString : null;
    }

    if(this.promiseDate && this.promiseDate != undefined){
      let yearString = this.promiseDate.year;
      let monthString = this.promiseDate.month.toString().length == 1 ? "0" + this.promiseDate.month : this.promiseDate.month;
      let dayString =  this.promiseDate.day.toString().length == 1 ? "0" + this.promiseDate.day : this.promiseDate.day;
      this.promiseDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.promiseDate = this.promiseDateString ? this.promiseDateString : null;
    }

    this.formProspect.prospectResult.description = this.description != "" ? this.description : null;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;

    this.apiUtil.invokeAPI("PROSPECT_CDE_SUBMIT", this.formProspect).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        this.router.navigate(['/homepage/prospect-cde']);
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  productName(prodId){
    switch (prodId) {
      case "SD":
        return "Siap Dana";
      case "NC":
        return "New Car";
      case "KI":
        return "Kinto";
      case "IU":
        return "Insurance Upgrade";
      case "IR": 
        return "Insurance Renewal";
      default:
        return prodId;
    }
  }

  typeName(typeId){
    switch (typeId) {
      case "UP":
        return "Upload";
      case "EN":
        return "Entry";
      case "DB":
        return "Database";
      default:
        return typeId;
    }
  }

}
