import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AssertNotNull } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Api } from '../utility/Api';
declare var $: any;
@Component({
	selector: 'sidebar-cmp',
	templateUrl: 'sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
	menuActive: any = {};
	menuList: any;

	constructor(
		private router: Router,
		private apiUtil: Api,
		private toastr: ToastrService
	) { }

	userData = JSON.parse(localStorage.getItem("userData"));
	userInfo = JSON.parse(localStorage.getItem("userInfo"));

	ngOnInit() {
		$('.nk-menu-trigger.desktop').on('click', function(){
			$('.nk-sidebar').toggleClass('is-compact');
			$('.menu-logo').toggleClass('d-none');
		});

		$('.nk-menu-trigger.mobile').on('click', function(){
			$('.nk-sidebar').toggleClass('nk-sidebar-active')
			if($('.nk-sidebar').hasClass('nk-sidebar-active')){
				$('.nk-wrap').addClass('nk-wrap-active');
			}else{
			$('.nk-wrap').removeClass('nk-wrap-active');	
			}
		});

		$('.nk-nav-toggle.mobile').on('click', function(){
			$('.nk-sidebar').toggleClass('nk-sidebar-active');
			if($('.nk-sidebar').hasClass('nk-sidebar-active')){
				$('.nk-wrap').addClass('nk-wrap-active');
			}else{
			$('.nk-wrap').removeClass('nk-wrap-active');	
			}
		});
		$(document).on('scroll', function(){
			if ( $(window).scrollTop() > 30) {
				// console.log('hheee');
				$('#container-header').addClass('change-color');
			} else {
				$('#container-header').removeClass('change-color');
				// console.log('apa?')
			}
		});
		

		// this.menuActive.dashboard = true;
		// this.menuActive.prospect = false;
		// this.menuActive.customerHandling = false;
		
		if(this.router.url.includes('dashboard')){
			// this.menuActive.dashboard = true;
			// this.menuActive.prospect = false;
			// this.menuActive.customerHandling = false;
			this.menuActive = "dashboard";
		}
		else if(this.router.url.includes('prospect')){
			// this.menuActive.dashboard = false;
			// this.menuActive.prospect = true;
			// this.menuActive.customerHandling = false;
			this.menuActive = "prospect";
		}
		else if(this.router.url === '/homepage'){
			// this.menuActive.dashboard = true;
			// this.menuActive.prospect = false;
			// this.menuActive.customerHandling = false;
			this.menuActive = "dashboard";
		}
		else{
			// this.menuActive.dashboard = false;
			// this.menuActive.prospect = false;
			// this.menuActive.customerHandling = true;
			this.menuActive = "customerhandling";
		}
	}

	addActive(menuName){
		this.menuActive = menuName;
		// if(menuName == 'dashboard'){
		// 	Object.keys(this.menuActive).forEach(v => this.menuActive[v] = false)
		// 	this.menuActive.dashboard = true;
		// }
		// else if(menuName == 'prospect'){
		// 	Object.keys(this.menuActive).forEach(v => this.menuActive[v] = false)
		// 	this.menuActive.prospect = true;
		// }
		// else if(menuName == 'customerHandling'){
		// 	Object.keys(this.menuActive).forEach(v => this.menuActive[v] = false)
		// 	this.menuActive.customerHandling = true;
		// }
	}

	activeButton=1;

	showPhase(event){
	  this.activeButton = event;
	}

	logoutSubmit(){
		localStorage.clear();
		this.router.navigate(['/']);
	}

	checkToken(){
		//Untuk Cek Token
		if(this.userInfo != null){
			//Get Role
			let tmpRequest = {
				userId: this.userInfo.userId,
			};
	
			this.apiUtil.invokeAPI("GET_ROLE_USER", tmpRequest).subscribe(
				res => {
					// console.log("res", res);
				}, 
				err => {
					// console.log("errsidebarcek", err);
					if(err.status == '401'){
						localStorage.clear();
						this.router.navigate(['/']);
					}
					else{
						localStorage.clear();
						this.router.navigate(['/']);
					}
				}
			);
			//End

			//Get Menu	
			this.apiUtil.invokeAPI("GET_ROLE_MENU", tmpRequest).subscribe(
				res => {
					// console.log("menukita", res);
					if(res.state.status == '200'){
						if(res.state.error == 'emptymenu'){
							localStorage.clear();
							this.toastr.error("Empty Menu Assigned");
							this.router.navigate(['/']);
						}
						else{
							this.menuList = res.data;
						}
					}
				}, 
				err => {
					// console.log("errsidebarcek", err);
					if(err.status == '401'){
						localStorage.clear();
						this.router.navigate(['/']);
					}
					else{
						localStorage.clear();
						this.router.navigate(['/']);
					}
				}
			);
			//End
		}
		else{
			this.router.navigate(['/']);
		}

	}
}