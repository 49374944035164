import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  viewMode = 'tab1';

  filterDate: any = "";
  filterDateDailyString: any = "";
  filterDateMonthlyString: any = "";
  startPage = 0;
  dataDashboard: any;

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  // @ViewChild(DataTableDirective, {static: false}) dtElement2: DataTableDirective;
  // @ViewChild(DataTableDirective, {static: false}) dtElement3: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtTriggerStatus: Subject<any> = new Subject();
  dtTriggerTL: Subject<any> = new Subject();
  dtTriggerTLStatus: Subject<any> = new Subject();
  dtTriggerSPV: Subject<any> = new Subject();
  dtTriggerSPVStatus: Subject<any> = new Subject();

  dtTrigger2: Subject<any> = new Subject();
  dtTrigger2Status: Subject<any> = new Subject();
  dtTrigger2TL: Subject<any> = new Subject();
  dtTrigger2TLStatus: Subject<any> = new Subject();
  dtTrigger2SPV: Subject<any> = new Subject();
  dtTrigger2SPVStatus: Subject<any> = new Subject();

  dtTrigger3TL: Subject<any> = new Subject();
  dtTrigger3SPV: Subject<any> = new Subject();

  dtOptions: any = {};
  dtOptionsStatus: any = {};
  dtOptionsTL: any = {};
  dtOptionsTLStatus: any = {};
  dtOptionsSPV: any = {};
  dtOptionsSPVStatus: any = {};

  dtOptions2: any = {};
  dtOptions2Status: any = {};
  dtOptions2TL: any = {};
  dtOptions2TLStatus: any = {};
  dtOptions2SPV: any = {};
  dtOptions2SPVStatus: any = {};

  dtOptions3TL: any = {};
  dtOptions3SPV: any = {};
  
  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  dashboardRole: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.dashboardRole = this.userInfo.roleId;

    if((this.dashboardRole == 'CDE') || (this.dashboardRole == 'CDETL')){
      this.router.navigate(['/homepage/dashboard-cde']);
    }
    if((this.dashboardRole == 'SRO') || (this.dashboardRole == 'SO') || (this.dashboardRole == 'SH')) {
      this.router.navigate(['/homepage/dashboard-sh']);
    }
    if((this.dashboardRole == 'SEH')) {
      this.router.navigate(['/homepage/dashboard-seh']);
    }
    if((this.dashboardRole == 'BM' || this.dashboardRole == 'BH')) {
      this.router.navigate(['/homepage/dashboard-bh']);
    }
    if(this.dashboardRole == "CSO"){
      this.router.navigate(['/homepage/dashboard-cso']);
    }

    let dateToday = moment().format('YYYY-MM-DD');
    let dateMonth = moment().format('YYYY/M');
    this.filterDate = "";
    this.filterDateDailyString = "";
    this.filterDateMonthlyString = "";

    //Declare Table Daily
    if(this.dashboardRole == 'SPV'){
      this.dtOptionsSPV = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "parentUserId",
          }, 
          {
            data: "userId"
          }, 
          {
            data: "leadSource",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "newCount"
          }, 
          {
            data: "nfuCount"
          }, 
          {
            data: "isDraftCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          },
          {
            data: "coldCount"
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "appInCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "appValidCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      };

      this.dtOptionsSPVStatus = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "parentUserId",
          }, 
          {
            data: "userId"
          },
          {
            data: "dataEntryCount",
          }, 
          {
            data: "creditReviewCount",
          }, 
          {
            data: "approveCount",
          }, 
          {
            data: "contractSigningCount",
          }, 
          {
            data: "customerConfirmationCount",
          }, 
          {
            data: "validCount",
          }, 
          {
            data: "cancelCount",
          }, 
          {
            data: "rejectCount",
          }, 
          {
            data: "totalCount",
          }
        ]
      };

    }
    else if(this.dashboardRole == 'TL'){
      this.dtOptionsTL = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          }, 
          {
            data: "newCount"
          }, 
          {
            data: "nfuCount"
          }, 
          {
            data: "isDraftCount"
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          },
          {
            data: "coldCount"
          }, 
          {
            data: "resultNfuCount"
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "appInCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "appValidCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      };

      this.dtOptionsTLStatus = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          },
          {
            data: "dataEntryCount",
          }, 
          {
            data: "creditReviewCount",
          }, 
          {
            data: "approveCount",
          }, 
          {
            data: "contractSigningCount",
          }, 
          {
            data: "customerConfirmationCount",
          }, 
          {
            data: "validCount",
          }, 
          {
            data: "cancelCount",
          }, 
          {
            data: "rejectCount",
          }, 
          {
            data: "totalCount",
          }
        ]
      };

    }
    else if (this.dashboardRole == 'TM'){
      this.dtOptions = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          }, 
          {
            data: "newCount"
          }, 
          {
            data: "nfuCount"
          }, 
          {
            data: "isDraftCount"
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          },
          {
            data: "coldCount"
          }, 
          {
            data: "resultNfuCount"
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "appInCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "appValidCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        // dom: 'lBfrtip',
        // buttons: [
        //   'excel'
        // ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      };

      this.dtOptionsStatus = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          }, 
          {
            data: "dataEntryCount",
          }, 
          {
            data: "creditReviewCount",
          }, 
          {
            data: "approveCount",
          }, 
          {
            data: "contractSigningCount",
          }, 
          {
            data: "customerConfirmationCount",
          }, 
          {
            data: "validCount",
          }, 
          {
            data: "cancelCount",
          }, 
          {
            data: "rejectCount",
          }, 
          {
            data: "totalCount",
          }
        ]
      };

    }

    //Declare Table Monthly
    if(this.dashboardRole == 'SPV'){
      this.dtOptions2SPV = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "parentUserId",
          }, 
          {
            data: "userId"
          }, 
          {
            data: "leadSource",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "newCount"
          }, 
          {
            data: "nfuCount"
          }, 
          {
            data: "isDraftCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          },
          {
            data: "coldCount"
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "appInCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "appValidCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      };

      this.dtOptions2SPVStatus = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "parentUserId",
          }, 
          {
            data: "userId"
          },
          {
            data: "dataEntryCount",
          }, 
          {
            data: "creditReviewCount",
          }, 
          {
            data: "approveCount",
          }, 
          {
            data: "contractSigningCount",
          }, 
          {
            data: "customerConfirmationCount",
          }, 
          {
            data: "validCount",
          }, 
          {
            data: "cancelCount",
          }, 
          {
            data: "rejectCount",
          }, 
          {
            data: "totalCount",
          }
        ]
      };

    }
    else if(this.dashboardRole == 'TL'){
      this.dtOptions2TL = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          }, 
          {
            data: "newCount"
          }, 
          {
            data: "nfuCount"
          }, 
          {
            data: "isDraftCount"
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          },  
          {
            data: "coldCount"
          },
          {
            data: "resultNfuCount"
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "appInCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "appValidCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      };

      this.dtOptions2TLStatus = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          },
          {
            data: "dataEntryCount",
          }, 
          {
            data: "creditReviewCount",
          }, 
          {
            data: "approveCount",
          }, 
          {
            data: "contractSigningCount",
          }, 
          {
            data: "customerConfirmationCount",
          }, 
          {
            data: "validCount",
          }, 
          {
            data: "cancelCount",
          }, 
          {
            data: "rejectCount",
          }, 
          {
            data: "totalCount",
          }
        ]
      };

    }
    else if (this.dashboardRole == 'TM') {
      this.dtOptions2 = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          }, 
          {
            data: "newCount"
          }, 
          {
            data: "nfuCount"
          }, 
          {
            data: "isDraftCount"
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          },
          {
            data: "coldCount"
          }, 
          {
            data: "resultNfuCount"
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "appInCount",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "appValidCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }

      this.dtOptions2Status = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
          }, 
          {
            data: "dataEntryCount",
          }, 
          {
            data: "creditReviewCount",
          }, 
          {
            data: "approveCount",
          }, 
          {
            data: "contractSigningCount",
          }, 
          {
            data: "customerConfirmationCount",
          }, 
          {
            data: "validCount",
          }, 
          {
            data: "cancelCount",
          }, 
          {
            data: "rejectCount",
          }, 
          {
            data: "totalCount",
          }
        ]
      }

    }

    //Declare Table Performance 
    if(this.dashboardRole == 'TL'){
      this.dtOptions3TL = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_PERFORMANCE"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "branchId",
          }, 
          {
            data: null,
            render: function(data){
              return parseInt(data.newCount) + parseInt(data.nfuCount); 
            }
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          }, 
          {
            data: "resultInterestedCount"
          }, 
          {
            data: "resultNfuCount"
          }, 
          {
            data: "resultNotInterestedCount"
          }, 
          {
            data: null,
            render: function(data){
              return "-"; 
            }
          }, 
          {
            data: null,
            render: function(data){
              return "-"; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
        // rowCallback: (row: Node, data: any[] | Object, index: number) => {
        //   const self = this;
        //   $('.delete-btn', row).unbind('click');
        //   $('.edit-btn', row).unbind('click');
        //   $('.delete-btn', row).bind('click', () => {
        //     self.deleteData(data, row);
        //   });
        //   $('.edit-btn', row).bind('click', () => {
        //     self.editData(data);
        //   });
        //   return row;
        // }
      }
    }
    else if(this.dashboardRole == 'SPV'){
      this.dtOptions3SPV = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_PERFORMANCE"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "branchId",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "leadSource",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: null,
            render: function(data){
              return parseInt(data.newCount) + parseInt(data.nfuCount); 
            }
          }, 
          {
            data: "connectedCount"
          }, 
          {
            data: "contactedCount"
          }, 
          {
            data: "probingActionCount"
          }, 
          {
            data: "hotCount"
          }, 
          {
            data: "warmCount"
          }, 
          {
            data: "resultInterestedCount"
          }, 
          {
            data: "resultNfuCount"
          }, 
          {
            data: "resultNotInterestedCount"
          }, 
          {
            data: null,
            render: function(data){
              return "-"; 
            }
          }, 
          {
            data: null,
            render: function(data){
              return "-"; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }
    }
  }

  filterDashboard(){
    //Read Calendar
    let yearString = this.filterDate.year;
    let monthString = this.filterDate.month.toString().length == 1 ? "0" + this.filterDate.month : this.filterDate.month;
    let dayString =  this.filterDate.day.toString().length == 1 ? "0" + this.filterDate.day : this.filterDate.day;
    this.filterDateDailyString = yearString + "-" + monthString + "-" + dayString;
    this.filterDateMonthlyString = yearString + "/" + monthString;
    //End

    //Promise Set URL New Ajax
    let optionPromiseTM = new Promise((resolve, reject) => {
      this.dtOptions.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptionsStatus.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptions2.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }

      this.dtOptions2Status.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }

      resolve("success");
    });
    //End
  
    //Finish Promise render datatables again
    optionPromiseTM.then(() => {
      this.rerenderTM();
    });

    let optionPromiseTL = new Promise((resolve, reject) => {
      this.dtOptionsTL.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptionsTLStatus.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptions2TL.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }

      this.dtOptions2TLStatus.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }

      this.dtOptions3TL.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_PERFORMANCE"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }
      resolve("success");
    });
    //End
  
    //Finish Promise render datatables again
    optionPromiseTL.then(() => {
      this.rerenderTL();
    });

    let optionPromiseSPV = new Promise((resolve, reject) => {
      this.dtOptionsSPV.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptionsSPVStatus.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_DAILY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptions2SPV.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }

      this.dtOptions2SPVStatus.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_MONTHLY_STATUS"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }

      this.dtOptions3SPV.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardRole + "_PERFORMANCE"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }
      resolve("success");
    });
    //End
  
    //Finish Promise render datatables again
    optionPromiseSPV.then(() => {
      this.rerenderSPV();
    });
    //End
  }

  clearFilter(){
    window.location.reload();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.dtTriggerStatus.next();
    this.dtTriggerTL.next();
    this.dtTriggerTLStatus.next();
    this.dtTriggerSPV.next();
    this.dtTriggerSPVStatus.next();

    this.dtTrigger2.next();
    this.dtTrigger2Status.next();
    this.dtTrigger2TL.next();
    this.dtTrigger2TLStatus.next();
    this.dtTrigger2SPV.next();
    this.dtTrigger2SPVStatus.next();

    this.dtTrigger3TL.next();
    this.dtTrigger3SPV.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTriggerStatus.unsubscribe();
    this.dtTriggerTL.unsubscribe();
    this.dtTriggerTLStatus.unsubscribe();
    this.dtTriggerSPV.unsubscribe();
    this.dtTriggerSPVStatus.unsubscribe();

    this.dtTrigger2.unsubscribe();
    this.dtTrigger2Status.unsubscribe();
    this.dtTrigger2TL.unsubscribe();
    this.dtTrigger2TLStatus.unsubscribe();
    this.dtTrigger2SPV.unsubscribe();
    this.dtTrigger2SPVStatus.unsubscribe();

    this.dtTrigger3TL.unsubscribe();
    this.dtTrigger3SPV.unsubscribe();
  }

  rerenderTM(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
      });
    });
    this.dtTrigger.next();
    this.dtTriggerStatus.next();
    this.dtTrigger2.next();
    this.dtTrigger2Status.next();
  }

  rerenderSPV(): void {
    this.dtTriggerSPV.next();
    this.dtTriggerSPVStatus.next();
    this.dtTrigger2SPV.next();
    this.dtTrigger2SPVStatus.next();
    this.dtTrigger3SPV.next();
  }

  rerenderTL(): void {
    this.dtTriggerTL.next();
    this.dtTriggerTLStatus.next();
    this.dtTrigger2TL.next();
    this.dtTrigger2TLStatus.next();
    this.dtTrigger3TL.next();  
  }
}
