<main class="prospect">
    <div class="div-text-left mt-30">
        <p class="text-large text-darkgrey no-margin">
            Show result for Agent - {{userInfo.user.name}} ({{userInfo.roleId}} - {{userInfo.branch.name}})
        </p>
        <div class="row dropdown-right">
            <div class="">
              <div class="p-relative">
                <button class="btn btn-clear mr-15 pointer" (click)="searchOpen()" type="button">Search</button>
              </div>
            </div>
            <!-- <div class="">
                <div ngbDropdown class="d-inline-block">
                    <div class="p-relative dropdown">
                        <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>{{shProductNameSelected}}</button>
                        <em class="fas fa-caret-down down-dropdown"></em>
                    </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let shProduct of shProducts; let i = index;" (click)="shProductOnChange(shProduct.key, shProduct.value)">{{shProduct.value}}</button>
                  </div>
                </div>
            </div> -->
            <div class="row">
              <div ngbDropdown class="d-inline-block dropdown-calendar">
                <form  class="form-inline col-lg-3 ">
                  <div class="form-group custom-calendar">
                    <div class="input-group form-calendar">
                      <input class="form-control" [(ngModel)]="filterDate" name="dp" placeholder="Assigned Date" ngbDatepicker #d="ngbDatepicker" (dateSelect)="filterProspect()" autocomplete="off" (click)="d.toggle()">
                      <i class="calendar-icon" (click)="d.toggle()"></i>
                    </div>
                  </div>
                </form>
                </div>
                <div class="p-relative">
                  <button class="btn btn-clear ml-10 pointer" (click)="clearFilter()" type="button">Clear Filter</button>
                </div>
            </div>
        </div>
        
    </div>

    <div class="title-prospect row mt-40">
      <div class="form-check" *ngIf="userInfo.roleId == 'SH'">
          <label class="form-check-label">
             <input type="checkbox" style="margin-top: 2px;" (click)="checkAll()" [checked]="checkedIn"> 
             <span class="text-darkgrey text-large ml-20">Select all</span>
          </label>
          <button class="btn btn-purple ml-30" type="button" (click)="open()">Assign</button><!-- (click)="open(content)" -->
      </div>
      <div class="btn-right">
        <div *ngIf="userInfo.isSiapDana == 'Y'" class="btn-custom p-relative" [routerLink]="['/homepage/prospect-new-so-sd']">
          <img src="../../../assets/images/icons/btn-plus.svg" alt="btn-plus" class="logo-btn img-30"/>
          <button class="btn btn-purple ml-30" type="button">Create non Database</button>
        </div>
      </div>
  </div>
  <section class="row section-dropdown mt-40 div-text-left pr-0">
    <div class="w-100 ml-0 row space-bottom div-text-left" *ngFor="let prospect of dataProspects; let i = index;">
      <input type="checkbox" class="mr-20" *ngIf="userInfo.roleId == 'SH'" (change)="change(prospect.id, $event)" [checked]="checkedIn"> 
      <div class="row white-container width-card p-20 ml-20 div-text-center pointer" (click)="routerLinkCustom(prospect.product, prospect.id)" [routerLink]="['/homepage/prospect-detail', prospect.id]">
          <div class="col-lg-4 no-padding card-left">
            <p class="text-darkgrey text-normal no-margin" *ngIf="prospect.branch != null">
              {{prospect.custNo}} - {{prospect.branch.name}}
            </p>
            <p class="text-darkgrey text-normal no-margin" *ngIf="prospect.branch == null">
              {{prospect.custNo}} 
            </p>
              <p class="text-largest no-margin">
                {{prospect.custName}} - {{prospect.custMobileNo1}}
              </p>
              <p class="no-margin text-normal">
                Source - {{typeName(prospect.type)}} - {{productName(prospect.product)}}
              </p>
          </div>
          <div class="col-lg-4 no-padding row text-darkgrey card-center div-text-center">
              <span class="text-normal">Agent :  {{userInfo.user.name}}</span>              
          </div>
          <div class="col-lg-4 no-padding text-right card-right">
              <p class="text-darkgrey text-normal">
                {{prospect.currentTaskBucketActivity.dtmCrt | date : 'MMM d, y'}}
              </p>
              <p class="text-red text-large" *ngIf="compareDate(prospect.currentTaskBucketActivity.dtmSla)">
                <em class="fas fa-circle"></em>
                Over SLA
              </p>     
              <p class="text-green text-large" *ngIf="!compareDate(prospect.currentTaskBucketActivity.dtmSla)">
                <em class="fas fa-circle"></em>
                Under SLA
              </p>
              <p class="text-darkgrey text-normal" *ngIf="prospect.custAgreementStatus != null">
                {{prospect.custAgreementStatus}}
              </p> 
              <p class="text-darkgrey text-normal" *ngIf="prospect.custAgreementStatus == null">
                -
              </p>  
          </div>
      </div>
    </div> 
    <!-- <div class="w-100 ml-0 row space-bottom div-text-left" [routerLink]="['/homepage/prospect-detail']">
      <input type="checkbox" class="mr-20"> 
      <div class="row white-container width-card p-20 ml-20 div-text-center">
          <div class="col-lg-4 no-padding card-left">
            <p class="text-darkgrey text-normal no-margin">
                123156544542125555
              </p>
              <p class="text-largest no-margin">
                Angela Tanoesudibjo
              </p>
              <p class="no-margin text-normal">
                Source - Database TM
              </p>
          </div>
          <div class="col-lg-4 no-padding row text-darkgrey card-center div-text-center">
              <span class="text-normal">Agent: Filbert </span>
          </div>
          <div class="col-lg-4 no-padding text-right card-right">
            <p class="text-darkgrey text-normal">
                Jan 12, 2021
              </p>
              <p class="text-red text-large">
                <em class="fas fa-circle"></em>
                Over SLA
              </p>
          </div>
      </div>
    </div> -->
    <!-- <div class="w-100 ml-0 row space-bottom div-text-left" [routerLink]="['/homepage/prospect-detail']">
      <input type="checkbox" class="mr-20"> 
      <div class="row white-container width-card p-20 ml-20 div-text-center">
          <div class="col-lg-4 no-padding card-left">
              <p class="text-darkgrey no-margin">
                123156544542125555
              </p>
              <p class="text-large no-margin">
                Angela Tanoesudibjo
              </p>
              <p class="no-margin">
                Source - Database TM
              </p>
          </div>
          <div class="col-lg-4 no-padding row text-darkgrey card-center div-text-center">
              <span>Agent: </span>
              <p>
                Filbert
              </p>
          </div>
          <div class="col-lg-4 no-padding text-right card-right">
              <p class="text-darkgrey">
                Jan 12, 2021
              </p>
              <p class="text-red">
                <em class="fas fa-circle"></em>
                Over SLA
              </p>
          </div>
      </div>
    </div>
    <div class="w-100 ml-0 row space-bottom div-text-left" [routerLink]="['/homepage/prospect-detail']">
      <input type="checkbox" class="mr-20"> 
      <div class="row white-container width-card p-20 ml-20 div-text-center">
          <div class="col-lg-4 no-padding card-left">
              <p class="text-darkgrey no-margin">
                123156544542125555
              </p>
              <p class="text-large no-margin">
                Angela Tanoesudibjo
              </p>
              <p class="no-margin">
                Source - Database TM
              </p>
          </div>
          <div class="col-lg-4 no-padding row text-darkgrey card-center div-text-center">
              <span>Agent: </span>
              <p>
                Filbert
              </p>
          </div>
          <div class="col-lg-4 no-padding text-right card-right">
              <p class="text-darkgrey">
                Jan 12, 2021
              </p>
              <p class="text-green1">
                <em class="fas fa-circle"></em>
                Over SLA
              </p>
          </div>
      </div>
    </div> -->
  </section>
  <div class="text-large text-center w-100 pl-25">
    <p class="text-blue1 pointer" *ngIf="dataProspects?.length > 9 && filterDate == '' && filterDateString == ''" (click)="showMore()">
      Show more
    </p>
    <p class="text-blue1 pointer" *ngIf="dataProspects?.length > 9 && filterDate != '' && filterDateString != ''" (click)="showMoreCustom()">
      Show more
    </p>
    <p class="text-blue1" *ngIf="dataProspects?.length == 0">
      No Data
    </p>
  </div>
</main> 
<ng-template #content let-modal>
  <div class="modal-header">
      <button type="button" class="close text-grey font-roboto" aria-label="Close" (click)="modal.dismiss('Cross click')" style="font-size: 30px !important;">
      <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center">
      <div>
        <p class="text-largest text-blue">Pemindahan Data Prospect</p>
      </div>
      <div>
        <p class="text-large mt-30">
          Anda telah memilih {{inputs.length}} prospek untuk dipindahkan menuju agent lain
        </p>
      </div>
      <div ngbDropdown class="mt-20 d-inline-block">
        <div ngbDropdown class="d-inline-block">
          <div class="p-relative dropdown">
              <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{childUserNameSelected}}</button>
              <em class="fas fa-caret-down down-dropdown"></em>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem *ngFor="let childUser of childUsers; let i = index;" (click)="assignToOnChange(childUser.userId, childUser.user.name, childUser.tlUser.name)">{{childUser.user.name}}</button>
          </div>
      </div>
      </div>

  </div>
  <div class="modal-footer ">
      <div class="row w-100 div-text-center">
          <div class="col-lg-4 col-sm-6">
              <button type="button" [disabled]="inputs.length == 0" class="btn btn-purple w-100" (click)="submitAssign()" style="padding: 8px !important;">Submit</button>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #contentSearch let-modal>
  <div class="modal-header">
      <button type="button" class="close text-grey font-roboto" aria-label="Close" (click)="modal.dismiss('Cross click')" style="font-size: 30px !important;">
      <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center">
    <div>
      <p class="text-largest text-blue">Search</p>
    </div>
    <div class="mt-20">

      <div class="row no-margin w-100 mt-20">
        <div class="col-lg-6 col-sm-6">
          <div class="radio-button">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="searchRadio" value="search" name="searchRadio" [(ngModel)]="searchRadio" (change)="changeRadioSearch()">
              <label class="custom-control-label" for="searchRadio">Search</label>
            </div>  
          </div>
        </div>
        <div class="col-lg-6 col-sm-6">
          <div ngbDropdown class="d-inline-block">
              <div class="p-relative dropdown">
                  <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 185px;height:40px;">{{searchTypeSelected2}}</button>
                  <em class="fas fa-caret-down down-dropdown"></em>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="searchOnChange('custName', 'Customer Name')">Customer Name</button>
                <button ngbDropdownItem (click)="searchOnChange('custNo', 'Customer Number')">Customer Number</button>
                <button ngbDropdownItem (click)="searchOnChange('custMobileNo1', 'No Handphone')">No Handphone</button>
                <button ngbDropdownItem (click)="searchOnChange('custBranch', 'Branch')">Branch</button>
                <button ngbDropdownItem (click)="searchOnChange('custAgreementStatus', 'Contract Status')">Contract Status</button>
              </div>
          </div>
          <div class="mt-20" *ngIf="searchTypeString2 == 'custName'">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchTypeValueCustNameString" id="custName" name="custName" placeholder="Customer Name" />
            </div>
          </div>
          <div class="mt-20" *ngIf="searchTypeString2 == 'custNo'">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchTypeValueCustNoString" id="contractNum" name="contractNum" placeholder="Customer Number" />
            </div>
          </div>
          <div class="mt-20" *ngIf="searchTypeString2 == 'custMobileNo1'">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchTypeValueCustMobileNo1String" id="custMobileNo1" name="custMobileNo1" placeholder="No Handphone" />
            </div>
          </div>
          <div class="mt-20" *ngIf="searchTypeString2 == 'custBranch'">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchTypeValueCustBranchString" id="custBranch" name="custBranch" placeholder="Branch" />
            </div>
          </div>
          <div class="mt-20" *ngIf="searchTypeString2 == 'custAgreementStatus'">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchTypeValueCustAgreementStatusString" id="custAgreementStatus" name="custAgreementStatus" placeholder="Contract Status" />
            </div>
          </div>
        </div>    
      </div>

      <!-- <div class="row no-margin w-100">
        <div class="col-lg-6 col-sm-6">
          <div class="radio-button">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="custNameRadio" value="custName" name="searchRadio" checked [(ngModel)]="searchRadio" (change)="changeRadioSearch()">
              <label class="custom-control-label" for="custNameRadio">Customer Name</label>
            </div>  
          </div>
        </div>
        <div class="col-lg-6 col-sm-6">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchCustNameString" id="custName" name="custName" placeholder="Customer Name" />
            </div>
        </div>    
      </div>

      <div class="row no-margin w-100 mt-20">
        <div class="col-lg-6 col-sm-6">
          <div class="radio-button">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="contractNumRadio" value="custNo" name="searchRadio" [(ngModel)]="searchRadio" (change)="changeRadioSearch()">
              <label class="custom-control-label" for="contractNumRadio">Customer Number</label>
            </div>  
          </div>
        </div>
        <div class="col-lg-6 col-sm-6">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchCustNoString" id="contractNum" name="contractNum" placeholder="Contract Number" />
            </div>
        </div>    
      </div> -->

      <div class="row no-margin w-100 mt-20">
        <div class="col-lg-6 col-sm-6">
          <div class="radio-button">
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="sourceRadio" value="source" name="searchRadio" [(ngModel)]="searchRadio" (change)="changeRadioSearch()">
              <label class="custom-control-label" for="sourceRadio">Source</label>
            </div>  
          </div>
        </div>
        <div class="col-lg-6 col-sm-6">
          <div ngbDropdown class="d-inline-block">
              <div class="p-relative dropdown">
                  <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 185px;height:40px;">{{searchTypeSelected}}</button>
                  <em class="fas fa-caret-down down-dropdown"></em>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="sourceOnChange('DB', 'Database')">Database</button>
                <button ngbDropdownItem (click)="sourceOnChange('EN', 'Entry')">Entry</button>
                <button ngbDropdownItem (click)="sourceOnChange('UP', 'Upload')">Upload</button>
              </div>
          </div>
          <div class="mt-20" *ngIf="searchTypeString == 'UP'">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="searchTypeValueString" id="searchTypeValueString" name="searchTypeValueString" placeholder="" />
            </div>
          </div>
        </div>    
      </div>
      
    </div>
  </div>
  <div class="modal-footer mt-20">
      <div class="row w-100 div-text-center">
          <div class="col-lg-4 col-sm-6">
              <button type="button" class="btn btn-purple w-100" (click)="searchSubmit()" style="padding: 8px !important;">Search</button>
          </div>
      </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>

