<main class="container-fluid dashboard">   
    <div class="tab-slider--nav p-relative">
        <ul class="nav nav-tabs custom-tab">
          <li class="nav-item" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Daily Activity</li>
          <li class="nav-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Monthly Activity</li>
          <li class="nav-item" [class.active]="viewMode == 'tab3'" rel="tab3" (click)="viewMode ='tab3'" *ngIf="(dashboardRole == 'SPV' && userInfo.isAsuransi == 'N') || (dashboardRole == 'TL' && userInfo.isAsuransi == 'N')">Performance by Branch</li>
        </ul>
        <div class="dropdown-dashboard div-text-center">
          <!-- Hen Dropdown ini berlaku untuk TL & Agent aja, kalau spv di hide aja -->
            <!-- <div ngbDropdown class="d-inline-block mr-15">
              <div class="p-relative dropdown dropodown-product">
                  <button class="btn-dropdown dropdown-toogle" id="dropdownProduct" ngbDropdownToggle>Product</button>
                  <em class="fas fa-caret-down down-dropdown"></em>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownProduct">
                <button ngbDropdownItem>Product 1</button>
                <button ngbDropdownItem>Product 2</button>
              </div>
            </div> -->
          <!-- End -->
            <div ngbDropdown class="d-inline-block dropdown-calendar">
              <form  class="form-inline col-lg-3 ">
                <div class="form-group custom-calendar">
                  <div class="input-group form-calendar">
                    <input class="form-control " [(ngModel)]="filterDate" name="dp" placeholder="Assigned Date" ngbDatepicker #d="ngbDatepicker" (dateSelect)="filterDashboard()" autocomplete="off" (click)="d.toggle()">
                    <i class="calendar-icon" (click)="d.toggle()"></i>
                  </div>
                </div>
              </form>
                <!-- <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>19 Sept 2021</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem>12 Sept 2021</button>
                <button ngbDropdownItem>08 Agst 2021</button>
              </div> -->
            </div>
            <div class="p-relative">
              <button class="btn btn-clear ml-10 pointer" (click)="clearFilter()" type="button">Clear Filter</button>
            </div>
            
        </div>
    </div>

    <div class="tab-slider--container">
        <div id="tab1" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab2' || viewMode == 'tab3'">
          <h3 class="mb-20">Dashboard Daily</h3>
          <table *ngIf="(dashboardRole == 'TM')" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover rounded" id="myTable">
              <thead class="head-custom">
                  <tr class="rounderleft">
                    <th class=" border-topright-radius br-white" rowspan="3">AGENT</th>
                    <th class="br-white bb-white bg-darkblue" colspan="2" rowspan="2">DATABASE</th>
                    <th class="br-white" rowspan="3">TOUCH</th>
                    <th class="br-white" rowspan="3">CONNECTED</th>
                    <th class="br-white" rowspan="3">CONTACTED</th>
                    <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                    <th class="bg-darkblue br-white bb-white" colspan="5">CALL RESULT</th>
                    <th class="border-topright-radius bg-darkblue bb-white" colspan="2">APPLICATION STEP</th>
                  </tr>
                  <tr class="rounderright">
                    <th class="br-white" colspan="3">INTERESTED</th>
                    <th class="br-white" rowspan="2" >NFU</th>
                    <th class="br-white" rowspan="2">NOT INTERESTED</th>
                    <th class="br-white" rowspan="2">BACKLOG</th>
                    <th class="" rowspan="2">VALID</th>
                  </tr>
                  <tr>
                    <th class="bg-darkerblue br-white">NEW</th>
                    <th class="bg-navy">NFU</th>
                    <th class="bg-darkerblue br-white">HOT</th>
                    <th class="bg-navy">WARM</th>
                    <th class="bg-darkerblue br-white">COLD</th>
                  </tr>
                </thead>
          </table>

          <table *ngIf="(dashboardRole == 'TL')" datatable [dtOptions]="dtOptionsTL" [dtTrigger]="dtTriggerTL" class="row-border hover rounded" id="myTable">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="3">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2" rowspan="2">DATABASE</th>
                  <th class="br-white" rowspan="3">TOUCH</th>
                  <th class="br-white" rowspan="3">CONNECTED</th>
                  <th class="br-white" rowspan="3">CONTACTED</th>
                  <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                  <th class="bg-darkblue br-white bb-white" colspan="5">CALL RESULT</th>
                  <th class="border-topright-radius bg-darkblue bb-white" colspan="2">APPLICATION STEP</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white" colspan="3">INTERESTED</th>
                  <th class="br-white" rowspan="2" >NFU</th>
                  <th class="br-white" rowspan="2">NOT INTERESTED</th>
                  <th class="br-white" rowspan="2">BACKLOG</th>
                  <th class="" rowspan="2">VALID</th>
                </tr>
                <tr>
                  <th class="bg-darkerblue br-white">NEW</th>
                  <th class="bg-navy">NFU</th>
                  <th class="bg-darkerblue br-white">HOT</th>
                  <th class="bg-navy">WARM</th>
                  <th class="bg-darkerblue br-white">COLD</th>
                </tr>
              </thead>
          </table>

          <table *ngIf="(dashboardRole == 'SPV')" datatable [dtOptions]="dtOptionsSPV" [dtTrigger]="dtTriggerSPV" class="row-border hover rounded" id="myTable">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="3">TEAM LEADER</th>
                  <th class=" border-topright-radius br-white" rowspan="3">AGENT</th>
                  <th class=" border-topright-radius br-white" rowspan="3">SOURCE</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2" rowspan="2">DATABASE</th>
                  <th class="br-white" rowspan="3">TOUCH</th>
                  <th class="br-white" rowspan="3">CONNECTED</th>
                  <th class="br-white" rowspan="3">CONTACTED</th>
                  <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                  <th class="bg-darkblue br-white bb-white" colspan="5">CALL RESULT</th>
                  <th class="border-topright-radius bg-darkblue bb-white" colspan="2">APPLICATION STEP</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white" colspan="3">INTERESTED</th>
                  <th class="br-white" rowspan="2" >NFU</th>
                  <th class="br-white" rowspan="2">NOT INTERESTED</th>
                  <th class="br-white" rowspan="2">BACKLOG</th>
                  <th class="" rowspan="2">VALID</th>
                </tr>
                <tr>
                  <th class="bg-darkerblue br-white">NEW</th>
                  <th class="bg-navy">NFU</th>
                  <th class="bg-darkerblue br-white">HOT</th>
                  <th class="bg-navy">WARM</th>
                  <th class="bg-darkerblue br-white">COLD</th>
                </tr>
              </thead>
          </table>

          <!-- Ini buat Pemisah antar table -->
          <hr>
          <!-- end -->

          <h3 class="mt-35 mb-20">Dashboard Daily Status</h3>
          <table *ngIf="(dashboardRole == 'TM')" datatable [dtOptions]="dtOptionsStatus" [dtTrigger]="dtTriggerStatus" class="row-border hover rounded" id="myTableStatus">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="2">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="8" rowspan="1">PROSPECT STATUS</th>
                  <th class="br-white" rowspan="2">TOTAL</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white">DATA ENTRY</th>
                  <th class="br-white">CREDIT REVIEW</th>
                  <th class="br-white">APPROVE</th>
                  <th class="br-white">CONTRACT SIGNING</th>
                  <th class="br-white">CUSTOMER CONFIRMATION</th>
                  <th class="br-white">VALID</th>
                  <th class="br-white">CANCEL</th>
                  <th class="br-white">REJECT</th>
                </tr>
              </thead>
          </table>

          <table *ngIf="(dashboardRole == 'TL')" datatable [dtOptions]="dtOptionsTLStatus" [dtTrigger]="dtTriggerTLStatus" class="row-border hover rounded" id="myTableStatus">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="2">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="8" rowspan="1">PROSPECT STATUS</th>
                  <th class="br-white" rowspan="2">TOTAL</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white">DATA ENTRY</th>
                  <th class="br-white">CREDIT REVIEW</th>
                  <th class="br-white">APPROVE</th>
                  <th class="br-white">CONTRACT SIGNING</th>
                  <th class="br-white">CUSTOMER CONFIRMATION</th>
                  <th class="br-white">VALID</th>
                  <th class="br-white">CANCEL</th>
                  <th class="br-white">REJECT</th>
                </tr>
              </thead>
          </table>

          <table *ngIf="(dashboardRole == 'SPV')" datatable [dtOptions]="dtOptionsSPVStatus" [dtTrigger]="dtTriggerSPVStatus" class="row-border hover rounded" id="myTableStatus">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="2">TEAM LEADER</th>
                  <th class=" border-topright-radius br-white" rowspan="2">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="8" rowspan="1">PROSPECT STATUS</th>
                  <th class="br-white" rowspan="2">TOTAL</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white">DATA ENTRY</th>
                  <th class="br-white">CREDIT REVIEW</th>
                  <th class="br-white">APPROVE</th>
                  <th class="br-white">CONTRACT SIGNING</th>
                  <th class="br-white">CUSTOMER CONFIRMATION</th>
                  <th class="br-white">VALID</th>
                  <th class="br-white">CANCEL</th>
                  <th class="br-white">REJECT</th>
                </tr>
              </thead>
          </table>

        </div>
        
        <div id="tab2" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab1' || viewMode == 'tab3'">
          <h3 class="mb-20">Dashboard Monthly</h3>
          <table *ngIf="(dashboardRole == 'TM')" datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2" class="row-border hover rounded" id="myTable">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="3">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2" rowspan="2">DATABASE</th>
                  <th class="br-white" rowspan="3">TOUCH</th>
                  <th class="br-white" rowspan="3">CONNECTED</th>
                  <th class="br-white" rowspan="3">CONTACTED</th>
                  <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                  <th class="bg-darkblue br-white bb-white" colspan="5">CALL RESULT</th>
                  <th class="border-topright-radius bg-darkblue bb-white" colspan="2">APPLICATION STEP</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white" colspan="3">INTERESTED</th>
                  <th class="br-white" rowspan="2" >NFU</th>
                  <th class="br-white" rowspan="2">NOT INTERESTED</th>
                  <th class="br-white" rowspan="2">BACKLOG</th>
                  <th class="" rowspan="2">VALID</th>
                </tr>
                <tr>
                  <th class="bg-darkerblue br-white">NEW</th>
                  <th class="bg-navy">NFU</th>
                  <th class="bg-darkerblue br-white">HOT</th>
                  <th class="bg-navy">WARM</th>
                  <th class="bg-darkerblue br-white">COLD</th>
                </tr>
              </thead>
          </table>

          <table *ngIf="(dashboardRole == 'TL')" datatable [dtOptions]="dtOptions2TL" [dtTrigger]="dtTrigger2TL" class="row-border hover rounded" id="myTable">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="3">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2" rowspan="2">DATABASE</th>
                  <th class="br-white" rowspan="3">TOUCH</th>
                  <th class="br-white" rowspan="3">CONNECTED</th>
                  <th class="br-white" rowspan="3">CONTACTED</th>
                  <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                  <th class="bg-darkblue br-white bb-white" colspan="5">CALL RESULT</th>
                  <th class="border-topright-radius bg-darkblue bb-white" colspan="2">APPLICATION STEP</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white" colspan="3">INTERESTED</th>
                  <th class="br-white" rowspan="2" >NFU</th>
                  <th class="br-white" rowspan="2">NOT INTERESTED</th>
                  <th class="br-white" rowspan="2">BACKLOG</th>
                  <th class="" rowspan="2">VALID</th>
                </tr>
                <tr>
                  <th class="bg-darkerblue br-white">NEW</th>
                  <th class="bg-navy">NFU</th>
                  <th class="bg-darkerblue br-white">HOT</th>
                  <th class="bg-navy">WARM</th>
                  <th class="bg-darkerblue br-white">COLD</th>
                </tr>
              </thead>
          </table>

          <table *ngIf="(dashboardRole == 'SPV')" datatable [dtOptions]="dtOptions2SPV" [dtTrigger]="dtTrigger2SPV" class="row-border hover rounded" id="myTable">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="3">TEAM LEADER</th>
                  <th class=" border-topright-radius br-white" rowspan="3">AGENT</th>
                  <th class=" border-topright-radius br-white" rowspan="3">SOURCE</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2" rowspan="2">DATABASE</th>
                  <th class="br-white" rowspan="3">TOUCH</th>
                  <th class="br-white" rowspan="3">CONNECTED</th>
                  <th class="br-white" rowspan="3">CONTACTED</th>
                  <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                  <th class="bg-darkblue br-white bb-white" colspan="5">CALL RESULT</th>
                  <th class="border-topright-radius bg-darkblue bb-white" colspan="2">APPLICATION STEP</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white" colspan="3">INTERESTED</th>
                  <th class="br-white" rowspan="2" >NFU</th>
                  <th class="br-white" rowspan="2">NOT INTERESTED</th>
                  <th class="br-white" rowspan="2">BACKLOG</th>
                  <th class="" rowspan="2">VALID</th>
                </tr>
                <tr>
                  <th class="bg-darkerblue br-white">NEW</th>
                  <th class="bg-navy">NFU</th>
                  <th class="bg-darkerblue br-white">HOT</th>
                  <th class="bg-navy">WARM</th>
                  <th class="bg-darkerblue br-white">COLD</th>
                </tr>
            </thead>
          </table>

          <h3 class="mt-35">Dashboard Monthly Status</h3>
          <table *ngIf="(dashboardRole == 'TM')" datatable [dtOptions]="dtOptions2Status" [dtTrigger]="dtTrigger2Status" class="row-border hover rounded" id="myTableStatus">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="2">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="8" rowspan="1">PROSPECT STATUS</th>
                  <th class="br-white" rowspan="2">TOTAL</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white">DATA ENTRY</th>
                  <th class="br-white">CREDIT REVIEW</th>
                  <th class="br-white">APPROVE</th>
                  <th class="br-white">CONTRACT SIGNING</th>
                  <th class="br-white">CUSTOMER CONFIRMATION</th>
                  <th class="br-white">VALID</th>
                  <th class="br-white">CANCEL</th>
                  <th class="br-white">REJECT</th>
                </tr>
              </thead>
          </table>

          <table *ngIf="(dashboardRole == 'TL')" datatable [dtOptions]="dtOptions2TLStatus" [dtTrigger]="dtTrigger2TLStatus" class="row-border hover rounded" id="myTableStatus">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="2">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="8" rowspan="1">PROSPECT STATUS</th>
                  <th class="br-white" rowspan="2">TOTAL</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white">DATA ENTRY</th>
                  <th class="br-white">CREDIT REVIEW</th>
                  <th class="br-white">APPROVE</th>
                  <th class="br-white">CONTRACT SIGNING</th>
                  <th class="br-white">CUSTOMER CONFIRMATION</th>
                  <th class="br-white">VALID</th>
                  <th class="br-white">CANCEL</th>
                  <th class="br-white">REJECT</th>
                </tr>
              </thead>
          </table>

          <table *ngIf="(dashboardRole == 'SPV')" datatable [dtOptions]="dtOptions2SPVStatus" [dtTrigger]="dtTrigger2SPVStatus" class="row-border hover rounded" id="myTableStatus">
            <thead class="head-custom">
                <tr class="rounderleft">
                  <th class=" border-topright-radius br-white" rowspan="2">TEAM LEADER</th>
                  <th class=" border-topright-radius br-white" rowspan="2">AGENT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="8" rowspan="1">PROSPECT STATUS</th>
                  <th class="br-white" rowspan="2">TOTAL</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white">DATA ENTRY</th>
                  <th class="br-white">CREDIT REVIEW</th>
                  <th class="br-white">APPROVE</th>
                  <th class="br-white">CONTRACT SIGNING</th>
                  <th class="br-white">CUSTOMER CONFIRMATION</th>
                  <th class="br-white">VALID</th>
                  <th class="br-white">CANCEL</th>
                  <th class="br-white">REJECT</th>
                </tr>
              </thead>
          </table>

        </div>

        <!-- di TL&agent  tab 3 di hide saja ya hen -->
        <div id="tab3" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab1' || viewMode == 'tab2'">
            <table *ngIf="(dashboardRole == 'TL')" datatable [dtOptions]="dtOptions3TL" [dtTrigger]="dtTrigger3TL" class="row-border hover rounded" id="myTable">
              <thead class="head-custom"> 
                <tr class="rounderleft">
                  <th class="border-topright-radius  br-white" rowspan="3">BRANCH</th>
                  <th class="br-white" rowspan="3">DATABASE</th>
                  <th class="br-white" rowspan="3">CONNECTED</th>
                  <th class="br-white" rowspan="3">CONTACTED</th>
                  <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2">CALL RESULT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="3">RESULT BY SRO</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2">APPLICATION STEP</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white" colspan="2">INTERESTED</th>
                  <th class="br-white" rowspan="2">INTERESTED</th>
                  <th class="br-white" rowspan="2">IN PROGRESS</th>
                  <th class="br-white" rowspan="2">NOT INTERESTED</th>
                  <th class="br-white" rowspan="2">BACKLOG</th>
                  <th class="br-white" rowspan="2">VALID</th>
                </tr>
                <tr>
                  <th class="br-white bg-darkerblue">HOT</th>
                  <th class="bg-navy">WARM</th>
                </tr>
              </thead>
            </table>

            <table *ngIf="dashboardRole == 'SPV'" datatable [dtOptions]="dtOptions3SPV" [dtTrigger]="dtTrigger3SPV" class="row-border hover rounded" id="myTable">
              <thead class="head-custom"> 
                <tr class="rounderleft">
                  <th class="border-topright-radius  br-white" rowspan="3">BRANCH</th>
                  <th class="border-topright-radius  br-white" rowspan="3">SOURCE</th>
                  <th class="br-white" rowspan="3">DATABASE</th>
                  <th class="br-white" rowspan="3">CONNECTED</th>
                  <th class="br-white" rowspan="3">CONTACTED</th>
                  <th class="br-white" rowspan="3">PRODUCT &amp; CREDIT SIMULATION INFORMED</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2">CALL RESULT</th>
                  <th class="br-white bb-white bg-darkblue" colspan="3">RESULT BY SRO</th>
                  <th class="br-white bb-white bg-darkblue" colspan="2">APPLICATION STEP</th>
                </tr>
                <tr class="rounderright">
                  <th class="br-white" colspan="2">INTERESTED</th>
                  <th class="br-white" rowspan="2">INTERESTED</th>
                  <th class="br-white" rowspan="2">IN PROGRESS</th>
                  <th class="br-white" rowspan="2">NOT INTERESTED</th>
                  <th class="br-white" rowspan="2">BACKLOG</th>
                  <th class="br-white" rowspan="2">VALID</th>
                </tr>
                <tr>
                  <th class="br-white bg-darkerblue">HOT</th>
                  <th class="bg-navy">WARM</th>
                </tr>
              </thead>
            </table>

        </div>
    </div>
</main>
