import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Api } from 'src/app/utility/Api';
import * as CryptoJS from 'crypto-js';
declare var $: any;

@Component({
  selector: 'app-login-expired',
  templateUrl: './login-expired.component.html',
  styleUrls: ['./login-expired.component.css']
})
export class LoginExpiredComponent implements OnInit {

  screatKey = "claris";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  inputPassword: any;
  inputPasswordConfirm: any;
  errorFlag: any = false;
  errorPattern: any = false;
  submitBtn: any = true;
  errorMsg: any;
  username: any;
  password: any;
  userInf: any;

  ngOnInit(): void {
    this.username = this.route.snapshot.paramMap.get("username");
    this.password = this.route.snapshot.paramMap.get("password");
    console.log("PASSWORD22", this.password)

    $("#sh-newPassword").on('click', function() {
      if ($("#newPassword").prop("type") == "password") {
          $("#newPassword").prop("type", "text");
          
          $(this).find('i').removeClass('fa-eye-slash');
          $(this).find('i').addClass('fa-eye');
      } else {
          $("#newPassword").prop("type", "password");
          
          $(this).find('i').removeClass('fa-eye');
          $(this).find('i').addClass('fa-eye-slash');
      }
    });

    $("#sh-newPasswordConfirm").on('click', function() {
      if ($("#newPasswordConfirm").prop("type") == "password") {
          $("#newPasswordConfirm").prop("type", "text");
          
          $(this).find('i').removeClass('fa-eye-slash');
          $(this).find('i').addClass('fa-eye');
      } else {
          $("#newPasswordConfirm").prop("type", "password");
          
          $(this).find('i').removeClass('fa-eye');
          $(this).find('i').addClass('fa-eye-slash');
      }
    });
  }

  alphanumericOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // console.log(charCode);
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode > 96 && charCode < 123)) {
      return true;
    }
    return false;
  }

  checkInputValue(value: string){
    // console.log("asd",value);
    // suppose value is"https://www.google.com";
    var patt = new RegExp(/(?=.*[A-Z])(?=.*[0-9]).{8,}/);
    var res = patt.test(value);
    if(res){
      this.errorPattern = false;
    }
    else{
      this.errorPattern = true;
    }
  }
  
  checkExec(){
    if(this.inputPassword != this.inputPasswordConfirm){
      this.errorFlag = true;
      this.submitBtn = true;
      this.errorMsg = "Confirm Password must Match New Password";
    }
    else{
      this.errorFlag = false;      
      this.submitBtn = false;
    }
  }

  resetPassSubmit(){
    this.spinner.show();

    let tmpRequest = {
		  username: this.username,
      password: this.password,
      newPassword: this.encrypt(this.inputPassword, this.screatKey),
    }
    console.log("PASSWORD", this.password);

    this.apiUtil.invokeAPI("RESET_PASSWORD", tmpRequest).subscribe(res => {
      if(res.status == '200'){
        this.toastr.success(res.error);

        //Hit Login Again to gain Token
        let tmpRequest2 = {
          username: this.username,
          password: this.encrypt(this.inputPassword, this.screatKey)
        }
    
        this.apiUtil.invokeAPI("LOGIN", tmpRequest2).subscribe(res => {
          if(res.access_token != "" && res.access_token != null){
            localStorage.setItem("userData", JSON.stringify(res));
    
            this.userInf= JSON.parse(window.atob(res.access_token.split('.')[1]));
            let tmpRequest3 = {
              userId: this.userInf.user_name
            };
    
            this.apiUtil.invokeAPI("GET_ROLE_USER", tmpRequest3).subscribe(
              res => {
                if(res.state.status == '200'){
                  localStorage.setItem("userInfo", JSON.stringify(res.data[0]));
                  this.toastr.success("Login Success");
                  this.spinner.hide();
                  this.router.navigate(['/homepage']);
                }
                else{
                  console.log(res);
                  this.toastr.error(res);
                  this.spinner.hide();
                }
              }, 
              (err) => {
                console.log(err);
                this.toastr.error(err);
                this.spinner.hide();
              }
            );
          }
          else if(res.error_description == 'User credentials have expired'){
            this.spinner.hide();
            this.router.navigate(['/login-expired', tmpRequest]);
          }
          else{
            console.log(res.error_description);
            this.toastr.error(res.error_description);
            this.spinner.hide();
          }
        }, (err) => {
          console.log(err);
          this.toastr.error(err);
          this.spinner.hide();
        });
        //End Hit Login Again
      }
      else if(res.status == '400'){
        this.toastr.error(res.message);
        this.spinner.hide();
      }
	  }, (err) => {
      console.log(err);
      this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  encrypt(value: string, key:string) {
    let enc = CryptoJS.AES.encrypt(value, key);
    let encc = enc.toString();
    console.log("ENCCC", encc);
    return encc;
  }

}
