import { Component, OnInit } from '@angular/core';
import { Router,RouterLink } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';
@Component({
  selector: 'app-customer-handling',
  templateUrl: './customer-handling.component.html',
  styleUrls: ['./customer-handling.component.css']
})
export class CustomerHandlingComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  viewMode = 'tab1';

  productList: any;

  dtOptions: any = {};
  dtOptionsSPV: any = {};
  dtOptionsTL: any = {};
  // dtOptionsSHSD: any = {};
  // dtOptionsSHNC: any = {};
  // dtOptionsSO: any = {};
  // dtOptionsSRO: any = {};
  // dtOptionsCDE: any = {};
  productSelected: any;
  productKeySelected: any = "";
  product: any = "";
  insuranceProductKeySelected: any;
  insuranceProductNameSelected: any;

  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  handlingRole: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }
  ngOnInit(): void {
    this.handlingRole = this.userInfo.roleId;
    // this.insuranceProductOnChange(this.insuranceProductKeySelected, 'Insurance Renewal');
    // this.insuranceProductOnChange('IR', 'Insurance Renewal');

    if((this.userInfo.roleId == 'SH') || (this.userInfo.roleId == 'SRO') || (this.userInfo.roleId == 'SO') || (this.userInfo.roleId == 'CDE')) {
      this.router.navigate(['/homepage/customer-handling-sh']);
    }

    if(this.userInfo.roleId == 'CSO' || this.userInfo.roleId == 'TCSO' || this.userInfo.roleId == 'JURO' || this.userInfo.roleId == 'DERO' || this.userInfo.roleId == 'AE'){
      this.router.navigate(['/homepage/customer-handling-cso']);
    }

    if(this.userInfo.roleId == 'SEH'){
      this.router.navigate(['/homepage/customer-handling-seh']);
    }

    if(this.userInfo.roleId == 'BM' || this.userInfo.roleId == 'BH'){
      this.router.navigate(['/homepage/customer-handling-bh']);
    }

    if(this.handlingRole == 'SPV') {
      this.dtOptionsSPV = {
        processing: true,
	      serverSide: true,
        ajax: {
          url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+this.handlingRole+"_LIST"].ENDPOINT + "/" + this.userInfo.userId,
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function (d) {
            return JSON.stringify(d);
          }
        },
        columns: [
          {
            data: "custName",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: "dtmCrt", 
            render: function(data){
              return moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "branchName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "userName",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "dtmLastFu",
            render: function(data){
              return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
            }
          }, 
          {
            data: "custAgreementStatus",
            render: function(data){
              return data == null ? '-' : data;
            }
          }, 
          {
            data: "status",
            render: function(data){
              return data == null ? '-' : data;
            }
          },
          {
            data: null,
            render: function(data, type, row){
              return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
            }
          }
        ],
      }
    } else if ((this.handlingRole == 'TM') && !(this.userInfo.isAsuransi == 'Y') ){
    this.dtOptions = {
      // filter: true,
      // sSearching: true,
      // processing: true,
	    // serverSide: true,
      processing: true,
      serverSide: true,
      search: true,
      ajax: {
        url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+this.handlingRole+"_LIST"].ENDPOINT + "/" + this.userInfo.userId,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function (d) {
          return JSON.stringify(d);
        }
      },
      columns: [
        {
          data: "custName",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: "dtmCrt", 
          render: function(data){
            return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "branchName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "userName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "dtmLastFu",
          render: function(data){
            return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "custAgreementStatus",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "status",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: null,
          render: function(data, type, row){
            return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
          }
        }
      ],
    }
  } else if ((this.handlingRole == 'TM') && (this.userInfo.isAsuransi == 'Y')) {
    this.insuranceProductOnChange('IR', 'Insurance Renewal');
  } 
  else if ((this.handlingRole == 'TL')) {
    this.dtOptionsTL = {
      processing: true,
	    serverSide: true,
      ajax: {
        url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+ this.handlingRole +"_LIST"].ENDPOINT + "/" + this.userInfo.userId,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function (d) {
          return JSON.stringify(d);
        }
      },
      columns: [
        {
          data: "custName",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: "dtmCrt", 
          render: function(data){
            return moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "branchName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "userName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "dtmLastFu",
          render: function(data){
            return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "custAgreementStatus",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "status",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: null,
          render: function(data, type, row){
            return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
          }
        }
      ],
    }
  }
}
getListProducts(){
  let tmpRequest = {
    currentUserId: this.userInfo.userId
  }

  this.apiUtil.invokeAPI("SH_PRODUCT", tmpRequest).subscribe(res => {
    if(res.state.status == '200'){
      this.productList = res.data;
      // console.log(this.productList);
      this.productKeySelected = this.productList[0].key;
      this.productSelected = this.productList[0].value;

      this.spinner.hide();
    }

  },(err) => {
    console.log(err);
    // this.toastr.error(err);
    this.spinner.hide();
  });
}
productOnChange(key, value) {
  this.productSelected = value;
  this.productKeySelected = key;
}
insuranceProductOnChange(key, value){
  this.insuranceProductKeySelected = key;
  this.insuranceProductNameSelected = value;
  
  this.dtOptions = {
    processing: true,
    serverSide: true,
    ajax: {
      url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+this.handlingRole+"_LIST"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.insuranceProductKeySelected,
      type: "POST",
      contentType: "application/json",
      dataType: "json",
      headers: { 'Authorization': "Bearer " + this.userData.access_token },
      data: function (d) {
        return JSON.stringify(d);
      }
    },
    columns: [
      {
        data: "custName",
        render: function(data){
          return data == null ? '-' : data;
        }
      },
      {
        data: "dtmCrt", 
        render: function(data){
          return moment(data).format("DD-MMM-YYYY");
        }
      }, 
      {
        data: "branchName",
        render: function(data){
          return data == null ? '-' : data;
        }
      }, 
      {
        data: "dtmLastFu",
        render: function(data){
          return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
        }
      },
      {
        data: "custAgreementStatus",
        render: function(data){
          return data == null ? '-' : data;
        }
      }, 
      {
        data: "status",
        render: function(data){
          return data == null ? '-' : data;
        }
      },
      {
        data: null,
        render: function(data, type, row){
          return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
        }
      }
    ],
  }
}
}