import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
import * as moment from 'moment';

@Component({
  selector: 'app-prospect-sh-reassign',
  templateUrl: './prospect-sh-reassign.component.html',
  styleUrls: ['./prospect-sh-reassign.component.css']
})
export class ProspectShReassignComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  model: NgbDateStruct;
  today = this.calendar.getToday();
  closeResult = '';
  dataProspects: any;
  startPage = 0;
  userInfo = JSON.parse(localStorage.getItem("userInfo"));
  dateToday: any;
  filterDate: any = "";
  filterDateString: any = "";
  checkBoxValue: any;
  inputs = [];
  checkedIn: any = false;

  childUsers: any;
  childUserNameSelected: any;
  childUserIdSelected: any;

  shProducts: any;
  shProductNameSelected: any;
  shProductIdSelected: any;

  searchRadio: any = "";
  searchCustNameString: any;
  searchCustNoString: any;
  searchTypeString2: any;
  searchTypeString: any;
  searchTypeValueString2: any;
  searchTypeValueString: any;
  searchTypeValueCustNameString: any;
  searchTypeValueCustNoString: any;
  searchTypeValueCustMobileNo1String: any;
  searchTypeValueCustBranchString: any;
  searchTypeValueCustAgreementStatusString: any;
  searchTypeSelected: any;
  searchTypeSelected2: any;

  @ViewChild('content') contentModal: ElementRef;
  @ViewChild('contentSearch') contentModalSearch: ElementRef;

  ngOnInit(): void {
    this.searchRadio = 'search';
    
    this.getShProduct();
  }

  showMore(){
    this.checkedIn = false;
    this.inputs = [];

    this.spinner.show();
    this.startPage = this.startPage + 1;
    
    let tmpRequest = {
		  page: this.startPage.toString(),
      size: '10',
      currentUserId: this.userInfo.userId,
      product: this.shProductIdSelected
    }

    this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          res.data.content.forEach(element => {
            this.dataProspects.push(element);
          });
        }

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  showMoreCustom(){
    this.checkedIn = false;
    this.inputs = [];

    this.spinner.show();
    this.startPage = this.startPage + 1;
    
    let tmpRequest = {
		  page: this.startPage.toString(),
      size: '10',
      currentUserId: this.userInfo.userId,
      product: this.shProductIdSelected,
      dtmCrt: this.filterDateString
    }

    this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          res.data.content.forEach(element => {
            this.dataProspects.push(element);
          });
        }

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  routerLinkCustom(product, id){
    if(product == 'NC'){
      this.router.navigate(['/homepage/prospect-detail-sh-so', id]);
    }
    else if(product == 'SD'){
      this.router.navigate(['/homepage/prospect-detail-sh-sro', id]);
    }
  }

  compareDate(dateSla){
    let dateSlaRaw = new Date(dateSla);
    let dateSlaa = moment(dateSlaRaw).format('YYYY-MM-DD HH:mm:ss');

    let dateToday = moment().format('YYYY-MM-DD HH:mm:ss');

    if(dateSlaa < dateToday){
      return true;
    }
    else{
      return false;
    }
  }

  filterProspect(){
    this.spinner.show();
    
    let yearString = this.filterDate.year;
    let monthString = this.filterDate.month.toString().length == 1 ? "0" + this.filterDate.month : this.filterDate.month;
    let dayString =  this.filterDate.day.toString().length == 1 ? "0" + this.filterDate.day : this.filterDate.day;
    this.filterDateString = yearString + "-" + monthString + "-" + dayString;

    this.startPage = 0;

    let tmpRequest = {
		  page: this.startPage.toString(),
      size: '10',
      currentUserId: this.userInfo.userId,
      product: this.shProductIdSelected,
      dtmCrt: this.filterDateString
    }

    this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        // console.log("res", res);
        if(res.data.length != 0){
          this.dataProspects = res.data.content;
        }
        else{
          this.dataProspects = [];
        }
        
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  clearFilter(){
    this.searchTypeString2 = "";
    this.searchTypeSelected2 = "";
    this.searchTypeValueCustNameString = "";
    this.searchTypeValueCustNoString = "";
    this.searchTypeValueCustMobileNo1String = "";
    this.searchTypeValueCustBranchString = "";
    this.searchTypeValueCustAgreementStatusString = "";
    this.filterDate = "";
    this.filterDateString = "";
    this.startPage = 0;
    
    this.spinner.show();

    let tmpRequest = {
		  page: this.startPage.toString(),
      size: '10',
      currentUserId: this.userInfo.userId,
      product: this.shProductIdSelected
    }

    this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          this.dataProspects = res.data.content;
        }
        else{
          this.dataProspects = [];
        }
        
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  open() {
    this.modalService.open(this.contentModal, {ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    // console.log(this.inputs);

    this.getChildUser();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  change(obj: any, check: any){
    let evtCheck = false
    if(check?.path?.length) {
      //For old Google chrome
      evtCheck = check?.path[0]?.checked
    } else {
      //For newest
      evtCheck = check?.target.checked
    }
    if(evtCheck) {
      this.inputs.push(obj);
    }
    else{
      this.inputs = this.arrayRemove(this.inputs, obj);
    } 
  }

  arrayRemove(arr, value) {
    return arr.filter(function(ele){
        return ele != value;
    });
  }

  assignToOnChange(userId, userName){
    this.childUserIdSelected = userId;
    this.childUserNameSelected = userName;
  }

  getChildUser(){
    let tmpRequest = {
      currentUserId: this.userInfo.userId,
      product: this.shProductIdSelected
    }

    this.apiUtil.invokeAPI("CHILD_SH_USER", tmpRequest).subscribe(res => {
      // console.log("qwd", res);
      if(res.state.status == '200'){
        this.childUsers = res.data;

        if(this.childUsers.length > 0){
          this.childUserIdSelected = this.childUsers[0].userId;
          this.childUserNameSelected = this.childUsers[0].user.name;
        }

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getShProduct(){
    let tmpRequest = {
      currentUserId: this.userInfo.userId
    }

    this.apiUtil.invokeAPI("SH_PRODUCT", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.shProducts = res.data;

        if(this.shProducts.length > 0){
          this.shProductIdSelected = this.shProducts[0].key;
          this.shProductNameSelected = this.shProducts[0].value;

          //Call Default Product prospect SH
          this.spinner.show();
  
          this.dateToday = moment().format('YYYY-MM-DD');
          this.filterDate = "";
          this.filterDateString = "";
      
          let tmpRequest = {
            page: this.startPage.toString(),
            size: '10',
            currentUserId: this.userInfo.userId,
            product: this.shProductIdSelected
          }
      
          this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
            if(res.state.status == '200'){
              if(res.data.length != 0){
                this.dataProspects = res.data.content;
                // console.log("Prospect SH List", this.dataProspects);
              }
              else{
                this.dataProspects = [];
              }
      
              this.spinner.hide();
            }
      
          },(err) => {
            console.log(err);
            // this.toastr.error(err);
            this.spinner.hide();
          });
          //End

        }

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  shProductOnChange(key, value){
    this.shProductIdSelected = key;
    this.shProductNameSelected = value;

    //Call Default Product prospect SH
    this.spinner.show();

    this.dateToday = moment().format('YYYY-MM-DD');
    this.filterDate = "";
    this.filterDateString = "";

    let tmpRequest = {
      page: this.startPage.toString(),
      size: '10',
      currentUserId: this.userInfo.userId,
      product: this.shProductIdSelected
    }

    this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          this.dataProspects = res.data.content;
          // console.log("Prospect SH List", this.dataProspects);
        }
        else{
          this.dataProspects = [];
        }

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
    //End
  }

  submitAssign(){
    this.spinner.show();

    let tmpRequest = {
      idList: this.inputs,
      currentUserId: this.childUserIdSelected
      // currentUserId: this.userInfo.userId
    }

    this.apiUtil.invokeAPI("ASSIGN_REASSIGN_SH_PROSPECT", tmpRequest).subscribe(res => {
      if(res.state.status == "200"){
        this.toastr.success(res.state.error);
        this.spinner.hide();
        // this.router.navigate(['/homepage/prospect']);
        location.reload();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }
  
  checkAll(){
    if(this.checkedIn == false){
      this.checkedIn = true;
      this.inputs = [];
      this.dataProspects.forEach(element => {
        this.inputs.push(element.id);
      });
    }
    else{
      this.checkedIn = false;
      this.inputs = [];
    }
  }

  searchOpen(){
    this.modalService.open(this.contentModalSearch, {ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    // console.log(this.inputs);
  }

  sourceOnChange(key, value){
    this.searchTypeString = key;
    this.searchTypeSelected = value;
  }

  searchOnChange(key, value){
    this.searchTypeString2 = key;
    this.searchTypeSelected2 = value;
  }

  // searchCustName(){
  //   this.spinner.show();

  //   this.startPage = 0;

  //   let tmpRequest = {
	// 	  page: this.startPage.toString(),
  //     size: '10',
  //     currentUserId: this.userInfo.userId,
  //     custName: this.searchCustNameString,
  //     product: this.shProductIdSelected
  //   }

  //   this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
  //     if(res.state.status == '200'){
  //       if(res.data.length != 0){
  //         this.dataProspects = res.data.content;
  //         // console.log("prospect sh List", this.dataProspects);
  //       }
  //       else{
  //         this.dataProspects = [];
  //       }

  //       this.spinner.hide();
  //       this.modalService.dismissAll();
  //     }

  //   },(err) => {
  //     console.log(err);
  //     // this.toastr.error(err);
  //     this.spinner.hide();
	//   });
  // }

  // showMoreCustName(){
  //   this.checkedIn = false;
  //   this.inputs = [];

  //   this.spinner.show();
  //   this.startPage = this.startPage + 1;
    
  //   let tmpRequest = {
	// 	  page: this.startPage.toString(),
  //     size: '10',
  //     currentUserId: this.userInfo.userId,
  //     custName: this.searchCustNameString,
  //     product: this.shProductIdSelected
  //   }

  //   this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
  //     if(res.state.status == '200'){
  //       if(res.data.length != 0){
  //         res.data.content.forEach(element => {
  //           this.dataProspects.push(element);
  //         });
  //       }

  //       this.spinner.hide();
  //     }

  //   },(err) => {
  //     console.log(err);
  //     // this.toastr.error(err);
  //     this.spinner.hide();
	//   });
  // }

  // searchCustNo(){
  //   this.spinner.show();

  //   this.startPage = 0;

  //   let tmpRequest = {
	// 	  page: this.startPage.toString(),
  //     size: '10',
  //     currentUserId: this.userInfo.userId,
  //     custNo: this.searchCustNoString,
  //     product: this.shProductIdSelected
  //   }

  //   this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
  //     if(res.state.status == '200'){
  //       if(res.data.length != 0){
  //         this.dataProspects = res.data.content;
  //         // console.log("prospect sh List", this.dataProspects);
  //       }
  //       else{
  //         this.dataProspects = [];
  //       }

  //       this.spinner.hide();
  //       this.modalService.dismissAll();
  //     }

  //   },(err) => {
  //     console.log(err);
  //     // this.toastr.error(err);
  //     this.spinner.hide();
	//   });
  // }

  // showMoreCustNo(){
  //   this.checkedIn = false;
  //   this.inputs = [];

  //   this.spinner.show();
  //   this.startPage = this.startPage + 1;
    
  //   let tmpRequest = {
	// 	  page: this.startPage.toString(),
  //     size: '10',
  //     currentUserId: this.userInfo.userId,
  //     custNo: this.searchCustNoString,
  //     product: this.shProductIdSelected
  //   }

  //   this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
  //     if(res.state.status == '200'){
  //       if(res.data.length != 0){
  //         res.data.content.forEach(element => {
  //           this.dataProspects.push(element);
  //         });
  //       }

  //       this.spinner.hide();
  //     }

  //   },(err) => {
  //     console.log(err);
  //     // this.toastr.error(err);
  //     this.spinner.hide();
	//   });
  // }

  searchSource(){
    this.spinner.show();

    this.startPage = 0;

    if(this.searchTypeValueString != null && this.searchTypeValueString != undefined){
      this.searchTypeValueString = this.searchTypeValueString;
    }
    else{
      this.searchTypeValueString = "";
    }

    let tmpRequest = {
		  page: this.startPage.toString(),
      size: '10',
      currentUserId: this.userInfo.userId,
      type: this.searchTypeString,
      typeValue: this.searchTypeValueString,
      product: this.shProductIdSelected
    }

    this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          this.dataProspects = res.data.content;
          // console.log("prospect sh List", this.dataProspects);
        }
        else{
          this.dataProspects = [];
        }

        this.spinner.hide();
        this.modalService.dismissAll();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  showMoreSource(){
    this.checkedIn = false;
    this.inputs = [];

    this.spinner.show();
    this.startPage = this.startPage + 1;

    if(this.searchTypeValueString != null && this.searchTypeValueString != undefined){
      this.searchTypeValueString = this.searchTypeValueString;
    }
    else{
      this.searchTypeValueString = "";
    }
    
    let tmpRequest = {
		  page: this.startPage.toString(),
      size: '10',
      currentUserId: this.userInfo.userId,
      type: this.searchTypeString,
      typeValue: this.searchTypeValueString,
      product: this.shProductIdSelected
    }

    this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          res.data.content.forEach(element => {
            this.dataProspects.push(element);
          });
        }

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  searchSearch(){
    if(this.searchTypeString2 == "custName") {
      console.log("DATA", this.searchTypeValueCustNameString)
      this.spinner.show();

      this.startPage = 0;

      if(this.searchTypeValueCustNameString != null && this.searchTypeValueCustNameString != undefined){
        this.searchTypeValueCustNameString = this.searchTypeValueCustNameString;
      }
      else{
        this.searchTypeValueCustNameString = "";
      }
      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custName: this.searchTypeValueCustNameString,
        product: this.shProductIdSelected
      }
  
      console.log("DATA DATA", tmpRequest);
      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            this.dataProspects = res.data.content;
            // console.log("prospect List", this.dataProspects);
          }
          else{
            this.dataProspects = [];
          }
  
          this.spinner.hide();
          this.modalService.dismissAll();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custNo") {
      console.log("DATA", this.searchTypeValueCustNoString)
      this.spinner.show();

      this.startPage = 0;

      if(this.searchTypeValueCustNoString != null && this.searchTypeValueCustNoString != undefined){
        this.searchTypeValueCustNoString = this.searchTypeValueCustNoString;
      }
      else{
        this.searchTypeValueCustNoString = "";
      }
      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custNo: this.searchTypeValueCustNoString,
        product: this.shProductIdSelected
      }
  
      console.log("DATA DATA", tmpRequest);
      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            this.dataProspects = res.data.content;
            // console.log("prospect List", this.dataProspects);
          }
          else{
            this.dataProspects = [];
          }
  
          this.spinner.hide();
          this.modalService.dismissAll();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custMobileNo1") {
      console.log("DATA", this.searchTypeValueCustMobileNo1String)
      this.spinner.show();

      this.startPage = 0;

      if(this.searchTypeValueCustMobileNo1String != null && this.searchTypeValueCustMobileNo1String != undefined){
        this.searchTypeValueCustMobileNo1String = this.searchTypeValueCustMobileNo1String;
      }
      else{
        this.searchTypeValueCustMobileNo1String = "";
      }
      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custMobileNo1: this.searchTypeValueCustMobileNo1String,
        product: this.shProductIdSelected
      }
  
      console.log("DATA DATA", tmpRequest);
      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            this.dataProspects = res.data.content;
            // console.log("prospect List", this.dataProspects);
          }
          else{
            this.dataProspects = [];
          }
  
          this.spinner.hide();
          this.modalService.dismissAll();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custBranch") {
      console.log("DATA", this.searchTypeValueCustBranchString)
      this.spinner.show();

      this.startPage = 0;

      if(this.searchTypeValueCustBranchString != null && this.searchTypeValueCustBranchString != undefined){
        this.searchTypeValueCustBranchString = this.searchTypeValueCustBranchString;
      }
      else{
        this.searchTypeValueCustBranchString = "";
      }
      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custBranch: this.searchTypeValueCustBranchString,
        product: this.shProductIdSelected
      }
  
      console.log("DATA DATA", tmpRequest);
      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            this.dataProspects = res.data.content;
            // console.log("prospect List", this.dataProspects);
          }
          else{
            this.dataProspects = [];
          }
  
          this.spinner.hide();
          this.modalService.dismissAll();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custAgreementStatus") {
      console.log("DATA", this.searchTypeValueCustAgreementStatusString)
      this.spinner.show();

      this.startPage = 0;

      if(this.searchTypeValueCustAgreementStatusString != null && this.searchTypeValueCustAgreementStatusString != undefined){
        this.searchTypeValueCustAgreementStatusString = this.searchTypeValueCustAgreementStatusString;
      }
      else{
        this.searchTypeValueCustAgreementStatusString = "";
      }
      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custAgreementStatus: this.searchTypeValueCustAgreementStatusString,
        product: this.shProductIdSelected
      }
  
      console.log("DATA DATA", tmpRequest);
      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            this.dataProspects = res.data.content;
            // console.log("prospect List", this.dataProspects);
          }
          else{
            this.dataProspects = [];
          }
  
          this.spinner.hide();
          this.modalService.dismissAll();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
  }

  showMoreSearchSearch(){
    this.checkedIn = false;
    this.inputs = [];

    this.spinner.show();
    this.startPage = this.startPage + 1;

    if(this.searchTypeString2 == "custName") {
      if(this.searchTypeValueCustNameString != null && this.searchTypeValueCustNameString != undefined){
        this.searchTypeValueCustNameString = this.searchTypeValueCustNameString;
      }
      else{
        this.searchTypeValueCustNameString = "";
      }

      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custName: this.searchTypeValueCustNameString,
        product: this.shProductIdSelected
      }

      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            res.data.content.forEach(element => {
              this.dataProspects.push(element);
            });
          }
  
          this.spinner.hide();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custNo") {
      if(this.searchTypeValueCustNoString != null && this.searchTypeValueCustNoString != undefined){
        this.searchTypeValueCustNoString = this.searchTypeValueCustNoString;
      }
      else{
        this.searchTypeValueCustNoString = "";
      }

      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custNo: this.searchTypeValueCustNoString,
        product: this.shProductIdSelected
      }

      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            res.data.content.forEach(element => {
              this.dataProspects.push(element);
            });
          }
  
          this.spinner.hide();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custMobileNo1") {
      if(this.searchTypeValueCustMobileNo1String != null && this.searchTypeValueCustMobileNo1String != undefined){
        this.searchTypeValueCustMobileNo1String = this.searchTypeValueCustMobileNo1String;
      }
      else{
        this.searchTypeValueCustMobileNo1String = "";
      }

      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custMobileNo1: this.searchTypeValueCustMobileNo1String,
        product: this.shProductIdSelected
      }

      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            res.data.content.forEach(element => {
              this.dataProspects.push(element);
            });
          }
  
          this.spinner.hide();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custBranch") {
      if(this.searchTypeValueCustBranchString != null && this.searchTypeValueCustBranchString != undefined){
        this.searchTypeValueCustBranchString = this.searchTypeValueCustBranchString;
      }
      else{
        this.searchTypeValueCustBranchString = "";
      }

      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custBranch: this.searchTypeValueCustBranchString,
        product: this.shProductIdSelected
      }

      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            res.data.content.forEach(element => {
              this.dataProspects.push(element);
            });
          }
  
          this.spinner.hide();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
    else if(this.searchTypeString2 == "custAgreementStatus") {
      if(this.searchTypeValueCustAgreementStatusString != null && this.searchTypeValueCustAgreementStatusString != undefined){
        this.searchTypeValueCustAgreementStatusString = this.searchTypeValueCustAgreementStatusString;
      }
      else{
        this.searchTypeValueCustAgreementStatusString = "";
      }

      let tmpRequest = {
        page: this.startPage.toString(),
        size: '10',
        currentUserId: this.userInfo.userId,
        type: this.searchTypeString2,
        custAgreementStatus: this.searchTypeValueCustAgreementStatusString,
        product: this.shProductIdSelected
      }

      this.apiUtil.invokeAPI("PROSPECT_SH_REASSIGN_LIST", tmpRequest).subscribe(res => {
        if(res.state.status == '200'){
          if(res.data.length != 0){
            res.data.content.forEach(element => {
              this.dataProspects.push(element);
            });
          }
  
          this.spinner.hide();
        }
  
      },(err) => {
        console.log(err);
        // this.toastr.error(err);
        this.spinner.hide();
      });
    }
  }

  searchSubmit(){
    // if(this.searchRadio == "custName"){
    //   //Call Search Customer Name
    //   this.searchCustName();
    // }
    // else if(this.searchRadio == "custNo"){
    //   //Call Search Customer Number
    //   this.searchCustNo();
    // }
    if(this.searchRadio == "search"){
      //Call Search Search
      this.searchSearch();
    }
    else{
      //Call Search Base On Souce
      this.searchSource();
    }
  }

  changeRadioSearch(){
    // if(this.searchRadio == "custName"){
    //   this.searchCustNoString = null;
    //   this.searchTypeSelected = null;
    //   this.searchTypeString = null;
    //   this.searchTypeValueString = null;
    // }
    // else if(this.searchRadio == "custNo"){
    //   this.searchCustNameString = null;
    //   this.searchTypeSelected = null;
    //   this.searchTypeString = null;
    //   this.searchTypeValueString = null;
    // } 
    if(this.searchRadio == "search"){
      // this.searchCustNoString = null;
      // this.searchCustNameString = null;
      this.searchTypeSelected = null;
      this.searchTypeString = null;
      this.searchTypeValueString = null;
    }
    else{
      this.searchTypeValueCustAgreementStatusString = null;
      this.searchTypeValueCustBranchString = null;
      this.searchTypeValueCustMobileNo1String = null;
      this.searchTypeSelected2 = null;
      this.searchTypeString2 = null;
      this.searchTypeValueCustNoString = null;
      this.searchTypeValueCustNameString = null;
      this.searchCustNoString = null;
      this.searchCustNameString = null;
    }
  }

  productName(prodId){
    switch (prodId) {
      case "SD":
        return "Siap Dana";
      case "NC":
        return "New Car";
      case "KI":
        return "Kinto";
      case "IU":
        return "Insurance Upgrade";
      case "IR": 
        return "Insurance Renewal";
      default:
        return prodId;
    }
  }

  typeName(typeId){
    switch (typeId) {
      case "UP":
        return "Upload";
      case "EN":
        return "Entry";
      case "DB":
        return "Database";
      default:
        return typeId;
    }
  }

}
