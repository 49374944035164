import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  
  screatKey = "claris";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  inputOldPassword: any;
  inputPassword: any;
  inputPasswordConfirm: any;
  errorFlag: any = false;
  submitBtn: any = true;
  errorMsg: any;
  username: any;
  password: any;
  userInf: any;

  ngOnInit(): void {
    $("#sh-oldPassword").on('click', function() {
      if ($("#oldPassword").prop("type") == "password") {
          $("#oldPassword").prop("type", "text");
          
          $(this).find('i').removeClass('fa-eye-slash');
          $(this).find('i').addClass('fa-eye');
      } else {
          $("#oldPassword").prop("type", "password");
          
          $(this).find('i').removeClass('fa-eye');
          $(this).find('i').addClass('fa-eye-slash');
      }
    });

    $("#sh-newPassword").on('click', function() {
      if ($("#newPassword").prop("type") == "password") {
          $("#newPassword").prop("type", "text");
          
          $(this).find('i').removeClass('fa-eye-slash');
          $(this).find('i').addClass('fa-eye');
      } else {
          $("#newPassword").prop("type", "password");
          
          $(this).find('i').removeClass('fa-eye');
          $(this).find('i').addClass('fa-eye-slash');
      }
    });

    $("#sh-newPasswordConfirm").on('click', function() {
      if ($("#newPasswordConfirm").prop("type") == "password") {
          $("#newPasswordConfirm").prop("type", "text");
          
          $(this).find('i').removeClass('fa-eye-slash');
          $(this).find('i').addClass('fa-eye');
      } else {
          $("#newPasswordConfirm").prop("type", "password");
          
          $(this).find('i').removeClass('fa-eye');
          $(this).find('i').addClass('fa-eye-slash');
      }
    });
  }

  checkExecOld(){
    if(this.inputPassword == this.inputOldPassword){
      this.errorFlag = true;
      this.errorMsg = "Old password and new password cannot be same";
    }
    else{
      this.errorFlag = false;      
    }
  }

  checkExec(){
    if(this.inputPassword != this.inputPasswordConfirm){
      this.errorFlag = true;
      this.submitBtn = true;
      this.errorMsg = "Confirm Password must Match New Password";
    }
    else{
      this.errorFlag = false;      
      this.submitBtn = false;
    }
  }

  changePassSubmit(){
    this.spinner.show();

    let tmpRequest = {
		  username: this.encrypt(this.userInfo.userId, this.screatKey),
      password: this.encrypt(this.inputOldPassword,this.screatKey),
      newPassword: this.encrypt(this.inputPassword, this.screatKey)
    }

    this.apiUtil.invokeAPI("CHANGE_PASSWORD", tmpRequest).subscribe(res => {
      if(res.status == '200'){
        this.toastr.success(res.message + ' , Please log in again.');
        this.spinner.hide();
        this.router.navigate(['/']);
        localStorage.clear();
      }
      else if(res.status == '400'){
        this.toastr.error(res.message);
        this.spinner.hide();
      }
	  }, (err) => {
      console.log(err);
      this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  encrypt(value: string, key:string) {
    let enc = CryptoJS.AES.encrypt(value, key);
    let encc = enc.toString();
    console.log("ENCCC", encc);
    return encc;
  }

}
