<div class="container-fluid">
    <div class="row">
        <div class="col-sm-5 login-form-wrapper">
            <div class="brand-wrapper mb-80">
                <img src="../../assets/images/taf_logo.svg" alt="logo" class="logo">
            </div>
            <div class="login-wrapper my-auto">
                <h1 class="login-title text-blue">Change Password</h1>
                <p class="f-15r text-light-grey pb-20"></p>

                <form>
                    <input type="hidden" id="username" name="username" [(ngModel)]="username">
	                <input type="hidden" id="password" name="password" [(ngModel)]="password">
                    <div class="form-group">
                        <div class="input-container">
                            <div class="input-addon">
                                <img src="../../assets/images/icons/input-pass.svg">
                            </div>
                            <input type="password" (keypress)="alphanumericOnly($event)" [(ngModel)]="inputPassword" (keyup)="checkInputValue(inputPassword)" id="newPassword" name="newPassword" class="form-control" placeholder="New Password">
                            <button type="button" class="btn btn-eye" id="sh-newPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <span style="color: red;" *ngIf="errorPattern">Minimum 8 character, must contain at least 1 uppercase alphabetical and 1 number</span>
                    <div class="form-group">
                        <div class="input-container">
                            <div class="input-addon">
                                <img src="../../assets/images/icons/input-pass.svg">
                            </div>
                            <input type="password" [(ngModel)]="inputPasswordConfirm" id="newPasswordConfirm" name="newPasswordConfirm" class="form-control" placeholder="Confirm Password" (keyup)="checkExec()">
                            <button type="button" class="btn btn-eye" id="sh-newPasswordConfirm"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20" *ngIf="errorFlag">
                        <div class="col-xs-12">
                            <span class="text-danger">{{errorMsg}}</span>
                       </div>
                   </div>
                    <button type="button" [disabled]="submitBtn" (click)="resetPassSubmit()" class="btn btn-block login-btn mt-80">Submit</button>
                </form>
            
            </div>
        </div>
        <div class="col-sm-7 px-0 d-none d-sm-block login-img-wrapper">
            <img src="../../assets/images/tomi_toyota.svg" alt="login image" class="login-img h-100vh">
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>