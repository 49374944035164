import { Component, OnInit } from '@angular/core';
import { Router,RouterLink } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';

@Component({
  selector: 'app-customer-handling-cso',
  templateUrl: './customer-handling-cso.component.html',
  styleUrls: ['./customer-handling-cso.component.css']
})
export class CustomerHandlingCsoComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  viewMode = 'tab1';

  productList: any;

  dtOptions: any = {};

  productSelected: any;
  productKeySelected: any = "";
  product: any = "";

  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  handlingRole: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.handlingRole = this.userInfo.roleId;

    let userInfoProduct = "";
    if(this.userInfo.isSiapDana == "Y" && this.userInfo.isNewCar == "N"){
      userInfoProduct = "SD";
    }
    else if(this.userInfo.isSiapDana == "N" && this.userInfo.isNewCar == "Y"){
      userInfoProduct = "NC";
    }
  
    this.dtOptions = {
      processing: true,
	    serverSide: true,
      ajax: {
        url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+ this.handlingRole +"_LIST"].ENDPOINT + "/" + this.userInfo.userId + "/" + userInfoProduct,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function (d) {
          return JSON.stringify(d);
        }
      },
      columns: [
        {
          data: "custName",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: "dtmCrt", 
          render: function(data){
            return moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "branchName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "userName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "dtmLastFu",
          render: function(data){
            return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "custAgreementStatus",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "status",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: null,
          render: function(data, type, row){
            return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
          }
        }
      ],
    }
  
  }

}
