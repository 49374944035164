<main class="container-fluid dashboard">   
    <div class="tab-slider--nav p-relative">
        <ul class="nav nav-tabs custom-tab">
          <li class="nav-item" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Daily Activity</li>
          <li class="nav-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Monthly Activity</li>
          <!-- <li class="nav-item" [class.active]="viewMode == 'tab3'" rel="tab3" (click)="viewMode ='tab3'" *ngIf="(dashboardRole == 'SPV' && userInfo.isAsuransi == 'N') || (dashboardRole == 'TL' && userInfo.isAsuransi == 'N')">Performance by Branch</li> -->
        </ul>
        <div class="dropdown-dashboard div-text-center">
          <!-- Hen Dropdown ini berlaku untuk TL & Agent aja, kalau spv di hide aja -->
            <!-- <div ngbDropdown class="d-inline-block mr-15">
              <div class="p-relative dropdown dropodown-product">
                  <button class="btn-dropdown dropdown-toogle" id="dropdownProduct" ngbDropdownToggle>Product</button>
                  <em class="fas fa-caret-down down-dropdown"></em>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownProduct">
                <button ngbDropdownItem>Product 1</button>
                <button ngbDropdownItem>Product 2</button>
              </div>
            </div> -->
          <!-- End -->
            <div ngbDropdown class="d-inline-block dropdown-calendar">
              <form  class="form-inline col-lg-3 ">
                <div class="form-group custom-calendar">
                  <div class="input-group form-calendar">
                    <input class="form-control " [(ngModel)]="filterDate" name="dp" placeholder="Assigned Date" ngbDatepicker #d="ngbDatepicker" (dateSelect)="filterDashboard()" autocomplete="off" (click)="d.toggle()">
                    <i class="calendar-icon" (click)="d.toggle()"></i>
                  </div>
                </div>
              </form>
                <!-- <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>19 Sept 2021</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem>12 Sept 2021</button>
                <button ngbDropdownItem>08 Agst 2021</button>
              </div> -->
            </div>
            <div class="p-relative">
              <button class="btn btn-clear ml-10 pointer" (click)="clearFilter()" type="button">Clear Filter</button>
            </div> 
        </div>
        
        
    </div>

    <div class="tab-slider--container">
        <div id="tab1" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab2'">
            <table *ngIf="(dashboardCDERole == 'CDE')" datatable [dtOptions]="dtOptionsCDE" [dtTrigger]="dtTriggerCDE" class="row-border hover rounded" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                      <th class="" >AGENT</th>
                      <th class="" >DATABASE</th>
                      <th class="" >CONNECTED</th>
                      <th class="" >CONTACTED</th>
                      <th class="" >INPUTTED</th>
                      <th class="" >ASSIGN BACK TO TM</th>
                      <th class="" >ASSIGN TO SH</th>
                    </tr>
                    <!-- <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th> -->
                  </thead>
                  <!-- <tbody>
                    <tr class="row-rounded">
                      <td class="">Joan Clarke</td>
                      <td class="bg-darkerblue text-white">15</td>
                      <td class="bg-navy text-white">20</td>
                      <td class="">5</td>
                      <td class="">20</td>
                      <td class="">4</td>
                      <td class="">50</td>
                      <td class="">11</td>
                      <td class="">44</td>
                      <td class="">4</td>
                      <td class="">2</td>
                      <td class="">4</td>
                      <td class="">5</td>
                    </tr>
                    <tr class="row-rounded">
                      <td class="">Mr tickle</td>
                      <td class="bg-darkerblue text-white">3</td>
                      <td class="bg-navy text-white">45</td>
                      <td class="">4</td>
                      <td class="">24</td>
                      <td class="">24</td>
                      <td class="">42</td>
                      <td class="">4</td>
                      <td class="">7</td>
                      <td class="">5</td>
                      <td class="">5</td>
                      <td class="">4</td>
                      <td class="">2</td>
                    </tr>
                  </tbody> -->
            </table>
            <table *ngIf="(dashboardCDERole == 'CDETL')" datatable [dtOptions]="dtOptionsCDETL" [dtTrigger]="dtTriggerCDETL" class="row-border hover rounded" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                      <th class="" >AGENT</th>
                      <th class="" >DATABASE</th>
                      <th class="" >CONNECTED</th>
                      <th class="" >CONTACTED</th>
                      <th class="" >INPUTTED</th>
                      <th class="" >ASSIGN BACK TO TM</th>
                      <th class="" >ASSIGN TO SH</th>
                    </tr>
                    <!-- <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th>
                    <th class=" border-topright-radius br-white">AGENT</th> -->
                  </thead>
                  <!-- <tbody>
                    <tr class="row-rounded">
                      <td class="">Joan Clarke</td>
                      <td class="bg-darkerblue text-white">15</td>
                      <td class="bg-navy text-white">20</td>
                      <td class="">5</td>
                      <td class="">20</td>
                      <td class="">4</td>
                      <td class="">50</td>
                      <td class="">11</td>
                      <td class="">44</td>
                      <td class="">4</td>
                      <td class="">2</td>
                      <td class="">4</td>
                      <td class="">5</td>
                    </tr>
                    <tr class="row-rounded">
                      <td class="">Mr tickle</td>
                      <td class="bg-darkerblue text-white">3</td>
                      <td class="bg-navy text-white">45</td>
                      <td class="">4</td>
                      <td class="">24</td>
                      <td class="">24</td>
                      <td class="">42</td>
                      <td class="">4</td>
                      <td class="">7</td>
                      <td class="">5</td>
                      <td class="">5</td>
                      <td class="">4</td>
                      <td class="">2</td>
                    </tr>
                  </tbody> -->
            </table>
        </div>
        <div id="tab2" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab1'">
            <table *ngIf="(dashboardCDERole == 'CDE')" datatable [dtOptions]="dtOptions2CDE" [dtTrigger]="dtTrigger2CDE" class="row-border hover rounded" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                      <th class="">AGENT</th>
                      <th class="">DATABASE</th>
                      <th class="">CONNECTED</th>
                      <th class="">CONTACTED</th>
                      <th class="">INPUTTED</th>
                      <th class="">ASSIGN BACK TO TM</th>
                      <th class="">ASSIGN TO SH</th>
                    </tr>
                </thead>
                <!-- <tbody>
                  <tr class="row-rounded">
                    <td class="">Joan Clarke</td>
                    <td class="bg-darkerblue text-white">15</td>
                    <td class="bg-navy text-white">20</td>
                    <td class="">5</td>
                    <td class="">20</td>
                    <td class="">4</td>
                    <td class="">50</td>
                    <td class="">11</td>
                    <td class="">44</td>
                    <td class="">4</td>
                    <td class="">2</td>
                    <td class="">4</td>
                    <td class="">5</td>
                  </tr>
                  <tr class="row-rounded">
                    <td class="">Mr tickle</td>
                    <td class="bg-darkerblue text-white">3</td>
                    <td class="bg-navy text-white">45</td>
                    <td class="">4</td>
                    <td class="">24</td>
                    <td class="">24</td>
                    <td class="">42</td>
                    <td class="">4</td>
                    <td class="">7</td>
                    <td class="">5</td>
                    <td class="">5</td>
                    <td class="">4</td>
                    <td class="">2</td>
                  </tr>
                </tbody> -->
            </table>
            <table *ngIf="(dashboardCDERole == 'CDETL')" datatable [dtOptions]="dtOptions2CDETL" [dtTrigger]="dtTrigger2CDETL" class="row-border hover rounded" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                      <th class="">AGENT</th>
                      <th class="">DATABASE</th>
                      <th class="">CONNECTED</th>
                      <th class="">CONTACTED</th>
                      <th class="">INPUTTED</th>
                      <th class="">ASSIGN BACK TO TM</th>
                      <th class="">ASSIGN TO SH</th>
                    </tr>
                </thead>
                <!-- <tbody>
                  <tr class="row-rounded">
                    <td class="">Joan Clarke</td>
                    <td class="bg-darkerblue text-white">15</td>
                    <td class="bg-navy text-white">20</td>
                    <td class="">5</td>
                    <td class="">20</td>
                    <td class="">4</td>
                    <td class="">50</td>
                    <td class="">11</td>
                    <td class="">44</td>
                    <td class="">4</td>
                    <td class="">2</td>
                    <td class="">4</td>
                    <td class="">5</td>
                  </tr>
                  <tr class="row-rounded">
                    <td class="">Mr tickle</td>
                    <td class="bg-darkerblue text-white">3</td>
                    <td class="bg-navy text-white">45</td>
                    <td class="">4</td>
                    <td class="">24</td>
                    <td class="">24</td>
                    <td class="">42</td>
                    <td class="">4</td>
                    <td class="">7</td>
                    <td class="">5</td>
                    <td class="">5</td>
                    <td class="">4</td>
                    <td class="">2</td>
                  </tr>
                </tbody> -->
            </table>
        </div>
        <!-- di TL&agent  tab 3 di hide saja ya hen -->
    </div>
</main>
