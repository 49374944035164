<main class="container-fluid customer-handling-sh">
    <div class="tab-slider--nav p-relative">
        <div class="nav nav-tabs custom-tab">
            <div ngbDropdown class="d-inline-block mr-15" >
                <div *ngIf="userInfo.roleId == 'SH'" class="p-relative dropdown dropodown-product">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>{{productSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let product of productList; let i = index" (click)="productOnChange(product.key, product.value)">{{product.value}}</button>
                </div>
            </div>
            
            <div id="tab1" class="table-custom custom-white tab-slider--body mt-40 w-100 pr-10 pl-10">
                <table *ngIf="(handlingRoleSH == 'SH' && productKeySelected == 'SD')" datatable [dtOptions]="dtOptionsSHSD" class="row-border hover rounded" id="myTable">
                    <thead class="head-custom">
                        <tr class="rounderleft rounderright header-blue">
                            <!-- <th class=" ">No</th> -->
                            <th class=" ">Nama Customer</th>
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Cabang</th>
                            <th class=" ">Nama SRO</th>
                            <th class=" ">Last Follow Up Date</th>
                            <th class=" ">Prospect Status</th>
                            <th class=" ">Status</th>
                            <th class=" ">Detail</th>
                        </tr>
                      </thead>
                </table>

                <table *ngIf="(handlingRoleSH == 'SH' && productKeySelected == 'NC')" datatable [dtOptions]="dtOptionsSHNC" class="row-border hover rounded" id="myTable">
                    <thead class="head-custom">
                        <tr class="rounderleft rounderright header-blue">
                            <!-- <?h class=" ">No</th> -->
                            <th class=" ">Nama Customer</th>
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Cabang</th>
                            <th class=" ">Nama SO</th>
                            <th class=" ">Last Follow Up Date</th>
                            <th class=" ">Prospect Status</th>
                            <th class=" ">Status</th>
                            <th class=" ">Detail</th>
                        </tr>
                      </thead>
                </table>

                <table *ngIf="(handlingRoleSH == 'SRO')" datatable [dtOptions]="dtOptionsSRO" class="row-border hover rounded" id="myTable">
                    <thead class="head-custom">
                        <tr class="rounderleft rounderright header-blue">
                            <!-- <th class=" ">No</th> -->
                            <th class=" ">Nama Customer</th>
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Cabang</th>
                            <th class=" ">Nama SRO</th>
                            <th class=" ">Last Follow Up Date</th>
                            <th class=" ">Prospect Status</th>
                            <th class=" ">Status</th>
                            <th class=" ">Detail</th>
                        </tr>
                      </thead>
                </table>

                <table *ngIf="handlingRoleSH == 'SO'" datatable [dtOptions]="dtOptionsSO" class="row-border hover rounded" id="myTable">
                    <thead class="head-custom">
                        <tr class="rounderleft rounderright header-blue">
                            <!-- <th class=" ">No</th> -->
                            <th class=" ">Nama Customer</th>
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Cabang</th>
                            <th class=" ">Nama SO</th>
                            <th class=" ">Last Follow Up Date</th>
                            <th class=" ">Prospect Status</th>
                            <th class=" ">Status</th>
                            <th class=" ">Detail</th>
                        </tr>
                      </thead>
                </table>

                <table *ngIf="(handlingRoleSH == 'CDE')" datatable [dtOptions]="dtOptionsCDE" class="row-border hover rounded" id="myTable">
                    <thead class="head-custom">
                        <tr class="rounderleft rounderright header-blue">
                            <!-- <th class=" ">No</th> -->
                            <th class=" ">Nama Customer</th>
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Cabang</th>
                            <th class=" ">Nama SRO</th>
                            <th class=" ">Last Follow Up Date</th>
                            <th class=" ">Prospect Status</th>
                            <th class=" ">Status</th>
                            <th class=" ">Detail</th>
                        </tr>
                      </thead>
                      <!-- <tbody>
                        <tr class="rounderrow">
                            <<td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class="text-underline" [routerLink]="['/homepage/customer-handling-detail']"><a class="text-blue">Detail</a></td>
                          </tr>
                      </tbody> -->
                </table>
            </div>
        </div>
    </div>
</main>