<main class="prospect-detail">
  <div class="tab-slider--nav p-relative mt-40">
    <ul class="nav nav-tabs">
      <li class="nav-item" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Informasi Pelanggan</li>
      <li class="nav-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Informasi Produk</li>
      <li class="nav-item" [class.active]="viewMode == 'tab3'" rel="tab3" (click)="viewMode ='tab3'" *ngIf="dataProspectDetail?.product != 'NC' && dataProspectDetail?.product != 'KI'">Informasi Kontrak</li>
      <li class="nav-item" [class.active]="viewMode == 'tab4'" rel="tab4" (click)="viewMode ='tab4';populateData();">Result</li>
    </ul>
</div>

<div class="tab-slider--container" [ngSwitch]="viewMode">
    <div id="tab1" class="" *ngSwitchCase="'tab1'">
      <div class="white-container p-20" *ngIf="dataProspectDetail?.product == 'SD'">
        <div class="div-text-right">
          <p class="text-red text-large" *ngIf="compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Over SLA
          </p>     
          <p class="text-green text-large" *ngIf="!compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Under SLA
          </p>  
        </div>

        <div class="row no-margin">
            <div class="col-lg-6 left-side">
                <p class="text-grey text-large">
                  Customer Name
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.custName}}
                </p>
            </div>
            <div class="col-lg-6 right-side">
                <p class="text-grey text-large">
                  Customer Number
                </p>
                <p class="text-large text-darkergrey pointer">
                  {{dataProspectDetail?.prospectDetail.custNo}}
                  <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editCustNum()" *ngIf="userInfo.roleId == 'TM'">
                </p>
                <div class="editCustNum row div-text-center d-none" >
                  <div class="col-lg-6 pl-0">
                    <form>
                      <div class="form-group">
                        <input (keypress)="alphanumericOnly($event)" type="text" class="form-control" [(ngModel)]="inputCustomerNumber" id="inputCustomerNumber" name="inputCustomerNumber" style="height: 40px;" aria-describedby="custNum" placeholder="Customer Number">
                      </div>
                    </form>
                  </div>
                  <div class="row col-lg-6 no-padding space-between">
                    <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                    <button type="submit" class="btn btn-primary col-lg-5" (click)="changeCustNo()">Submit</button>
                  </div>
                </div>
            </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Source Data
              </p>
              <p class="text-large text-darkergrey">
                {{typeName(dataProspectDetail?.type)}} {{(dataProspectDetail?.type == 'UP' || dataProspectDetail?.type == 'EX') ? (dataProspectDetail?.prospectDetail?.dataSource ? ' - ' + dataProspectDetail?.prospectDetail?.dataSource : dataProspectDetail?.prospectDetail?.dataSource) : ''}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Contract Number
              </p>
              <p class="text-large text-darkergrey pointer">
                {{dataProspectDetail?.prospectDetail.custAgreementNo}}
                <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editContractNum()" *ngIf="userInfo.roleId == 'TM'">
              </p>
              <div class="editContractNum row div-text-center d-none" >
                  <div class="col-lg-6 pl-0">
                    <form>
                      <div class="form-group">
                        <input (keypress)="numberOnly($event)" type="text" class="form-control" [(ngModel)]="inputContractNumber" id="inputContractNumber" name="inputContractNumber" style="height: 40px;" aria-describedby="contractNum" placeholder="Contract Number">
                      </div>
                    </form>
                  </div>
                  <div class="row col-lg-6 no-padding space-between">
                    <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                    <button type="submit" class="btn btn-primary col-lg-5" (click)="changeContractNo()">Submit</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Contract Status
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.custAgreementStatus}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Nomor KTP/Identitas
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.custId ? dataProspectDetail.prospectDetail.custId : '-'}}
              </p>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Mobile Phone 1 
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.custMobileNo1}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Mobile Phone 2
              </p>
              <p class="text-large text-darkergrey pointer">
                {{dataProspectDetail?.prospectDetail.custMobileNo2}}
                <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editPhone()" *ngIf="userInfo.roleId == 'TM'">
              </p>
              <div class="editPhone row div-text-center d-none" >
                  <div class="col-lg-6 pl-0 pt-10">
                    <form>
                      <div class="form-group">
                        <input (keypress)="numberOnly($event)" type="text" class="form-control" [(ngModel)]="inputMobileNo2" id="inputMobileNo2" name="inputMobileNo2" style="height: 40px;" aria-describedby="phoneNum" placeholder="Mobile Phone Number">
                        <h8 class="text-muted div-text-left">Format : 0xxxxxxx</h8>
                      </div>
                    </form>
                  </div>
                  <div class="row col-lg-6 no-padding space-between">
                    <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                    <button type="submit" class="btn btn-primary col-lg-5" (click)="checkMobileNo2()">Submit</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="full-row">
          <p class="text-grey text-large">
            Address
          </p>
          <p class="text-large text-darkergrey" *ngIf="dataProspectDetail">
            {{dataProspectDetail?.prospectDetail.custHomeAddress}}
          </p>
        </div>

      </div> <!-- SIAP DANA-->

      <div class="white-container p-20" *ngIf="dataProspectDetail?.product == 'NC' || dataProspectDetail?.product == 'KI'">
        <div class="div-text-right">
          <p class="text-red text-large" *ngIf="compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Over SLA
          </p>     
          <p class="text-green text-large" *ngIf="!compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Under SLA
          </p>  
        </div>
        <div class="row no-margin">
            <div class="col-lg-6 left-side">
                <p class="text-grey text-large">
                  Customer Name
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.custName}}
                </p>
            </div>
            <div class="col-lg-6 right-side">
                <p class="text-grey text-large">
                  Customer Number
                </p>
                <p class="text-large text-darkergrey pointer">
                  {{dataProspectDetail?.prospectDetail.custNo}}
                  <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editCustNum()" *ngIf="userInfo.roleId == 'TM'">
                </p>
                <div class="editCustNum row div-text-center d-none" >
                  <div class="col-lg-6 pl-0">
                    <form>
                      <div class="form-group">
                        <input (keypress)="alphanumericOnly($event)" type="text" class="form-control" [(ngModel)]="inputCustomerNumber" id="inputCustomerNumber" name="inputCustomerNumber" style="height: 40px;" aria-describedby="custNum" placeholder="Customer Number">
                      </div>
                    </form>
                  </div>
                  <div class="row col-lg-6 no-padding space-between">
                    <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                    <button type="submit" class="btn btn-primary col-lg-5" (click)="changeCustNo()">Submit</button>
                  </div>
                </div>
            </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Source Data
              </p>
              <p class="text-large text-darkergrey">
                {{typeName(dataProspectDetail?.type)}} {{(dataProspectDetail?.type == 'UP' || dataProspectDetail?.type == 'EX') ? (dataProspectDetail?.prospectDetail?.dataSource ? ' - ' + dataProspectDetail?.prospectDetail?.dataSource : dataProspectDetail?.prospectDetail?.dataSource) : ''}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Contract Number
              </p>
              <p class="text-large text-darkergrey pointer">
                {{dataProspectDetail?.prospectDetail.custAgreementNo}}
                <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editContractNum()" *ngIf="userInfo.roleId == 'TM'">
              </p>
              <div class="editContractNum row div-text-center d-none" >
                <div class="col-lg-6 pl-0">
                  <form>
                    <div class="form-group">
                      <input (keypress)="numberOnly($event)" type="text" class="form-control" [(ngModel)]="inputContractNumber" id="inputContractNumber" name="inputContractNumber" style="height: 40px;" aria-describedby="contractNum" placeholder="Contract Number">
                    </div>
                  </form>
                </div>
                <div class="row col-lg-6 no-padding space-between">
                  <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                  <button type="submit" class="btn btn-primary col-lg-5" (click)="changeContractNo()">Submit</button>
                </div>
              </div>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Prospect Date
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.dtmProspect ? (dataProspectDetail?.prospectDetail.dtmProspect | date: 'yyyy-MM-dd HH:mm:ss') : '-' }}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Agent Name
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.agentUserId ? dataProspectDetail.prospectDetail.agentUserId : '-'}}
              </p>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Mobile Phone 1 
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.custMobileNo1}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Mobile Phone 2
              </p>
              <p class="text-large text-darkergrey pointer">
                {{dataProspectDetail?.prospectDetail.custMobileNo2}}
                <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editPhone()" *ngIf="userInfo.roleId == 'TM'">
              </p>
              <div class="editPhone row div-text-center d-none" >
                <div class="col-lg-6 pl-0 pt-10">
                  <form>
                    <div class="form-group">
                      <input (keypress)="numberOnly($event)" type="text" class="form-control" [(ngModel)]="inputMobileNo2" id="inputMobileNo2" name="inputMobileNo2" style="height: 40px;" aria-describedby="phoneNum" placeholder="Mobile Phone Number"> 
                      <h8 class="text-muted div-text-left">Format : 0xxxxxxx </h8>
                    </div>
                  </form>
                </div>
                <div class="row col-lg-6 no-padding space-between">
                  <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                  <button type="submit" class="btn btn-primary col-lg-5" (click)="checkMobileNo2()">Submit</button>
                </div>
              </div>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
            <p class="text-grey text-large">
              Address
            </p>
            <p class="text-large text-darkergrey" *ngIf="dataProspectDetail">
              {{dataProspectDetail?.prospectDetail?.custHomeAddress}}
            </p>
          </div>
          <div class="col-lg-6 right-side">
            <p class="text-grey text-large">
              Email
            </p>
            <p class="text-large text-darkergrey" *ngIf="dataProspectDetail">
              {{dataProspectDetail?.prospectDetail?.custEmail}}
            </p>
          </div>
        </div>

      </div>

      <div class="btn-custom p-relative mr-5 mt-40">
        <img src="../../../assets/images/button/Button-right-arrow.svg" alt="btn-next" class="logo-btn right img-30"/>
        <button class="btn btn-purple btn-next div-text-center mb-40" type="button" (click)="viewMode ='tab2'">Next</button>
      </div>
    </div> 

    <div id="tab2" class="" *ngSwitchCase="'tab2'">
      <div class="white-container p-20" *ngIf="dataProspectDetail?.product == 'SD'">
        <div class="div-text-right">
          <p class="text-red text-large" *ngIf="compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Over SLA
          </p>     
          <p class="text-green text-large" *ngIf="!compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Under SLA
          </p>  
        </div>
        <div class="row no-margin">
            <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                TAF Branch
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.branch?.name}}
              </p>
            </div>
            <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Car's Model
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.vehicleModel}}
              </p>
            </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Car's Type
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.vehicleType}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Nomor Polisi
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.vehicleLicensePlate}}
              </p>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Chasis No
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.vehicleIdentificationNo ? dataProspectDetail?.prospectDetail.vehicleIdentificationNo : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Machine No
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.vehicleEngineNo ? dataProspectDetail?.prospectDetail.vehicleEngineNo : '-'}}
              </p>
          </div>
        </div>

        <div class="full-row">
          <p class="text-grey text-large">
            Manufacturing Year
          </p>
          <p class="text-large text-darkergrey">
            {{dataProspectDetail?.prospectDetail.vehicleYear}}
          </p>
        </div>

      </div>


      <div class="white-container p-20" *ngIf="dataProspectDetail?.product == 'NC' || dataProspectDetail?.product == 'KI'">
        <div class="div-text-right">
          <p class="text-red text-large" *ngIf="compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Over SLA
          </p>     
          <p class="text-green text-large" *ngIf="!compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Under SLA
          </p>  
        </div>
        <div class="row no-margin">
            <div class="col-lg-6 left-side">
                <p class="text-grey text-large">
                  TAF Branch
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.branch?.name}}
                </p>
            </div>
            <div class="col-lg-6 right-side">
                <p class="text-grey text-large">
                  Car's Model
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.vehicleModel}}
                </p>
            </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Car's Type
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.vehicleType}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
            <p class="text-grey text-large">
              Nomor Polisi
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.vehicleLicensePlate}}
            </p>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
            <p class="text-grey text-large">
              Dealer Name
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.dealerName ? dataProspectDetail.prospectDetail.dealerName : '-'}}
            </p>
          </div>
          <div class="col-lg-6 right-side">
            <p class="text-grey text-large">
              Manufacturing Year
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.vehicleYear ? dataProspectDetail?.prospectDetail.vehicleYear : '-'}}
            </p>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
            <p class="text-grey text-large">
              Note 1
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.notes1 ? dataProspectDetail?.prospectDetail.notes1 : '-'}}
            </p>
          </div>
          <div class="col-lg-6 right-side">
            <p class="text-grey text-large">
              Note 2
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.notes2 ? dataProspectDetail?.prospectDetail.notes2 : '-'}}
            </p>
          </div>
        </div>

      </div>

      <div class="w-100 div-text-right mb-40 mobile-2button" *ngIf="dataProspectDetail?.product == 'SD'">
        <div class="btn-custom-2 p-relative mr-5 mt-40">
          <img src="../../../assets/images/button/Button-left-arrow.png" alt="btn-next" class="logo-btn left img-30"/>
          <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab1'">Back</button>
        </div>
        <div class="btn-custom-2 p-relative mr-5 mt-40">
          <img src="../../../assets/images/button/Button-right-arrow.png" alt="btn-next" class="logo-btn right img-30"/>
          <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab3'">Next</button>
        </div>
      </div>

      <div class="w-100 div-text-right mb-40 mobile-2button" *ngIf="dataProspectDetail?.product == 'NC' || dataProspectDetail?.product == 'KI'">
          <div class="btn-custom-2 p-relative mr-5 mt-40">
            <img src="../../../assets/images/button/Button-left-arrow.png" alt="btn-next" class="logo-btn left img-30"/>
            <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab1'">Back</button>
          </div>
          <div class="btn-custom-2 p-relative mr-5 mt-40">
            <img src="../../../assets/images/button/Button-right-arrow.png" alt="btn-next" class="logo-btn right img-30"/>
            <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab4';populateData();">Next</button>
          </div>
      </div>
      
    </div>
    <div id="tab3" class="" *ngSwitchCase="'tab3'">
      <div class="white-container p-20">
        <div class="div-text-right">
          <p class="text-red text-large" *ngIf="compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Over SLA
          </p>     
          <p class="text-green text-large" *ngIf="!compareDate(dataProspectDetail?.prospectResult.dtmSla)">
            <em class="fas fa-circle"></em>
            Under SLA
          </p>  
        </div>
        <div class="row no-margin">
            <div class="col-lg-6 left-side">
                <p class="text-grey text-large">
                  Rest Tenor
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.remainingTenor ? dataProspectDetail?.prospectDetail.remainingTenor : '-'}}
                </p>
            </div>
            <div class="col-lg-6 right-side">
                <p class="text-grey text-large">
                  Expiration Period (month)
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.expirationPeriod ? dataProspectDetail?.prospectDetail.expirationPeriod : '-'}}
                </p>
            </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Upload Date
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectResult.dtmCrt ? (dataProspectDetail?.prospectResult.dtmCrt | date: 'yyyy-MM-dd HH:mm:ss') : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Called By
              </p>
              <p class="text-large text-darkergrey">
                {{userInfo?.user?.name ? userInfo?.user?.name : '-'}}
              </p>
          </div>
      </div>

      <div class="row no-margin">
        <div class="col-lg-6 left-side">
            <p class="text-grey text-large">
              Tenor
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.tenor ? dataProspectDetail?.prospectDetail.tenor : '-'}}
            </p>
        </div>
        <div class="col-lg-6 right-side">
            <p class="text-grey text-large">
              Maturity Date
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.dtmMaturity ? (dataProspectDetail?.prospectDetail.dtmMaturity | date: 'yyyy-MM-dd') : '-'}}
            </p>
        </div>
      </div>

      <div class="row no-margin">
        <div class="col-lg-6 left-side">
            <p class="text-grey text-large">
              RRD Date
            </p>
            <p class="text-large text-darkergrey">
              {{dataProspectDetail?.prospectDetail.dtmRrd ? (dataProspectDetail?.prospectDetail.dtmRrd | date: 'yyyy-MM-dd') : '-'}}
            </p>
        </div>
        <div class="col-lg-6 right-side">
          <p class="text-grey text-large">
            Customer Type
          </p>
          <p class="text-large text-darkergrey">
            {{dataProspectDetail?.prospectDetail.custType ? dataProspectDetail?.prospectDetail.custType : '-'}}
          </p>
        </div>
      </div>

      <div class="row no-margin">
        <div class="col-lg-6 left-side">
          <p class="text-grey text-large">
            Max OVD
          </p>
          <p class="text-large text-darkergrey">
            {{dataProspectDetail?.prospectDetail.maxOvdDays ? dataProspectDetail?.prospectDetail.maxOvdDays : '-'}}
          </p>
        </div>
        <div class="col-lg-6 right-side">
          <p class="text-grey text-large">
            OSAR
          </p>
          <p class="text-large text-darkergrey">
            {{dataProspectDetail?.prospectDetail.sisaHutang ? dataProspectDetail?.prospectDetail.sisaHutang : '-'}}
          </p>
        </div>
      </div>

      <div class="full-row">
        <p class="text-grey text-large">
          Note 1
        </p>
        <p class="text-large text-darkergrey">
          {{dataProspectDetail?.prospectDetail.notes1 ? dataProspectDetail?.prospectDetail.notes1 : '-'}}
        </p>
      </div>

      </div>

      <div class="w-100 div-text-right mb-40 mobile-2button">
          <div class="btn-custom-2 p-relative mr-5 mt-40">
            <img src="../../../assets/images/button/Button-left-arrow.png" alt="btn-next" class="logo-btn left img-30"/>
            <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab2'">Back</button>
          </div>
          <div class="btn-custom-2 p-relative mr-5 mt-40">
            <img src="../../../assets/images/button/Button-right-arrow.png" alt="btn-next" class="logo-btn right img-30"/>
            <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab4';populateData();">Next</button>
          </div>
      </div>
      
    </div>
    <div id="tab4" class="" *ngSwitchCase="'tab4'">
      <div class="white-container p-20 result-tab" *ngIf="dataProspectDetail?.product == 'SD'">
        <div class="row no-margin w-100">
          <div class="col-lg-3 col-sm-6 mt-10">
              <!-- <div class="radio-button">
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  <label class="custom-control-label" for="defaultUnchecked">Connectivity</label>
                </div>  
              </div> -->
              <label class="f-14px">Connectivity</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{connectivitySelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of connectivityLov; let i = index;" (click)="connectivityOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                  </div>
              </div>
              <!-- <div class="mt-20" *ngIf="connectivityKeySelected == 'L001-2'">
                <div class="form-group">
                  <textarea class="form-control" id="connectivityReason" name="connectivityReason" [(ngModel)]="connectivityReason" rows="2" placeholder="Reason"></textarea>
                </div>
              </div> -->
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="connectivityKeySelected == 'L001-1'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Contactivity</label>
              </div>  
            </div> -->
            <label class="f-14px">Contactivity</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{contactivitySelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of contactivityLov; let i = index;" (click)="contactivityOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                  </div>
              </div>
              <!-- <div class="mt-20" *ngIf="contactivityKeySelected == 'L006-2'">
                <div class="form-group">
                  <textarea class="form-control" id="contactivityReason" name="contactivityReason" [(ngModel)]="contactivityReason" rows="2" placeholder="Reason"></textarea>
                </div>
              </div> -->
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="connectivityKeySelected == 'L001-1' && contactivityKeySelected == 'L006-1'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Probing Action</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{probingActionSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of probingActionLov; let i = index;" (click)="probingActionOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="connectivityKeySelected == 'L001-1' && contactivityKeySelected == 'L006-1'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Result</label>
              </div>  
            </div> -->
            <label class="f-14px">Result</label>
          </div>
          <div class="col-lg-9 col-sm-6 row">
              <div class="div-text-left w-100 mobile-2row">
                <div ngbDropdown class="d-inline-block col-lg-8">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{resultSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of resultLov; let i = index;" (click)="resultOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
                </div>

                <form *ngIf="resultKeySelected == 'L009-2' || resultKeySelected == 'L010-3'" class="form-calendar form-inline col-lg-4">
                  <div class="form-group custom-calendar">
                    <div class="input-group form-calendar">
                      <input class="form-control " [minDate]="minDatePicker" [(ngModel)]="promiseDate" name="dp" ngbDatepicker #d="ngbDatepicker" placeholder="Appointment Date" (click)="d.toggle()" autocomplete="off">
                      <i class="calendar-icon" (click)="d.toggle()"></i>
                    </div>
                  </div>
                </form>

              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L010-4' || resultKeySelected == 'L009-3'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Result Reason (Not Interested)</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{resultReasonSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of resultReasonLov; let i = index;" (click)="resultReasonOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L009-1' || resultKeySelected == 'L010-1' || resultKeySelected == 'L010-2'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Interested Status</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{interestedStatusSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of interestedStatusLov; let i = index;" (click)="interestedStatusOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L009-1' && userInfo.roleId == 'SH'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Next Followup to</label>
              </div>  
            </div> -->
            <label class="f-14px">App No</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="">
                  <div class="form-group">
                    <textarea class="form-control" id="custAppNo" name="custAppNo" [(ngModel)]="custAppNo" rows="2" placeholder="App No"></textarea>
                  </div>
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L009-1' && userInfo.roleId == 'SH'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Next Followup to</label>
              </div>  
            </div> -->
            <label class="f-14px">App In Date</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-calendar">
                <div class="input-group form-calendar p-relative">
                  <input class="form-control " [(ngModel)]="custAppDate" name="custAppDate" ngbDatepicker #d="ngbDatepicker" placeholder="App In Date" (click)="d.toggle()" autocomplete="off">
                  <i class="calendar-icon" (click)="d.toggle()"></i>
                </div>
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="connectivityKeySelected == 'L001-1' && contactivityKeySelected == 'L006-1' && resultKeySelected != 'L010-4' && resultKeySelected != 'L009-3' && resultKeySelected != 'L009-2' && resultKeySelected != 'L010-3' && userInfo.roleId != 'SH'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Next Followup to</label>
              </div>  
            </div> -->
            <label class="f-14px">Next Followup to</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" [ngClass]="{'input-readOnly': dataProspectDetail?.prospectResult.activity == 'RETURNFROM_CDE'}" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{nextRoleIdSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="nextRoleIdOnChange('CDE')">CDE</button>
                    <button ngbDropdownItem (click)="nextRoleIdOnChange('SH')">SH</button>
                  </div>
              </div>
              <!-- <div class="mt-20">
                <div class="form-group">
                  <textarea class="form-control" id="description" name="description" [(ngModel)]="description" rows="2" placeholder="Description"></textarea>
                </div>
              </div> -->
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20">
          <div class="col-lg-3 col-sm-6 mt-10">
              <label class="text-large">Description</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-input">
                  <textarea class="form-control" id="description" name="description" [(ngModel)]="description" rows="1" placeholder="Description"></textarea>
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20">        
          <div class="row no-margin w-100 pt-20 pb-40">
            <div class="col-lg-3 col-sm-6 mt-10 text-large">
                <label>Attachment</label>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="wrapper-upload div-text-center" style="flex-wrap: wrap;">
                    <div>
                      <img [ngStyle]="{'width': (srcFileName != '') ? '70px' : 'auto'}" [src]="srcImgTemp" alt="drop-file"> 
                    </div>
                    <div class="mt-10 text-center">
                      <span>{{srcFileName}}</span>
                    </div>
                </div>
                <div class="mt-20">
                  <div class=" mt-20 p-relative">
                    <button class="btn btn-purple p-relative mobile-choose-file w-100" (click)="fileUpload.click()">Choose File
                        <img src="../../../assets/images/icons/ic-upload-blue.png" alt="btn-plus" class="ic-upload"/>
                        <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event)" style="display:none" accept="image/*,application/pdf">
                    </button>
                    <button class="btn btn-purple clear-img-btn mobile-clear" (click)="clearImgUpload()" [hidden]="(base64ImgString == '' || base64ImgString == null) && srcImgTemp == '../../../assets/images/drop-file.png'">Clear</button>
                    <div [hidden]="!showButtonShowFileExisting" class="btn-show-file">
                      <img src="../../../assets/images/icons/ic-download-blue.png" alt="btn-plus" class="ic-download"/>
                      <button (click)="openFileExisting()" class="btn btn-purple ml-30">Download File Existing</button>
                    </div>
                    <div [hidden]="!showButtonShowImageExisting" class="btn-show-file">
                      <img src="../../../assets/images/icons/ic-download-blue.png" alt="btn-plus" class="ic-download"/>
                      <button (click)="openImage()" class="btn btn-purple ml-30">Download File Existing</button>
                    </div>
                  </div>
                </div>
            </div>    
          </div>
        </div>
      </div>

      <div class="white-container p-20 result-tab" *ngIf="dataProspectDetail?.product == 'NC' || dataProspectDetail?.product == 'KI'">
        <div class="row no-margin w-100">
          <div class="col-lg-3 col-sm-6 mt-10">
              <!-- <div class="radio-button">
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  <label class="custom-control-label" for="defaultUnchecked">Connectivity</label>
                </div>  
              </div> -->
              <label class="f-14px">Has Been Called ?</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{hasBeenCalledSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of hasBeenCalledLov; let i = index;" (click)="hasBeenCalledOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                  </div>
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1'">
          <div class="col-lg-3 col-sm-6 mt-10">
              <!-- <div class="radio-button">
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  <label class="custom-control-label" for="defaultUnchecked">Connectivity</label>
                </div>  
              </div> -->
              <label class="f-14px">Connectivity</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{connectivitySelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of connectivityLov; let i = index;" (click)="connectivityOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                  </div>
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Contactivity</label>
              </div>  
            </div> -->
            <label class="f-14px">Contactivity</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{contactivitySelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of contactivityLov; let i = index;" (click)="contactivityOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Probing Action</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{probingActionSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of probingActionLov; let i = index;" (click)="probingActionOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Result</label>
              </div>  
            </div> -->
            <label class="f-14px">Result</label>
          </div>
          <div class="col-lg-9 col-sm-6 row">
              <div class="div-text-left w-100 mobile-2row">
                <div ngbDropdown class="d-inline-block col-lg-5 mr-20">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{resultSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of resultLov; let i = index;" (click)="resultOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
                </div>
                <form *ngIf="resultKeySelected == 'L009-2' || resultKeySelected == 'L010-3'" class="form-inline col-lg-4">
                  <div class="form-group custom-calendar">
                    <div class="input-group form-calendar">
                      <input class="form-control " [minDate]="minDatePicker" [(ngModel)]="promiseDate" name="dp" ngbDatepicker #d="ngbDatepicker" placeholder="Appointment Date" (click)="d.toggle()" autocomplete="off">
                      <i class="calendar-icon" (click)="d.toggle()"></i>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L010-4' || resultKeySelected == 'L009-3'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Result Reason (Not Interested)</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{resultReasonSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of resultReasonLov; let i = index;" (click)="resultReasonOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L009-1' || resultKeySelected == 'L010-1' || resultKeySelected == 'L010-2'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Interested Status</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{interestedStatusSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of interestedStatusLov; let i = index;" (click)="interestedStatusOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Paket New Car</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{paketNewCarSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of paketNewCarLov; let i = index;" (click)="paketNewCarOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Next Followup to</label>
              </div>  
            </div> -->
            <label class="f-14px">Waktu Pengambilan Mobil</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-calendar">
                <div class="input-group form-calendar p-relative" style="width: 300px !important;">
                  <input class="form-control " [minDate]="minDatePicker" [(ngModel)]="pickupDate" name="pickupDate" ngbDatepicker #d="ngbDatepicker" placeholder="Waktu Pengambilan Mobil" (click)="d.toggle()" autocomplete="off" style="width: 300px !important;max-width: 300px !important;">
                  <i class="calendar-icon" (click)="d.toggle()"></i>
                </div>
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'"> 
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">TAF's Branch</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" title="{{userInfo?.branch?.name}}" ngbDropdownToggle style="width: 290px;">{{branchCustSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let branchCust of branchProspect; let i = index" (click)="branchesOnChange(branchCust.id, branchCust.name)">{{branchCust.name}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Car Brand</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{brandSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of brandList; let i = index;" (click)="brandOnChange(lovItem, lovItem)">{{lovItem}}</button>
                  </div>
              </div>
          </div>
        </div>


        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Car Model</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{modelSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of modelList; let i = index;" (click)="modelOnChange(lovItem, lovItem)">{{lovItem}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Car Type</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{typeSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of typeList; let i = index;" (click)="typeOnChange(lovItem, lovItem)">{{lovItem}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
            <!-- <div class="radio-button">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                <label class="custom-control-label" for="defaultUnchecked">Probing Action</label>
              </div>  
            </div> -->
            <label class="f-14px">Nama Dealer</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 290px;">{{branchDealerSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let branchDealer of branchDealerList; let i = index" (click)="branchDealerOnChange(branchDealer.id, branchDealer.name)">{{branchDealer.name}}</button>
                  </div>
              </div>
          </div>
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
              <label class="text-large">Car Color</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-input">
                  <input type="text" class="form-control" id="carColor" name="carColor" [(ngModel)]="carColor" placeholder="Car Color">
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
              <label class="text-large">Tenor</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-input">
                  <input (keypress)="numberOnly($event)" class="form-control" id="tenor" name="tenor" [(ngModel)]="tenor" placeholder="Tenor">
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
              <label class="text-large">Angsuran</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-input">
                  <input (keypress)="numberOnly($event)" (keyup)="formatCurrency($event)" class="form-control" id="angsuran" name="angsuran" [(ngModel)]="angsuran" placeholder="Angsuran">
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L010-4'">
          <div class="col-lg-3 col-sm-6 mt-10">
              <label class="text-large">DP (%)</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-input">
                  <input (keypress)="numberOnlyWithComma($event)" class="form-control" id="dp" name="dp" [(ngModel)]="dp" placeholder="DP">
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20">
          <div class="col-lg-3 col-sm-6 mt-10">
              <label class="text-large">Description</label>
          </div>
          <div class="col-lg-9 col-sm-6">
              <div class="form-group custom-input">
                  <textarea class="form-control" id="description" name="description" [(ngModel)]="description" rows="1" placeholder="Description"></textarea>
              </div>
          </div>    
        </div>

        <div class="row no-margin w-100 pt-20">        
          <div class="row no-margin w-100 pt-20 pb-40">
            <div class="col-lg-3 col-sm-6 mt-10 text-large">
                <label>Attachment</label>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="wrapper-upload div-text-center" style="flex-wrap: wrap;">
                  <div>
                    <img [ngStyle]="{'width': (srcFileName != '') ? '70px' : 'auto'}" [src]="srcImgTemp" alt="drop-file"> 
                  </div>
                  <div class="mt-10 text-center">
                    <span>{{srcFileName}}</span>
                  </div>
                </div>
                <div class="mt-20">
                  <div class="mt-20 p-relative">
                    <button class="btn btn-purple p-relative mobile-choose-file w-100" (click)="fileUpload.click()">Choose File
                      <img src="../../../assets/images/icons/ic-upload-blue.png" alt="btn-plus" class="ic-upload"/>
                      <input accept="image/*,application/pdf" #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event)" style="display:none">
                    </button>                    
                    <button class="btn btn-purple clear-img-btn mobile-clear" (click)="clearImgUpload()" [hidden]="(base64ImgString == '' || base64ImgString == null) && srcImgTemp == '../../../assets/images/drop-file.png'">Clear</button>
                  </div>
                  <div [hidden]="!showButtonShowFileExisting" class="btn-show-file">
                    <img src="../../../assets/images/icons/ic-download-blue.png" alt="btn-plus" class="ic-download"/>
                    <button (click)="openFileExisting()" class="btn btn-purple ml-30">Download File Existing</button>
                  </div>
                  <div [hidden]="!showButtonShowImageExisting" class="btn-show-file">
                    <img src="../../../assets/images/icons/ic-download-blue.png" alt="btn-plus" class="ic-download"/>
                    <button (click)="openImage()" class="btn btn-purple ml-30">Download File Existing</button>
                  </div>
                </div>

            </div>    
          </div>
        </div>
      </div>

      <div class="w-100 div-text-right mb-40 mobile-3button">
        <div class="btn-custom-2 p-relative mr-5 mt-40">
          <img src="../../../assets/images/button/Button-left-arrow.png" alt="btn-next" class="logo-btn left img-30"/>
          <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab3'">Back</button>
        </div>
        <div class="btn-custom-2 p-relative mr-5 mt-40" *ngIf="userInfo.roleId == 'TM'">
          <img src="../../../assets/images/icons/ic-upload-blue.png" alt="btn-plus" class="logo-btn left img-30"/>
          <button class="btn btn-purple btn-next" type="button" (click)="saveForm()">Save</button>
        </div>
        <div class="btn-custom-2 mr-5 mt-40" *ngIf="userInfo.roleId == 'TM'">
          <button class="btn btn-violet div-text-center" type="button" (click)="submitForm()">Submit</button>
        </div>
      </div>
    </div>
</div>
</main>
<ngx-spinner></ngx-spinner>
