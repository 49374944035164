<main class="customer-handling">
    <div class="">
        <div class="row">    
            <div ngbDropdown class="d-inline-block mr-15" >
                <div *ngIf="userInfo.roleId == 'SEH'" class="p-relative dropdown dropodown-product">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>{{productSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let product of productList; let i = index" (click)="productOnChange(product.key, product.value)">{{product.value}}</button>
                </div>
            </div>

            <div id="tab1" class="table-custom custom-white tab-slider--body mt-40 w-100 pr-10 pl-10">
                <table datatable [dtTrigger]="dtTriggerSEH" [dtOptions]="dtOptions" class="row-border hover rounded" id="myTable" style="width: 100%;">
                    <thead class="head-custom">
                        <tr class="rounderleft rounderright header-blue">
                            <!-- <th class=" ">No</th> -->
                            <th class=" ">Nama Customer</th>
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Cabang</th>
                            <th class=" ">Nama CSO</th>
                            <th class=" ">Last Follow Up Date</th>
                            <th class=" ">Prospect Status</th>
                            <th class=" ">Status</th>
                            <th class=" ">Detail</th>
                        </tr>
                      </thead>
                </table> 
            </div>
        </div>
    </div>
</main>