<main class="container-fluid dashboard mobile-tab-sh">   
    <div class="tab-slider--nav p-relative">
        <ul class="nav nav-tabs custom-tab">
          <li class="nav-item" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Daily Activity</li>
          <li class="nav-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Monthly Activity</li>
          <!-- <li class="nav-item" [class.active]="viewMode == 'tab3'" rel="tab3" (click)="viewMode ='tab3'" *ngIf="(dashboardRole == 'SPV' && userInfo.isAsuransi == 'N') || (dashboardRole == 'TL' && userInfo.isAsuransi == 'N')">Performance by Branch</li> -->
        </ul>
        <div class="dropdown-dashboard div-text-center">
          <!-- Hen Dropdown ini berlaku untuk TL & Agent aja, kalau spv di hide aja -->
            <div ngbDropdown class="d-inline-block mr-15 dropdown-sh" *ngIf="userInfo.roleId == 'SH'">
              <div *ngIf="userInfo.roleId == 'SH'" class="p-relative dropdown dropodown-product">
                  <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>{{productSelected}}</button>
                  <em class="fas fa-caret-down down-dropdown"></em>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem *ngFor="let product of productList; let i = index" (click)="productOnChange(product.key, product.value)">{{product.value}}</button>
              </div>
            </div>
          <!-- End -->
          <div ngbDropdown class="d-inline-block dropdown-calendar">
            <form  class="form-inline col-lg-3 ">
              <div class="form-group custom-calendar">
                <div class="input-group form-calendar">
                  <input class="form-control " [(ngModel)]="filterDate" name="dp" placeholder="Assigned Date" ngbDatepicker #d="ngbDatepicker" (dateSelect)="filterDashboard()" autocomplete="off" (click)="d.toggle()">
                  <i class="calendar-icon" (click)="d.toggle()"></i>
                </div>
              </div>
            </form>
              <!-- <div class="p-relative dropdown">
                  <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>19 Sept 2021</button>
                  <em class="fas fa-caret-down down-dropdown"></em>
              </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem>12 Sept 2021</button>
              <button ngbDropdownItem>08 Agst 2021</button>
            </div> -->
          </div>
            <div class="p-relative">
              <button class="btn btn-clear ml-10 pointer" (click)="clearFilter()" type="button">Clear Filter</button>
            </div>
            

            
        </div>
        
        
    </div>

    <div class="tab-slider--container">
        <div id="tab1" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab2'">
            <div class="row">
              <div class="col-lg-6" *ngIf="userInfo.roleId == 'SH'">
                  <span class="text-large div-text-left">
                    <div class="wording-sh col-lg-4 no-padding">
                      Prospect Total {{productSelected}}
                    </div>
                    : {{dataDashboardPending?.dbCount}}
                  </span>
                  <br>
                  <span class="text-large div-text-left">
                    <div class="wording-sh col-lg-4 no-padding">
                      Assigned {{productSelected}}
                    </div>
                     : {{dataDashboardPending?.resultAssignedCount}}
                  </span>
                  <br>
                  <span class="text-large div-text-left">
                    <div class="wording-sh col-lg-4 no-padding">
                      Need to be Assigned 
                    </div>
                    : {{(dataDashboardPending?.dbCount) - (dataDashboardPending?.resultAssignedCount)}}
                  </span>
                  <br>
                  <br>
              </div>
            </div>
            <table *ngIf="(dashboardSHRole == 'SRO')" datatable [dtOptions]="dtOptionsSRO" [dtTrigger]="dtTriggerSRO" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                      <th class="br-white" rowspan="2">SRO</th>
                      <th class="br-white" rowspan="2">SOURCE</th>
                      <th class="br-white" rowspan="2">DATABASE</th>
                      <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY SRO</th>
                    </tr>
                    <tr>
                        <th class="br-white">IN PROGRESS</th>
                        <th class="br-white">INTERESTED</th>
                        <th class="bg-navy">NFU</th>
                        <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                    </tr>
                  </thead>
            </table>

            <table *ngIf="(dashboardSHRole == 'SO')" datatable [dtOptions]="dtOptionsSO" [dtTrigger]="dtTriggerSO" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                      <th class="br-white" rowspan="2">SO</th>
                      <th class="br-white" rowspan="2">SOURCE</th>
                      <th class="br-white" rowspan="2">DATABASE</th>
                      <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY SO</th>
                    </tr>
                    <tr>
                        <th class="br-white">IN PROGRESS</th>
                        <th class="br-white">INTERESTED</th>
                        <th class="bg-navy">NFU</th>
                        <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                    </tr>
                  </thead>
            </table>

            <table *ngIf="(dashboardSHRole == 'SH')" datatable [dtOptions]="dtOptionsSH" [dtTrigger]="dtTriggerSH" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">{{productKeySelected == 'NC' || productKeySelected == 'KI' ? 'SO' : 'SRO'}}</th>                  
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">{{productKeySelected == 'NC' || productKeySelected == 'KI' ? 'RESULT BY SO' : 'RESULT BY SRO'}}</th>
                    </tr>
                    <tr>
                        <th class="br-white">IN PROGRESS</th>
                        <th class="br-white">INTERESTED</th>
                        <th class="bg-navy">NFU</th>
                        <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                    </tr>
                  </thead>
            </table>

            <!-- <table *ngIf="(dashboardSHRole == 'SH' && productKeySelected == 'NC')" datatable [dtOptions]="dtOptionsSHNC" [dtTrigger]="dtTriggerSHNC" class="row-border hover rounded" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">SO</th>
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY SO</th>
                    </tr>
                    <tr>
                        <th class="br-white">INTERESTED</th>
                        <th class="br-white">IN PROGRESS</th>
                        <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                        <th class="bg-navy">NFU</th>
                    </tr>
                  </thead>
            </table> -->
        </div>

        <div id="tab2" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab1'">
            <table *ngIf="(dashboardSHRole == 'SRO')" datatable [dtOptions]="dtOptions2SRO" [dtTrigger]="dtTrigger2SRO" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">SRO</th>
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY SRO</th>
                      </tr>
                      <tr>
                          <th class="br-white">IN PROGRESS</th>
                          <th class="br-white">INTERESTED</th>
                          <th class="bg-navy">NFU</th>
                          <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                      </tr>
                </thead>
            </table>

            <table *ngIf="(dashboardSHRole == 'SO')" datatable [dtOptions]="dtOptions2SO" [dtTrigger]="dtTrigger2SO" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">SO</th>
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY SO</th>
                      </tr>
                      <tr>
                          <th class="br-white">IN PROGRESS</th>
                          <th class="br-white">INTERESTED</th>
                          <th class="bg-navy">NFU</th>
                          <th class="bg-darkerblue br-white">NOT INTERESTED</th>                          
                      </tr>
                </thead>
            </table>

            <table *ngIf="(dashboardSHRole == 'SH')" datatable [dtOptions]="dtOptions2SH" [dtTrigger]="dtTrigger2SH" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">{{productKeySelected == 'NC' || productKeySelected == 'KI' ? 'SO' : 'SRO'}}</th>
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">{{productKeySelected == 'NC' || productKeySelected == 'KI' ? 'RESULT BY SO' : 'RESULT BY SRO'}}</th>
                    </tr>
                    <tr>
                        <th class="br-white">IN PROGRESS</th>
                        <th class="br-white">INTERESTED</th>
                        <th class="bg-navy">NFU</th>
                        <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                    </tr>
                  </thead>
            </table>

            <!-- <table *ngIf="(dashboardSHRole == 'SH' && productKeySelected == 'NC')" datatable [dtOptions]="dtOptions2SHNC" [dtTrigger]="dtTrigger2SHNC" class="row-border hover rounded" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">SO</th>
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY SO</th>
                    </tr>
                    <tr>
                        <th class="br-white">INTERESTED</th>
                        <th class="br-white">IN PROGRESS</th>
                        <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                        <th class="bg-navy">NFU</th>
                    </tr>
                  </thead>
            </table> -->
        </div>
        <!-- di TL&agent  tab 3 di hide saja ya hen -->
    </div>
</main>
