import { Component, NgModuleFactoryLoader, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbCalendar, NgbDate, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
import * as moment from 'moment';

@Component({
  selector: 'app-prospect-detail',
  templateUrl: './prospect-detail.component.html',
  styleUrls: ['./prospect-detail.component.css']
})
export class ProspectDetailComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  viewMode = 'tab1';
  model: NgbDateStruct;
  idDetail: any;
  dataProspectDetail: any;
  dateToday: any;

  inputCustomerNumber: any;
  inputContractNumber: any;
  inputMobileNo2: any;

  connectivityLov: any;
  contactivityLov: any;
  probingActionLov: any;
  resultLov: any;
  hasBeenCalledLov: any;
  interestedStatusLov: any;
  resultReasonLov: any;
  paketNewCarLov: any;
  branchProspect : any;
  brandList: any;
  modelList: any;
  typeList: any;
  branchDealerList : any;

  connectivitySelected: any;
  connectivityKeySelected: any = "";
  connectivityReason: any = "";

  contactivitySelected: any;
  contactivityKeySelected: any = "";
  contactivityReason: any = "";

  probingActionSelected: any;
  probingActionKeySelected: any = "";

  resultSelected: any;
  resultKeySelected: any = "";
  interestedStatusSelected: any;
  interestedStatusKeySelected: any = "";
  resultReasonSelected: any;
  resultReasonKeySelected: any = "";

  hasBeenCalledSelected: any;
  hasBeenCalledKeySelected: any = "";
  hasBeenCalledReason: any = "";

  promiseDate: NgbDateStruct;
  promiseDateString: any = "";

  pickupDate: NgbDateStruct;
  pickupDateString: any = "";

  nextRoleIdSelected: any = "";
  description: any = "";

  srcImgTemp: any = '../../../assets/images/drop-file.png';
  base64ImgString: any = "";
  srcFileName: any = "";

  paketNewCarSelected: any;
  paketNewCarKeySelected: any = "";

  branchCustSelected : any;
  branchCustIdSelected : any;

  brandSelected: any;
  brandKeySelected: any;

  modelSelected: any;
  modelKeySelected: any;

  typeSelected: any;
  typeKeySelected: any;

  branchDealerSelected : any;
  branchDealerKeySelected : any;

  carColor: any = "";
  tenor: any = "";
  angsuran: any = "";
  dp: any = "";

  custAppNo: any = "";
  custAppDate: any = "";

  isPopulated: any = 0;

  showButtonShowFileExisting: any = false;
  showButtonShowImageExisting: any = false;

  formProspect: any = {
    id: null,
    userCrt: null,
    prospectResult: {
      isDraft: null,
      isFinal: null,
      hasBeenCalled: null,
      connectivity: null,
      connectivityReason: null,
      contactivity: null,
      contactivityReason: null,
      probingAction: null,
      result: null,
      resultReason: null,
      interestedStatus: null,
      nextRoleId: null,
      promiseDate: null,
      description: null,
      attachment: null,
      paketNewCar: null,
      pickupDate: null,
      branchId: null,
      vehicleBrand: null,
      vehicleModel: null,
      vehicleType: null,
      vehicleColor: null,
      dealerId: null,
      tenor: null,
      installment: null,
      dp: null
    }
  };

  minDatePicker: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, 
    day: new Date().getDate()
  }

  ngOnInit(): void {
    // console.log('asd', this.minDatePicker);
    this.spinner.show();
    this.idDetail = this.route.snapshot.params.id;

    let tmpRequest = {
      id: this.idDetail
    };

    this.apiUtil.invokeAPI("PROSPECT_TM_DETAIL", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        // console.log("Prospect Detail", res);
        this.dataProspectDetail = res.data;

        this.spinner.hide();

        if(this.dataProspectDetail.product == 'SD'){
          this.getLovConnectivity('L001');
          this.getLovContactivity('L006');
          this.getLovResult('L009');
          this.getLovResultReason('L003');
        }
        else if(this.dataProspectDetail.product == 'NC' || this.dataProspectDetail.product == 'KI'){
          this.getLovConnectivity('L002');
          this.getLovContactivity('L007');
          this.getLovResult('L010');
          this.getLovHasBeenCalled('L013');
          this.getLovResultReason('L004');
          this.getLovPaketNewCar();
          this.getListBrand();

          this.getBranchData();

          // this.brandKeySelected = 'Car Brand';
          // this.brandSelected = 'Car Brand';

          // this.modelKeySelected = 'Car Model';
          // this.modelSelected = 'Car Model';

          // this.typeKeySelected = 'Car Type';
          // this.typeSelected = 'Car Type';
        }
        else{
          this.getLovConnectivity('L002');
          this.getLovContactivity('L007');
          this.getLovResult('L009');
        }

        this.getLovProbingAction();
        this.getLovInterestedStatus("L011");

        this.nextRoleIdSelected = "CDE";
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  alphanumericOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // console.log(charCode);
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode > 96 && charCode < 123)) {
      return true;
    }
    return false;
  }

  numberOnlyWithComma(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 44) {
      return false;
    }
    return true;
  }

  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  formatCurrency(input, blur) {
    // get input value
    var input_val = input.target.value;
  
    // don't validate empty input
    if (input_val === "") { return; }
  
    // original length
    var original_len = input_val.length;
  
    // initial caret position
    var caret_pos = input.target.selectionStart;

    // remove all non-digits
    input_val = this.formatNumber(input_val);
    input_val = input_val;

    // final formatting
    if (blur === "blur") {
      // input_val += ".00";
      return false;
    }
  
    // send updated string to input
    input.target.value = input_val;
    this.angsuran = input_val;
  }

  getLovConnectivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.connectivityLov = res.data;
        // this.connectivityKeySelected = this.connectivityLov[0].key;
        // this.connectivitySelected = this.connectivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovContactivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.contactivityLov = res.data;
        // this.contactivityKeySelected = this.contactivityLov[0].key;
        // this.contactivitySelected = this.contactivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovProbingAction(){
    this.spinner.show();

    let tmpRequest = {
      type: "L008"
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.probingActionLov = res.data;
        // this.probingActionKeySelected = this.probingActionLov[0].key;
        // this.probingActionSelected = this.probingActionLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResult(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultLov = res.data;
        // this.resultKeySelected = this.resultLov[0].key;
        // this.resultSelected = this.resultLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovInterestedStatus(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.interestedStatusLov = res.data;
        // this.interestedStatusKeySelected = this.interestedStatusLov[0].key;
        // this.interestedStatusSelected = this.interestedStatusLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovHasBeenCalled(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.hasBeenCalledLov = res.data;
        // this.hasBeenCalledKeySelected = this.hasBeenCalledLov[0].key;
        // this.hasBeenCalledSelected = this.hasBeenCalledLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResultReason(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultReasonLov = res.data;
        // this.resultReasonKeySelected = this.resultReasonLov[0].key;
        // this.resultReasonSelected = this.resultReasonLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovPaketNewCar(){
    this.spinner.show();

    let tmpRequest = {
      type: 'L014'
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.paketNewCarLov = res.data;
        // this.paketNewCarKeySelected = this.paketNewCarLov[0].key;
        // this.paketNewCarSelected = this.paketNewCarLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getBranchData() {
    this.spinner.show();

    let tmpRequest = {
    };

    this.apiUtil.invokeAPI("PROSPECT_BRANCHES", tmpRequest).subscribe(res => {
      if (res.state.status == '200'){
        this.branchProspect = res.data;
        // this.branchCustIdSelected  = this.branchProspect[0].id;
        // this.branchCustSelected = this.branchProspect[0].name;
        this.spinner.hide();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });

  }

  getListBrand(){
    this.spinner.show();

    let tmpRequest = {
    };

    this.apiUtil.invokeAPI("LIST_BRAND", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.brandList = res.data;
        // this.brandKeySelected = this.brandList[0];
        // this.brandSelected = this.brandList[0];
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getListModel(brand){
    this.spinner.show();

    let tmpRequest = {
      brand: brand
    };

    this.apiUtil.invokeAPI("LIST_MODEL", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.modelList = res.data;
        // this.modelKeySelected = this.modelList[0];
        // this.modelSelected = this.modelList[0];
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getListType(brand, model){
    this.spinner.show();

    let tmpRequest = {
      brand: brand,
      model: model
    };

    this.apiUtil.invokeAPI("LIST_TYPE", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.typeList = res.data;
        // this.typeKeySelected = this.typeList[0];
        // this.typeSelected = this.typeList[0];
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getBranchDealerList(branchId, brand) {
    this.spinner.show();

    let tmpRequest = {
      branchId : branchId,
      brand : brand
    };

    this.apiUtil.invokeAPI("PROSPECT_BRANCH_DEALER", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.branchDealerList = res.data;
        // if (this.branchDealerList) {
        //   this.branchDealerSelected = this.branchDealerList[0].name;
        //   this.branchDealerKeySelected = this.branchDealerList[0].id;
        // }
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      this.toastr.error(err);
      this.spinner.hide();
	  });

  }

  editPhone(){
    $(document).ready(function(){
      $('.editPhone').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editPhone').addClass('d-none'); 
      console.log('yes')
    });
  }

  editCustNum(){
    $(document).ready(function(){
      $('.editCustNum').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editCustNum').addClass('d-none'); 
      console.log('yes')
    });
  }

  editContractNum(){
    $(document).ready(function(){
      $('.editContractNum').removeClass('d-none'); 
    });
    $( ".btn-cancel" ).click(function() {
      $('.editContractNum').addClass('d-none'); 
      console.log('yes')
    });
  }

  connectivityOnChange(key, value){
    this.connectivityKeySelected = key;
    this.connectivitySelected = value;
    
    //Reset value if not connected
    if(key != 'L001-1' && key != 'L002-1'){
      this.contactivityKeySelected = null;
      this.contactivitySelected = null;

      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.promiseDate = null;
      this.nextRoleIdSelected = "CDE";

      this.paketNewCarKeySelected = null;
      this.paketNewCarSelected = null;

      this.pickupDate = null;

      this.branchCustSelected = null;
      this.branchCustIdSelected = null;

      this.brandKeySelected = null;
      this.brandSelected = null;

      this.modelKeySelected = null;
      this.modelSelected = null;

      this.typeKeySelected = null;
      this.typeSelected = null;

      this.branchDealerKeySelected = null;
      this.branchDealerSelected = null;

      this.carColor = null;
      this.tenor = null;
      this.angsuran = null;
      this.dp = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  contactivityOnChange(key, value){
    this.contactivityKeySelected = key
    this.contactivitySelected = value;

    //Reset value if not contacted
    if(key != 'L006-1' && key != 'L007-1'){
      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.promiseDate = null;
      this.nextRoleIdSelected = "CDE";

      this.paketNewCarKeySelected = null;
      this.paketNewCarSelected = null;

      this.pickupDate = null;

      this.branchCustIdSelected = null;
      this.branchCustSelected = null;

      this.brandKeySelected = null;
      this.brandSelected = null;

      this.modelKeySelected = null;
      this.modelSelected = null;

      this.typeKeySelected = null;
      this.typeSelected = null;

      this.branchDealerKeySelected = null;
      this.branchDealerSelected = null;

      this.carColor = null;
      this.tenor = null;
      this.angsuran = null;
      this.dp = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  probingActionOnChange(key, value){
    this.probingActionKeySelected = key
    this.probingActionSelected = value;
  }

  nextRoleIdOnChange(value){
    this.nextRoleIdSelected = value;
  }

  resultOnChange(key, value){
    this.resultKeySelected = key
    this.resultSelected = value;

    if(key == "L010-4"){
      this.promiseDate = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;
      
      this.paketNewCarKeySelected = null;
      this.paketNewCarSelected = null;

      this.pickupDate = null;

      this.branchCustIdSelected = null;
      this.branchCustSelected = null;

      this.brandKeySelected = null;
      this.brandSelected = null;

      this.modelKeySelected = null;
      this.modelSelected = null;

      this.typeKeySelected = null;
      this.typeSelected = null;

      this.branchDealerKeySelected = null;
      this.branchDealerSelected = null;

      this.carColor = null;
      this.tenor = null;
      this.angsuran = null;
      this.dp = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    else if(key == "L010-1" || key == "L010-2"){
      this.promiseDate = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;
    }
    else if(key == "L010-3"){
      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;
    }
  }

  hasBeenCalledOnChange(key, value){
    this.hasBeenCalledKeySelected = key
    this.hasBeenCalledSelected = value;

    //Reset value if not has been called
    if(key != 'L013-1' ){
      this.connectivityKeySelected = null;
      this.connectivitySelected = null;

      this.contactivityKeySelected = null;
      this.contactivitySelected = null;

      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.promiseDate = null;
      this.nextRoleIdSelected = "CDE";

      this.paketNewCarKeySelected = null;
      this.paketNewCarSelected = null;

      this.pickupDate = null;

      this.branchCustIdSelected = null;
      this.branchCustSelected = null;

      this.brandKeySelected = null;
      this.brandSelected = null;

      this.modelKeySelected = null;
      this.modelSelected = null;

      this.typeKeySelected = null;
      this.typeSelected = null;

      this.branchDealerKeySelected = null;
      this.branchDealerSelected = null;

      this.carColor = null;
      this.tenor = null;
      this.angsuran = null;
      this.dp = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  resultReasonOnChange(key, value){
    this.resultReasonKeySelected = key
    this.resultReasonSelected = value;
  }

  interestedStatusOnChange(key, value){
    this.interestedStatusKeySelected = key
    this.interestedStatusSelected = value;
  }

  paketNewCarOnChange(key, value){
    this.paketNewCarKeySelected = key;
    this.paketNewCarSelected = value;
  }

  branchesOnChange(key, value){
    this.branchCustSelected = value;
    this.branchCustIdSelected = key;

    this.getBranchDealerList( this.branchCustIdSelected, this.brandKeySelected);

    this.branchDealerSelected = '';
    this.branchDealerKeySelected = '';

  }

  brandOnChange(key, value){
    this.brandKeySelected = key;
    this.brandSelected = value;

    this.modelList = [];
    this.modelKeySelected = '';
    this.modelSelected = '';

    this.getListModel(this.brandSelected);
    this.getBranchDealerList(this.branchCustIdSelected, this.brandKeySelected);
    
    this.typeList = [];
    this.typeKeySelected = '';
    this.typeSelected = '';
  }

  modelOnChange(key, value){
    this.modelKeySelected = key;
    this.modelSelected = value;

    this.getListType(this.brandSelected, this.modelSelected);

    this.typeList = [];
    this.typeKeySelected = '';
    this.typeSelected = '';
  }

  typeOnChange(key, value){
    this.typeKeySelected = key;
    this.typeSelected = value;
  }

  branchDealerOnChange(key, value) {
    this.branchDealerKeySelected = key;
    this.branchDealerSelected = value; 
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.base64ImgString = reader.result;
      
      if(this.base64ImgString.includes("application/pdf")){
        this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
        this.srcFileName = file.name;
      }
      else{
        this.srcImgTemp = (this.domSanitizer.bypassSecurityTrustResourceUrl(this.base64ImgString) as any).changingThisBreaksApplicationSecurity;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
       console.log("No file selected!");
       return
    }
    let file: File = event.target.files[0];
    
    if(file.size < (5000 * 1024)){
      await this.getBase64(file);
    }
    else{
      this.toastr.error("Attachment more than 5 MB");
    }
  }

  clearImgUpload(){
    this.srcImgTemp = "../../../assets/images/drop-file.png"; 
    this.base64ImgString = null;
    this.srcFileName = "";
    
    this.showButtonShowFileExisting = false;
    this.showButtonShowImageExisting = false;
  }

  compareDate(dateSla){
    let dateSlaRaw = new Date(dateSla);
    let dateSlaa = moment(dateSlaRaw).format('YYYY-MM-DD HH:mm:ss');

    let dateToday = moment().format('YYYY-MM-DD HH:mm:ss');

    if(dateSlaa < dateToday){
      return true;
    }
    else{
      return false;
    }
  }

  changeCustNo(){
    let tmpRequest = {
      id: this.idDetail,
      type: "customerNumber",
      value: this.inputCustomerNumber
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  changeContractNo(){
    let tmpRequest = {
      id: this.idDetail,
      type: "contractNumber",
      value: this.inputContractNumber
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  checkMobileNo2(){
    if (this.inputMobileNo2[0] != '0'){
      this.toastr.error("Invalid Format Mobile Phone");
    }
    else if ((this.inputMobileNo2[0] == '0') && (this.inputMobileNo2.length >= '5')){ 
      this.changeMobileNo2();
    }
    else if(this.inputMobileNo2.length < '5' ) {
      this.toastr.error("Mobile number error at least 5 digits");
    }
    else if(this.inputMobileNo2 == "" ) {
      this.toastr.error("Please Fill Mobile Phone ");
    }
  }

  changeMobileNo2(){
    let tmpRequest = {
      id: this.idDetail,
      type: "mobilePhone2",
      value: this.inputMobileNo2
    }

    this.apiUtil.invokeAPI("PROSPECT_UPDATE", tmpRequest).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        location.reload();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  openFileExisting(){
    let base64String  = this.dataProspectDetail.prospectResult.attachment.split(',')[1];
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  openImage(){
    let base64String  = this.dataProspectDetail.prospectResult.attachment;
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName + '.' + base64String.split('/')[1].split(';')[0];
    downloadLink.click();
  }

  populateData(){
    if(this.isPopulated == 0){
      console.log("populate data first");
      //Set Prospect Result if exist
      if(this.hasBeenCalledLov){
        this.hasBeenCalledKeySelected = this.dataProspectDetail.prospectResult.hasBeenCalled != null ? this.dataProspectDetail.prospectResult.hasBeenCalled : null;
        if(this.hasBeenCalledKeySelected != null){
          this.hasBeenCalledSelected = this.hasBeenCalledLov.find(el => el.key === this.hasBeenCalledKeySelected).value ? this.hasBeenCalledLov.find(el => el.key === this.hasBeenCalledKeySelected).value : null;
        }
        else{
          this.hasBeenCalledSelected = null;
        }
        this.hasBeenCalledReason = this.dataProspectDetail.prospectResult.hasBeenCalledReason != null ? this.dataProspectDetail.prospectResult.hasBeenCalledReason : null;
      }

      if(this.interestedStatusLov){
        this.interestedStatusKeySelected = this.dataProspectDetail.prospectResult.interestedStatus != null ? this.dataProspectDetail.prospectResult.interestedStatus : null;
        if(this.interestedStatusKeySelected != null){
          this.interestedStatusSelected = this.interestedStatusLov.find(el => el.key === this.interestedStatusKeySelected).value ? this.interestedStatusLov.find(el => el.key === this.interestedStatusKeySelected).value : null;
        }
        else{
          this.interestedStatusSelected = null;
        }
      }

      if(this.resultReasonLov){
        this.resultReasonKeySelected = this.dataProspectDetail.prospectResult.resultReason != null ? this.dataProspectDetail.prospectResult.resultReason : null;
        if(this.resultReasonKeySelected){
          this.resultReasonSelected = this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value ? this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value : null;
        }
        else{
          this.resultReasonSelected = null;
        }
      }

      if(this.brandList){
        this.brandKeySelected = this.dataProspectDetail.prospectResult.vehicleBrand != null ? this.dataProspectDetail.prospectResult.vehicleBrand : null;
        this.brandSelected = this.dataProspectDetail.prospectResult.vehicleBrand != null ? this.dataProspectDetail.prospectResult.vehicleBrand : null;

        this.branchCustIdSelected = this.dataProspectDetail.prospectResult.branchId != null ? this.dataProspectDetail.prospectResult.branchId : null;
        if(this.branchCustIdSelected){
          this.branchCustSelected = this.branchProspect.find(el => el.id === this.branchCustIdSelected).name ? this.branchProspect.find(el => el.id === this.branchCustIdSelected).name : null;
        }
        else{
          this.branchCustSelected = null;
        }

        if(this.brandSelected != '' && this.brandSelected != null && this.branchCustSelected != '' && this.branchCustSelected != null){
          this.getListModel(this.brandSelected);

          this.getBranchDealerList(this.dataProspectDetail.prospectResult.branchId, this.brandSelected);

          setTimeout(() => {
            this.modelKeySelected = this.dataProspectDetail.prospectResult.vehicleModel != null ? this.dataProspectDetail.prospectResult.vehicleModel : null;
            this.modelSelected = this.dataProspectDetail.prospectResult.vehicleModel != null ? this.dataProspectDetail.prospectResult.vehicleModel : null;

            this.branchDealerKeySelected = this.dataProspectDetail.prospectResult.dealerId != null ? this.dataProspectDetail.prospectResult.dealerId : null;
            if(this.branchDealerKeySelected){        
              this.branchDealerSelected = this.branchDealerList.find(el => el.id === this.branchDealerKeySelected).name ? this.branchDealerList.find(el => el.id === this.branchDealerKeySelected).name : null;
            }
            else{
              this.branchDealerSelected = null;
            }

            if(this.modelSelected != '' && this.modelSelected != null){
              this.getListType(this.brandSelected, this.modelSelected);

              setTimeout(() => {
                this.typeKeySelected = this.dataProspectDetail.prospectResult.vehicleType != null ? this.dataProspectDetail.prospectResult.vehicleType : null;
                this.typeSelected = this.dataProspectDetail.prospectResult.vehicleType != null ? this.dataProspectDetail.prospectResult.vehicleType : null;
              }, 500);
            }

          }, 500);
        }
        else if(this.brandSelected != '' && this.brandSelected != null){
          this.getListModel(this.brandSelected);

          setTimeout(() => {
            this.modelKeySelected = this.dataProspectDetail.prospectResult.vehicleModel != null ? this.dataProspectDetail.prospectResult.vehicleModel : null;
            this.modelSelected = this.dataProspectDetail.prospectResult.vehicleModel != null ? this.dataProspectDetail.prospectResult.vehicleModel : null;

            this.branchDealerKeySelected = this.dataProspectDetail.prospectResult.dealerId != null ? this.dataProspectDetail.prospectResult.dealerId : null;
            if(this.branchDealerKeySelected){        
              this.branchDealerSelected = this.branchDealerList.find(el => el.id === this.branchDealerKeySelected).name ? this.branchDealerList.find(el => el.id === this.branchDealerKeySelected).name : null;
            }
            else{
              this.branchDealerSelected = null;
            }

            if(this.modelSelected != '' && this.modelSelected != null){
              this.getListType(this.brandSelected, this.modelSelected);

              setTimeout(() => {
                this.typeKeySelected = this.dataProspectDetail.prospectResult.vehicleType != null ? this.dataProspectDetail.prospectResult.vehicleType : null;
                this.typeSelected = this.dataProspectDetail.prospectResult.vehicleType != null ? this.dataProspectDetail.prospectResult.vehicleType : null;
              }, 500);
            }

          }, 500);
        }
        
      }

      if(this.paketNewCarLov){
        this.paketNewCarKeySelected = this.dataProspectDetail.prospectResult.paketNewCar != null ? this.dataProspectDetail.prospectResult.paketNewCar : null;
        if(this.paketNewCarKeySelected != null){
          this.paketNewCarSelected = this.paketNewCarLov.find(el => el.key === this.paketNewCarKeySelected).value ? this.paketNewCarLov.find(el => el.key === this.paketNewCarKeySelected).value : null;
        }
        else{
          this.paketNewCarSelected = null;
        }
      }

      if(this.dataProspectDetail.product == "NC" || this.dataProspectDetail.product == "KI"){
        this.carColor = this.dataProspectDetail.prospectResult.vehicleColor != null ? this.dataProspectDetail.prospectResult.vehicleColor : null;
        this.tenor = this.dataProspectDetail.prospectResult.tenor != null ? this.dataProspectDetail.prospectResult.tenor : null;
        this.angsuran = this.dataProspectDetail.prospectResult.installment != null ? this.dataProspectDetail.prospectResult.installment : null;
        this.dp = this.dataProspectDetail.prospectResult.dp != null ? this.dataProspectDetail.prospectResult.dp : null;

        this.custAppNo = this.dataProspectDetail.prospectResult.custAppNo != null ? this.dataProspectDetail.prospectResult.custAppNo : null;
        if(this.dataProspectDetail.prospectResult.custAppDate != null){
          let appDate = moment(this.dataProspectDetail.prospectResult.custAppDate, "YYYY-MM-DD").format("YYYY-M-D");
          let yearSt = Number(appDate.split('-')[0]);
          let monthSt = Number(appDate.split('-')[1]);
          let daySt = Number(appDate.split('-')[2]);
          this.custAppDate = new NgbDate(yearSt, monthSt, daySt);
        }
        else{
          this.custAppDate = null;
        }

        if(this.dataProspectDetail.prospectResult.pickupDate != null){
          let appDate = moment(this.dataProspectDetail.prospectResult.pickupDate, "YYYY-MM-DD").format("YYYY-M-D");
          let yearSt = Number(appDate.split('-')[0]);
          let monthSt = Number(appDate.split('-')[1]);
          let daySt = Number(appDate.split('-')[2]);
          this.pickupDate = new NgbDate(yearSt, monthSt, daySt);
        }
        else{
          this.pickupDate = null;
        }
      }

      this.connectivityKeySelected = this.dataProspectDetail.prospectResult.connectivity != null ? this.dataProspectDetail.prospectResult.connectivity : null;
      if(this.connectivityKeySelected != null){
        this.connectivitySelected = this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value ? this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value : null;
      }
      else{
        this.connectivitySelected = null;
      }
      this.connectivityReason = this.dataProspectDetail.prospectResult.connectivityReason != null ? this.dataProspectDetail.prospectResult.connectivityReason : "";

      this.contactivityKeySelected = this.dataProspectDetail.prospectResult.contactivity != null ? this.dataProspectDetail.prospectResult.contactivity : null;
      if(this.contactivityKeySelected != null){
        this.contactivitySelected = this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value ? this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value : null;
      }
      else{
        this.contactivitySelected = null;
      }
      this.contactivityReason = this.dataProspectDetail.prospectResult.contactivityReason != null ? this.dataProspectDetail.prospectResult.contactivityReason : null;
      
      this.probingActionKeySelected = this.dataProspectDetail.prospectResult.probingAction != null ? this.dataProspectDetail.prospectResult.probingAction : null;
      if(this.probingActionKeySelected != null){
        this.probingActionSelected = this.probingActionLov.find(el => el.key === this.probingActionKeySelected).value ? this.probingActionLov.find(el => el.key === this.probingActionKeySelected).value : null;
      }
      else{
        this.probingActionSelected = null;
      }
      
      this.resultKeySelected = this.dataProspectDetail.prospectResult.result != null ? this.dataProspectDetail.prospectResult.result : null;
      if(this.resultKeySelected != null){
        this.resultSelected = this.resultLov.find(el => el.key === this.resultKeySelected).value ? this.resultLov.find(el => el.key === this.resultKeySelected).value : null;
      }
      else{
        this.resultSelected = null;
      }

      if(this.dataProspectDetail.prospectResult.promiseDate != null){
        //Assign value to ngbDate
        let dateCustomer = moment(this.dataProspectDetail.prospectResult.promiseDate, "YYYY-MM-DD").format("YYYY-M-D");
        let yearSt = Number(dateCustomer.split('-')[0]);
        let monthSt = Number(dateCustomer.split('-')[1]);
        let daySt = Number(dateCustomer.split('-')[2]);
        this.promiseDate = new NgbDate(yearSt, monthSt, daySt);
      }
      else{
        this.promiseDate = null;
      }

      this.nextRoleIdSelected = this.dataProspectDetail.prospectResult.nextRoleIdTemp != null ? this.dataProspectDetail.prospectResult.nextRoleIdTemp : "CDE";

      this.description = this.dataProspectDetail.prospectResult.description != null ? this.dataProspectDetail.prospectResult.description : null;

      if(this.dataProspectDetail.prospectResult.attachment != null){
        if(this.dataProspectDetail.prospectResult.attachment.split(',')[0].includes("image")){
          this.srcImgTemp = this.dataProspectDetail.prospectResult.attachment != null ? this.dataProspectDetail.prospectResult.attachment : "../../../assets/images/drop-file.png"; 
          this.showButtonShowImageExisting = true;
        }
        else{
          this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
          this.showButtonShowFileExisting = true;
        }
      }
      else{
        this.srcImgTemp = "../../../assets/images/drop-file.png"; 
      }
      //End

      this.isPopulated = 1;
    }
    else{
      
    }
  }

  submitForm(){
    this.formProspect.id = this.idDetail;
    this.formProspect.userCrt = this.userInfo.userId;
    this.formProspect.prospectResult.isDraft = "N";
    this.formProspect.prospectResult.isFinal = "Y";

    //For Siap Dana Product
    if(this.dataProspectDetail.product == "SD"){
      if(this.connectivityKeySelected != null && this.connectivityKeySelected != ""){
        if(this.connectivityKeySelected == "L001-1"){
          //If Connected
          if(this.contactivityKeySelected != null && this.contactivityKeySelected != ""){
            if(this.contactivityKeySelected == "L006-1"){
              //If Connected & Contacted
              if(this.probingActionKeySelected == null || this.probingActionKeySelected == ""){
                this.toastr.error("Please Fill Probing Action");
              }
              if(this.resultKeySelected == null || this.resultKeySelected == ""){
                this.toastr.error("Please Fill Result");
              }
              else if(this.resultKeySelected != null || this.resultKeySelected != ""){
                if(this.resultKeySelected == "L009-1"){
                  if(this.interestedStatusKeySelected == null || this.interestedStatusKeySelected == ""){
                    this.toastr.error("Please Fill Interested Status");
                  }
                  else{
                    //Pass if interested
                    this.hitSubmitSD();
                  }
  
                }
                if(this.resultKeySelected == "L009-2"){
                  if(this.promiseDate == null || this.promiseDate == undefined){
                    this.toastr.error("Please Fill Promise Date");
                  }     
                  else{
                    //Pass if Need follow up
                    this.hitSubmitSD();
                  }             
  
                }
                if(this.resultKeySelected == "L009-3"){
                  if(this.resultReasonKeySelected == null || this.resultReasonKeySelected == ""){
                    this.toastr.error("Please Fill Result Reason");
                  }   
                  else{
                    //Pass if Not Interested
                    this.hitSubmitSD();
                  }    
  
                }
              }
            }
            else{
              //Contactivity != contacted
              this.hitSubmitSD();
            }
          }
          else{
            this.toastr.error("Please Fill Contactivity");
          }
        }
        else{
          //Connectivity != connected
          this.hitSubmitSD();
        }
      }
      else{
        this.toastr.error("Please Fill Connectivity");
      }
    }

    //For New Car Product
    else{
      if(this.hasBeenCalledKeySelected != null && this.hasBeenCalledKeySelected != ""){
        if(this.hasBeenCalledKeySelected == "L013-1"){
          //If Has Been Called
          if(this.connectivityKeySelected != null && this.connectivityKeySelected != ""){
            if(this.connectivityKeySelected == "L002-1"){
              //If Connected
              if(this.contactivityKeySelected != null && this.contactivityKeySelected != ""){
                if(this.contactivityKeySelected == "L007-1"){
                  //If Connected & Contacted
                  if(this.probingActionKeySelected == null || this.probingActionKeySelected == ""){
                    this.toastr.error("Please Fill Probing Action");
                  }
                  //Check Result Status if null
                  if(this.resultKeySelected == null || this.resultKeySelected == ""){
                    this.toastr.error("Please Fill Result");
                  }
                  if(this.resultKeySelected != null || this.resultKeySelected != ""){
                    if(this.resultKeySelected == "L010-1" || this.resultKeySelected == "L010-2"){
                      //Check Interested Status if null
                      if(this.interestedStatusKeySelected == null || this.interestedStatusKeySelected == ""){
                        this.toastr.error("Please Fill Interested Status");
                      }
                      //Check Paket New Car if null
                      if(this.paketNewCarKeySelected == null || this.paketNewCarKeySelected == ""){
                        this.toastr.error("Please Fill Paket New Car");
                      }
                      //Check Waktu Pengambilan Mobil if null
                      if(this.pickupDate == null || this.pickupDate == undefined){
                        this.toastr.error("Please Fill Waktu Pengambilan Mobil");
                      }
                      //Check Branch if null
                      if(this.branchCustIdSelected == null || this.branchCustIdSelected == ""){
                        this.toastr.error("Please Fill Branch");
                      }
                      //Check Car Brand if null
                      if(this.brandKeySelected == null || this.brandKeySelected == ""){
                        this.toastr.error("Please Fill Brand");
                      }
                      //Check Car Model if null
                      if(this.modelKeySelected == null || this.modelKeySelected == ""){
                        this.toastr.error("Please Fill Model");
                      }
                      //Check Car Type if null
                      if(this.typeKeySelected == null || this.typeKeySelected == ""){
                        this.toastr.error("Please Fill Type");
                      }
                      //Check Dealer if null
                      if(this.branchDealerKeySelected == null || this.branchDealerKeySelected == ""){
                        this.toastr.error("Please Fill Dealer");
                      }
                      //Check Car Color if null
                      if(this.carColor == null || this.carColor == ""){
                        this.toastr.error("Please Fill Car Color");
                      }
                      //Check Car Tenor if null
                      if(this.tenor == null || this.tenor == ""){
                        this.toastr.error("Please Fill Tenor");
                      }
                      //Check Car Angsuran if null
                      if(this.angsuran == null || this.angsuran == ""){
                        this.toastr.error("Please Fill Angsuran");
                      }
                      //Check Car DP if null
                      if(this.dp == null || this.dp == ""){
                        this.toastr.error("Please Fill DP");
                      }
                      else if(
                        (this.interestedStatusKeySelected != null && this.interestedStatusKeySelected != "") &&
                        (this.paketNewCarKeySelected != null && this.paketNewCarKeySelected != "") &&
                        (this.pickupDate != null && this.pickupDate != undefined) &&
                        (this.branchCustIdSelected != null && this.branchCustIdSelected != "") &&
                        (this.brandKeySelected != null && this.brandKeySelected != "") &&
                        (this.modelKeySelected != null && this.modelKeySelected != "") &&
                        (this.typeKeySelected != null && this.typeKeySelected != "") &&
                        (this.branchDealerKeySelected != null && this.branchDealerKeySelected != "") &&
                        (this.carColor != null && this.carColor != "") &&
                        (this.tenor != null && this.tenor != "") &&
                        (this.angsuran != null && this.angsuran != "") &&
                        (this.dp != null && this.dp != "")
                      ){
                        //Pass hit submit
                        this.hitSubmitNC();
                      }
      
                    }
                    if(this.resultKeySelected == "L010-3"){
                      //Check fu Date if null
                      if(this.promiseDate == null || this.promiseDate == undefined){
                        this.toastr.error("Please Fill Promise Date");
                      }
                      //Check Paket New Car if null
                      if(this.paketNewCarKeySelected == null || this.paketNewCarKeySelected == ""){
                        this.toastr.error("Please Fill Paket New Car");
                      }
                      //Check Waktu Pengambilan Mobil if null
                      if(this.pickupDate == null || this.pickupDate == undefined){
                        this.toastr.error("Please Fill Waktu Pengambilan Mobil");
                      }
                      //Check Branch if null
                      if(this.branchCustIdSelected == null || this.branchCustIdSelected == ""){
                        this.toastr.error("Please Fill Branch");
                      }
                      //Check Car Brand if null
                      if(this.brandKeySelected == null || this.brandKeySelected == ""){
                        this.toastr.error("Please Fill Brand");
                      }
                      //Check Car Model if null
                      if(this.modelKeySelected == null || this.modelKeySelected == ""){
                        this.toastr.error("Please Fill Model");
                      }
                      //Check Car Type if null
                      if(this.typeKeySelected == null || this.typeKeySelected == ""){
                        this.toastr.error("Please Fill Type");
                      }
                      //Check Dealer if null
                      if(this.branchDealerKeySelected == null || this.branchDealerKeySelected == ""){
                        this.toastr.error("Please Fill Dealer");
                      }
                      //Check Car Color if null
                      if(this.carColor == null || this.carColor == ""){
                        this.toastr.error("Please Fill Car Color");
                      }
                      //Check Car Tenor if null
                      if(this.tenor == null || this.tenor == ""){
                        this.toastr.error("Please Fill Tenor");
                      }
                      //Check Car Angsuran if null
                      if(this.angsuran == null || this.angsuran == ""){
                        this.toastr.error("Please Fill Angsuran");
                      }
                      //Check Car DP if null
                      if(this.dp == null || this.dp == ""){
                        this.toastr.error("Please Fill DP");
                      }
                      else if(                        
                        (this.promiseDate != null && this.promiseDate != undefined) &&
                        (this.paketNewCarKeySelected != null && this.paketNewCarKeySelected != "") &&
                        (this.pickupDate != null && this.pickupDate != undefined) &&
                        (this.branchCustIdSelected != null && this.branchCustIdSelected != "") &&
                        (this.brandKeySelected != null && this.brandKeySelected != "") &&
                        (this.modelKeySelected != null && this.modelKeySelected != "") &&
                        (this.typeKeySelected != null && this.typeKeySelected != "") &&
                        (this.branchDealerKeySelected != null && this.branchDealerKeySelected != "") &&
                        (this.carColor != null && this.carColor != "") &&
                        (this.tenor != null && this.tenor != "") &&
                        (this.angsuran != null && this.angsuran != "") &&
                        (this.dp != null && this.dp != "")
                      ){
                        //Pass hit submit
                        this.hitSubmitNC();
                      }              
                    }
                    if(this.resultKeySelected == "L010-4"){
                      //Check Result Reason if null
                      if(this.resultReasonKeySelected == null || this.resultReasonKeySelected == ""){
                        this.toastr.error("Please Fill Result Reason");
                      }   
                      // //Check Paket New Car if null
                      // if(this.paketNewCarKeySelected == null || this.paketNewCarKeySelected == ""){
                      //   this.toastr.error("Please Fill Paket New Car");
                      // }
                      // //Check Car Brand if null
                      // if(this.brandKeySelected == null || this.brandKeySelected == ""){
                      //   this.toastr.error("Please Fill Brand");
                      // }
                      // //Check Car Model if null
                      // if(this.modelKeySelected == null || this.modelKeySelected == ""){
                      //   this.toastr.error("Please Fill Model");
                      // }
                      // //Check Car Type if null
                      // if(this.typeKeySelected == null || this.typeKeySelected == ""){
                      //   this.toastr.error("Please Fill Type");
                      // }
                      // //Check Car Color if null
                      // if(this.carColor == null || this.carColor == ""){
                      //   this.toastr.error("Please Fill Car Color");
                      // }
                      // //Check Car Tenor if null
                      // if(this.tenor == null || this.tenor == ""){
                      //   this.toastr.error("Please Fill Tenor");
                      // }
                      // //Check Car Angsuran if null
                      // if(this.angsuran == null || this.angsuran == ""){
                      //   this.toastr.error("Please Fill Angsuran");
                      // }
                      // //Check Car DP if null
                      // if(this.dp == null || this.dp == ""){
                      //   this.toastr.error("Please Fill DP");
                      // }
                      else if(
                        (this.resultReasonKeySelected != null && this.resultReasonKeySelected != "")
                        // (this.paketNewCarKeySelected != null && this.paketNewCarKeySelected != "") &&
                        // (this.brandKeySelected != null && this.brandKeySelected != "") &&
                        // (this.modelKeySelected != null && this.modelKeySelected != "") &&
                        // (this.typeKeySelected != null && this.typeKeySelected != "") &&
                        // (this.carColor != null && this.carColor != "") &&
                        // (this.tenor != null && this.tenor != "") &&
                        // (this.angsuran != null && this.angsuran != "") &&
                        // (this.dp != null && this.dp != "")
                      ){
                        //Pass hit submit
                        this.hitSubmitNC();
                      }  
                    }
                  }
                }
                else{
                  //Contactivity != contacted
                  this.hitSubmitNC();
                }
              }
              else{
                this.toastr.error("Please Fill Contactivity");
              }
            }
            else{
              //Connectivity != connected
              this.hitSubmitNC();
            }
          }
          else{
            this.toastr.error("Please Fill Connectivity");
          }
        }
        else{
          //Has Been Caled != has been called
          this.hitSubmitNC();
        }
      }
      else{
        this.toastr.error("Please Fill Has Been Called");
      }
    }
  }

  saveForm(){
    this.formProspect.id = this.idDetail;
    this.formProspect.userCrt = this.userInfo.userId;
    this.formProspect.prospectResult.isDraft = "Y";
    this.formProspect.prospectResult.isFinal = "N";
    this.formProspect.prospectResult.hasBeenCalled = this.hasBeenCalledKeySelected != "" ? this.hasBeenCalledKeySelected : null;
    this.formProspect.prospectResult.connectivity = this.connectivityKeySelected != "" ? this.connectivityKeySelected : null;
    this.formProspect.prospectResult.connectivityReason = this.connectivityReason != "" ? this.connectivityReason : null;
    this.formProspect.prospectResult.contactivity = this.contactivityKeySelected != "" ? this.contactivityKeySelected : null; 
    this.formProspect.prospectResult.contactivityReason = this.contactivityReason != "" ? this.contactivityReason : null;
    this.formProspect.prospectResult.probingAction = this.probingActionKeySelected != "" ? this.probingActionKeySelected : null;
    this.formProspect.prospectResult.result = this.resultKeySelected != "" ? this.resultKeySelected : null;
    this.formProspect.prospectResult.interestedStatus = this.interestedStatusKeySelected != "" ? this.interestedStatusKeySelected : null;
    this.formProspect.prospectResult.resultReason = this.resultReasonKeySelected != "" ? this.resultReasonKeySelected : null;
    this.formProspect.prospectResult.nextRoleId = this.nextRoleIdSelected != "" ? this.nextRoleIdSelected : null;

    if(this.promiseDate && this.promiseDate != undefined){
      let yearString = this.promiseDate.year;
      let monthString = this.promiseDate.month.toString().length == 1 ? "0" + this.promiseDate.month : this.promiseDate.month;
      let dayString =  this.promiseDate.day.toString().length == 1 ? "0" + this.promiseDate.day : this.promiseDate.day;
      this.promiseDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.promiseDate = this.promiseDateString ? this.promiseDateString : null;
    }

    if(this.pickupDate && this.pickupDate != undefined){
      let yearString = this.pickupDate.year;
      let monthString = this.pickupDate.month.toString().length == 1 ? "0" + this.pickupDate.month : this.pickupDate.month;
      let dayString =  this.pickupDate.day.toString().length == 1 ? "0" + this.pickupDate.day : this.pickupDate.day;
      this.pickupDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.pickupDate = this.pickupDateString ? this.pickupDateString : null;
    }

    this.formProspect.prospectResult.description = this.description != "" ? this.description : null;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;
    
    this.formProspect.prospectResult.paketNewCar = this.paketNewCarKeySelected != "" ? this.paketNewCarKeySelected : null;
    this.formProspect.prospectResult.branchId = this.branchCustIdSelected != "" ? this.branchCustIdSelected : null;
    this.formProspect.prospectResult.vehicleBrand = this.brandKeySelected != "" ? this.brandKeySelected : null;
    this.formProspect.prospectResult.vehicleModel = this.modelKeySelected != "" ? this.modelKeySelected : null;
    this.formProspect.prospectResult.vehicleType = this.typeKeySelected != "" ? this.typeKeySelected : null;
    this.formProspect.prospectResult.dealerId = this.branchDealerKeySelected != "" ? this.branchDealerKeySelected : null;
    this.formProspect.prospectResult.vehicleColor = this.carColor != "" ? this.carColor : null;
    this.formProspect.prospectResult.tenor = this.tenor != "" ? this.tenor : null;
    this.formProspect.prospectResult.installment = this.angsuran != "" ? this.angsuran : null;
    this.formProspect.prospectResult.dp = this.dp != "" ? this.dp : null;
    // console.log("ini angsuran", this.angsuran);
    this.apiUtil.invokeAPI("PROSPECT_SUBMIT", this.formProspect).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        this.router.navigate(['/homepage/prospect']);
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  hitSubmitSD(){
    this.formProspect.prospectResult.connectivity = this.connectivityKeySelected != "" ? this.connectivityKeySelected : null;
    this.formProspect.prospectResult.contactivity = this.contactivityKeySelected != "" ? this.contactivityKeySelected : null; 
    this.formProspect.prospectResult.probingAction = this.probingActionKeySelected != "" ? this.probingActionKeySelected : null;

    this.formProspect.prospectResult.result = this.resultKeySelected != "" ? this.resultKeySelected : null;
    this.formProspect.prospectResult.interestedStatus = this.interestedStatusKeySelected != "" ? this.interestedStatusKeySelected : null;
    this.formProspect.prospectResult.resultReason = this.resultReasonKeySelected != "" ? this.resultReasonKeySelected : null;
    if(this.promiseDate && this.promiseDate != undefined){
      let yearString = this.promiseDate.year;
      let monthString = this.promiseDate.month.toString().length == 1 ? "0" + this.promiseDate.month : this.promiseDate.month;
      let dayString =  this.promiseDate.day.toString().length == 1 ? "0" + this.promiseDate.day : this.promiseDate.day;
      this.promiseDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.promiseDate = this.promiseDateString ? this.promiseDateString : null;
    }

    this.formProspect.prospectResult.nextRoleId = this.nextRoleIdSelected != "" ? this.nextRoleIdSelected : null;

    this.formProspect.prospectResult.description = this.description != "" ? this.description : null;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;

    this.apiUtil.invokeAPI("PROSPECT_SUBMIT", this.formProspect).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        this.router.navigate(['/homepage/prospect']);
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  hitSubmitNC(){
    this.formProspect.prospectResult.hasBeenCalled = this.hasBeenCalledKeySelected !=  "" ? this.hasBeenCalledKeySelected : null;
    this.formProspect.prospectResult.connectivity = this.connectivityKeySelected != "" ? this.connectivityKeySelected : null;
    this.formProspect.prospectResult.contactivity = this.contactivityKeySelected != "" ? this.contactivityKeySelected : null; 
    this.formProspect.prospectResult.probingAction = this.probingActionKeySelected != "" ? this.probingActionKeySelected : null;
    this.formProspect.prospectResult.result = this.resultKeySelected != "" ? this.resultKeySelected : null;
    this.formProspect.prospectResult.interestedStatus = this.interestedStatusKeySelected != "" ? this.interestedStatusKeySelected : null;
    this.formProspect.prospectResult.resultReason = this.resultReasonKeySelected != "" ? this.resultReasonKeySelected : null;

    if(this.promiseDate && this.promiseDate != undefined){
      let yearString = this.promiseDate.year;
      let monthString = this.promiseDate.month.toString().length == 1 ? "0" + this.promiseDate.month : this.promiseDate.month;
      let dayString =  this.promiseDate.day.toString().length == 1 ? "0" + this.promiseDate.day : this.promiseDate.day;
      this.promiseDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.promiseDate = this.promiseDateString ? this.promiseDateString : null;
    }

    this.formProspect.prospectResult.description = this.description != "" ? this.description : null;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;
    
    this.formProspect.prospectResult.paketNewCar = this.paketNewCarKeySelected != "" ? this.paketNewCarKeySelected : null;

    if(this.pickupDate && this.pickupDate != undefined){
      let yearString = this.pickupDate.year;
      let monthString = this.pickupDate.month.toString().length == 1 ? "0" + this.pickupDate.month : this.pickupDate.month;
      let dayString =  this.pickupDate.day.toString().length == 1 ? "0" + this.pickupDate.day : this.pickupDate.day;
      this.pickupDateString = yearString + "-" + monthString + "-" + dayString;
      this.formProspect.prospectResult.pickupDate = this.pickupDateString ? this.pickupDateString : null;
    }

    this.formProspect.prospectResult.branchId = this.branchCustIdSelected != "" ? this.branchCustIdSelected : null;
    this.formProspect.prospectResult.vehicleBrand = this.brandKeySelected != "" ? this.brandKeySelected : null;
    this.formProspect.prospectResult.vehicleModel = this.modelKeySelected != "" ? this.modelKeySelected : null;
    this.formProspect.prospectResult.vehicleType = this.typeKeySelected != "" ? this.typeKeySelected : null;
    this.formProspect.prospectResult.dealerId = this.branchDealerKeySelected != "" ? this.branchDealerKeySelected : null;
    this.formProspect.prospectResult.vehicleColor = this.carColor != "" ? this.carColor : null;
    this.formProspect.prospectResult.tenor = this.tenor != "" ? this.tenor : null;
    this.formProspect.prospectResult.installment = this.angsuran != "" ? this.angsuran : null;
    this.formProspect.prospectResult.dp = this.dp != "" ? this.dp : null;

    this.apiUtil.invokeAPI("PROSPECT_SUBMIT", this.formProspect).subscribe(res => {
      console.log(res);
      if(res.state.status == '200'){
        this.toastr.success(res.state.error);
        this.router.navigate(['/homepage/prospect']);
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  productName(prodId){
    switch (prodId) {
      case "SD":
        return "Siap Dana";
      case "NC":
        return "New Car";
      case "KI":
        return "Kinto";
      case "IU":
        return "Insurance Upgrade";
      case "IR": 
        return "Insurance Renewal";
      default:
        return prodId;
    }
  }

  typeName(typeId){
    switch (typeId) {
      case "UP":
        return "Upload";
      case "EN":
        return "Entry";
      case "DB":
        return "Database";
      default:
        return typeId;
    }
  }
}
