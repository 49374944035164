<div class="forgot-password container-fluid">
    <div class="row">
        <div class="col-sm-5 login-form-wrapper">
            <div class="brand-wrapper mb-80">
                <img src="../../assets/images/taf_logo.svg" alt="logo" class="logo">
            </div>
            <div class="col-sm-7 px-0 d-none logo-mobile">
                <img src="../../assets/images/tomi_toyota.svg" alt="login image">
            </div>
            <div class="login-wrapper my-auto">
                <h1 class="login-title text-blue text-bold">Reset Password</h1>
                <p class="font-roboto f-15r text-light-grey pb-20">Masukkan Email yang terdaftar</p>

                <form class="custom-input">
                    <div class="form-group">
                        <div class="input-addon">
                            <img src="../../assets/images/icons/input-username.svg">
                        </div>
                        <input type="text" [(ngModel)]="inputUserName" name="username" id="username" class="form-control" required="" placeholder="Username" autocomplete="off">
                    </div>
                    <div class="form-group">
                        <div class="input-addon">
                            <img src="../../assets/images/icons/email-icon.svg">
                        </div>
                        <input type="email" [(ngModel)]="inputUserEmail" name="email" id="email" class="form-control" placeholder="Email" autocomplete="off">
                    </div>
                    <div class="p-relative single-button">
                        <button type="button" (click)="forgotPassSubmit()" class="btn btn-block login-btn mt-40">Submit</button>
                        <img src="../../assets/images/button/Button-right-arrow.png" class="right-arrow" alt="right-arrow">
                    </div>
                    <button type="button" (click)="forgotPassCancel()" class="btn btn-block cancel-btn mt-20">Cancel</button>
                </form>
            
            </div>
        </div>
        <div class="col-sm-7 px-0 d-none d-sm-block login-img-wrapper">
            <img src="../../assets/images/tomi_toyota.svg" alt="login image" class="login-img h-100vh">
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>