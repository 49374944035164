<div class="nk-sidebar nk-sidebar-fixed is-light no-padding " data-content="sidebarMenu">
	<div class="nk-sidebar-element nk-sidebar-head">
		<div class="nk-menu-trigger desktop">
			<a class="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu">
				<img src="../../assets/images/icons/menu-right.svg" alt="menu"/>
            </a>
		</div>
		<div class="nk-sidebar-brand">
			<a [routerLink]="['/homepage/dashboard']" class="logo-link nk-sidebar-logo">
				<img class="w-80 menu-logo" src="../../assets/images/taf_logo.svg" alt="logo" />
            </a>
            <a class="nk-nav-toggle mobile nk-quick-nav-icon d-xl-none toggle-active" data-target="sidebarMenu">
                <img src="../../assets/images/icons/menu-right.png" alt="menu" class="menu-mobile open-menu"/>
            </a>
		</div>
	</div><!-- .nk-sidebar-element -->
	<div class="nk-sidebar-element">
		<div class="nk-sidebar-content">
			<div class="nk-sidebar-menu">
				<ul class="nk-menu">
                    <li *ngFor="let menu of menuList; let i = index;" class="nk-menu-item" [ngClass]="{ 'active': menuActive == menu.tag }">
                        <a class="nk-menu-link" [routerLink]="[menu.path]" (click)="addActive(menu.tag)">
                            <div class="menu-img-wrapper">
                                <img [src]="menu.icon.split(';')[0]" alt="ic-dashboard" class="icon"/>
                                <img [src]="menu.icon.split(';')[1]" alt="ic-dashboard" class="active-icon"/>
                            </div>    
							<span class="nk-menu-text">{{menu.label}}</span>
						</a>
					</li>
                    <!-- .nk-menu-item -->
					<!-- <li class="nk-menu-item" [ngClass]="{ 'active': menuActive.dashboard }">
                        <a class="nk-menu-link" [routerLink]="['/homepage/dashboard']" (click)="addActive('dashboard')">
                            <div class="menu-img-wrapper">
                                <img src="../../assets/images/icons/dashboard-icon.svg" alt="ic-dashboard" class="icon"/>
                                <img src="../../assets/images/icons/ic-dashboard-white.png" alt="ic-dashboard" class="active-icon"/>
                            </div>    
							<span class="nk-menu-text">Dashboard</span>
						</a>
					</li>
					<li class="nk-menu-item" [ngClass]="{ 'active': menuActive.prospect }">
                        <a class="nk-menu-link" [routerLink]="['/homepage/prospect']" (click)="addActive('prospect')">
                            <div class="menu-img-wrapper">
                                <img src="../../assets/images/icons/prospect-blue.png" alt="ic-prospect" class="icon"/>
                                <img src="../../assets/images/icons/ic-prospect-white.png" alt="ic-prospect" class="active-icon"/>
                            </div> 
							<span class="nk-menu-text">Prospect</span>
						</a>
					</li>
					<li class="nk-menu-item" [ngClass]="{ 'active': menuActive.customerHandling }">
                        <a class="nk-menu-link" [routerLink]="['/homepage/customer-handling']" (click)="addActive('customerHandling')">
                            <div class="menu-img-wrapper">
                                <img src="../../assets/images/icons/cust-handling-icon.svg" alt="ic-handling" class="icon"/>
                                <img src="../../assets/images/icons/ic-costumer-white.svg" alt="ic-prospect" class="active-icon"/>
                            </div> 

							<span class="nk-menu-text">Customer Handling</span>
						</a>
					</li> -->
                    <!-- .nk-menu-item -->
				</ul><!-- .nk-menu -->
			</div><!-- .nk-sidebar-menu -->
		</div><!-- .nk-sidebar-content -->
	</div><!-- .nk-sidebar-element -->
</div>

<div class="nk-wrap ">
    <div id="container-header" class="nk-header nk-header-fixed is-light">
        <div class="container-fluid">
            <div class="nk-header-wrap">
                <div class="nk-menu-trigger mobile d-xl-none ml-n1">
                    <a class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu">
                        <img src="../../assets/images/icons/menu-right.png" alt="menu" class="menu-mobile"/>
                    </a>
                </div>

                <!-- Kondisi Search aja -->
                <!-- <form class="p-relative form-search form-inline md-form form-sm">
                    <img src="../../assets/images/icons/ic-search.png" alt="ic-search" class="ic-search"/>
                    <input class="form-control form-control-sm search-custom" type="text" placeholder="Search" style="font-family:Arial, FontAwesome" aria-label="Search"
                      mdbInput>
                </form>
                 -->
                 <!-- Untuk Halaman Prospect -->
                <!-- <div id="prospect" class="row no-margin header-search">
                    <div class="btn-group row mr-30">
                        <div class="button button-active" (click)="showPhase(1)" [class.button-active]="activeButton === 1">
                              <span>
                                  All Agent
                              </span>
                        </div>  
                        <div class="button"  (click)="showPhase(2)" [class.button-active]="activeButton === 2">
                              <span>
                                  By Agent
                              </span>
                        </div>
                  </div>
                  <form class="p-relative form-search form-inline md-form form-sm">
                      <img src="../../assets/images/icons/ic-search.png" alt="ic-search" class="ic-search"/>
                      <input class="form-control form-control-sm search-custom small" type="text" placeholder="Name" aria-label="Search"
                        mdbInput>
                  </form>
                </div> -->

                <div class="nk-header-tools">
                    <ul class="nk-quick-nav">
                        <li class="dropdown user-dropdown">
                            <a href="#" class="dropdown-toggle mr-n1" data-toggle="dropdown">
                                <div class="user-toggle">
                                    <div class="user-avatar sm">
                                        <img src="../../assets/images/user.png" alt="img-user" />
                                    </div>
                                    <div class="user-info row">
                                        <div class="user-name mr-5">{{userInfo.user.name}} ({{userInfo.roleId}} - {{userInfo.branch.name}})</div>
                                        <img src="../../assets/images/icons/chevron-down.svg" alt="" />
                                    </div>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                    <div class="w-100 div-text-center">
                                        <div class="user-avatar-large">
                                            <img src="../../assets/images/user.png" alt="img-user">
                                         </div>
                                    </div>
                                   
                                     <div class="user-info text-larger text-center mt-20">
                                        <p class="no-margin">{{userInfo.user.name}}</p>
                                        <p class="text-blue no-margin">{{userInfo.roleId}}</p>
                                        <p class="text-grey text-large">
                                            {{userInfo.branch.name}}
                                        </p>
                                    </div>
                                </div>
                                <div class="dropdown-inner">
                                    <ul class="link-list">
                                        <li><a [routerLink]="['/homepage/change-password']">
                                            <img src="../../assets/images/icons/ic-change-pswd-blue.png" alt="ic-lock" class="img-15">
                                            <span class="ml-20">Change Password</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="dropdown-inner">
                                    <ul class="link-list">
                                        <li>
                                            <a href="" (click)="logoutSubmit()">
                                                <img src="../../assets/images/icons/ic-logout-blue.png" alt="ic-logout" class="img-15">
                                                <span class="ml-20">Sign out</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div><!-- .nk-header-wrap -->
        </div><!-- .container-fliud -->
    </div>
    <div class="router-container">
        <router-outlet (activate)="checkToken()"></router-outlet>
    </div>
</div>