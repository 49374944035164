import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard-sh',
  templateUrl: './dashboard-sh.component.html',
  styleUrls: ['./dashboard-sh.component.css']
})
export class DashboardShComponent implements OnInit {

  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  viewMode = 'tab1';

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  dtTriggerSO: Subject<any> = new Subject();
  dtTriggerSRO: Subject<any> = new Subject();
  dtTriggerSH: Subject<any> = new Subject();
  dtTrigger2SO: Subject<any> = new Subject();
  dtTrigger2SRO: Subject<any> = new Subject();
  dtTrigger2SH: Subject<any> = new Subject();


  productList: any;
  dataProspects: any;

  dtOptionsSRO: any = {};
  dtOptions2SRO: any = {};
  dtOptionsSO: any = {};
  dtOptions2SO: any = {};
  dtOptionsSH: any = {};
  dtOptions2SH: any = {};
  productSelected: any;
  productKeySelected: any = "";

  filterDate: any = "";
  filterDateDailyString: any = "";
  filterDateMonthlyString: any = "";
  dataDashboard: any;
  dataDashboardPending: any;

  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  dashboardSHRole: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.dashboardSHRole = this.userInfo.roleId;

    if(this.dashboardSHRole == "SH"){
      this.getListProducts();
    }

    this.productKeySelected = 'SD';
    
    let dateToday = moment().format('YYYY-MM-DD');
    let dateMonth = moment().format('YYYY/M');
    this.filterDate = "";
    this.filterDateDailyString = "";
    this.filterDateMonthlyString = "";

    //DAILY
    if((this.dashboardSHRole == 'SRO')){
      this.dtOptionsSRO = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "source",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInprogressCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }
    } 
    else if (this.dashboardSHRole == 'SO'){
      this.dtOptionsSO = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "source",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInprogressCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }
    }
  
    //MONTHLY
    if(this.dashboardSHRole == 'SRO'){
      this.dtOptions2SRO = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "source",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInprogressCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }
    } 
    else if (this.dashboardSHRole == 'SO'){
      this.dtOptions2SO = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "source",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInprogressCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }
    }
    //SH
    else if (this.dashboardSHRole == 'SH') {
      // this.productOnChange("SD", "Siap Dana");  
      this.dtOptionsSH = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateToday,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "source",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInprogressCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }  
      
      this.dtOptions2SH = {
        ajax: {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateMonth,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
          data: function(data){
            return data;
          }
        },
        columns: [
          {
            data: "userId",
            render: function(data){
              return data == '-' ? null : data; 
            }
          },
          {
            data: "source",
            render: function(data){
              return data == null ? '-' : data; 
            }
          },  
          {
            data: "dbCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInprogressCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNfuCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }, 
          {
            data: "resultNotInterestedCount",
            render: function(data){
              return data == null ? '-' : data; 
            }
          }
        ],
        columnDefs: [
          { className: "bg-darkerblue text-white", targets: 1 },
          { className: "bg-navy text-white", targets: 2 }
        ]
      }   
    }

  }

  getListProducts(){
    let tmpRequest = {
      currentUserId: this.userInfo.userId
    }

    this.apiUtil.invokeAPI("SH_PRODUCT", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.productList = res.data;
        // console.log(this.productList);
        this.productKeySelected = this.productList[0].key;
        this.productSelected = this.productList[0].value;

        this.productOnChange(this.productKeySelected, this.productSelected);

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
  }


  productOnChange(key, value) {
    this.productSelected = value;
    this.productKeySelected = key;
    let dateToday = moment().format('YYYY-MM-DD');
    let dateMonth = moment().format('YYYY/M');

    // //Filtering for product SD
    let optionPromiseSH = new Promise((resolve, reject) => {
      this.dtOptionsSH.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateToday,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptions2SH.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateMonth,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      resolve("success");
    });

    //Finish Promise render datatables again
    optionPromiseSH.then(() => {
      this.rerenderSH();
    });

    // //END

    // START Map Pending Product Selected
    let tmpRequest = {
      userId: this.userInfo.userId,
      product: this.productKeySelected,
      dueDate: dateToday
    }

    this.apiUtil.invokeAPI("DASHBOARD_SH_DAILY_PENDING", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.dataDashboardPending = res.data[0];
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
    // END
  }

  filterDashboard(){
    //Read Calendar
    let yearString = this.filterDate.year;
    let monthString = this.filterDate.month.toString().length == 1 ? "0" + this.filterDate.month : this.filterDate.month;
    let dayString =  this.filterDate.day.toString().length == 1 ? "0" + this.filterDate.day : this.filterDate.day;
    this.filterDateDailyString = yearString + "-" + monthString + "-" + dayString;
    this.filterDateMonthlyString = yearString + "/" + monthString;
    //End
    //Promise Set URL New Ajax

      let optionPromiseSH = new Promise((resolve, reject) => {
        this.dtOptionsSH.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + this.filterDateDailyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token }
        }

        this.dtOptions2SH.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + this.filterDateMonthlyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
        }

        resolve("success");
      });
      //End
  
      //Finish Promise render datatables again
      optionPromiseSH.then(() => {
        this.rerenderSH
        ();
      });

      let optionPromiseSO = new Promise((resolve, reject) => {
        this.dtOptionsSO.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token }
        }

        this.dtOptions2SO.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
        }

        resolve("success");
      });
      //End
  
      //Finish Promise render datatables again
      optionPromiseSO.then(() => {
        this.rerenderSO();
      });

      let optionPromiseSRO = new Promise((resolve, reject) => {
        this.dtOptionsSRO.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateDailyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token }
        }

        this.dtOptions2SRO.ajax = {
          url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardSHRole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.filterDateMonthlyString,
          type: "GET",
          headers: { 'Authorization': "Bearer " + this.userData.access_token },
        }

        resolve("success");
      });
      //End
  
      //Finish Promise render datatables again
      optionPromiseSRO.then(() => {
        this.rerenderSRO();
      });
  }

  clearFilter(){
    this.filterDate = "";
    this.filterDateDailyString = "";
    this.filterDateMonthlyString = "";
    window.location.reload();
    this.spinner.show();

    let tmpRequest = {
      size: '10',
      currentUserId: this.userInfo.userId
    }

    this.apiUtil.invokeAPI("DASHBOARD_"+ this.userInfo.roleId +"_LIST", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        if(res.data.length != 0){
          this.dataDashboard = res.data.content;
        }
        else{
          this.dataDashboard = [];
        }
        
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    this.dtTriggerSO.next();
    this.dtTriggerSRO.next();
    this.dtTriggerSH.next();
    this.dtTrigger2SO.next();
    this.dtTrigger2SRO.next();
    this.dtTrigger2SH.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerSO.unsubscribe();
    this.dtTriggerSRO.unsubscribe();
    this.dtTriggerSH.unsubscribe();
    this.dtTrigger2SO.unsubscribe();
    this.dtTrigger2SRO.unsubscribe();
    this.dtTrigger2SH.unsubscribe();
  }

  rerenderSH(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
      });
    });
    this.dtTriggerSH.next();
    this.dtTrigger2SH.next();
  }

  rerenderSO(): void {
    this.dtTriggerSO.next();
    this.dtTrigger2SO.next();
  }

  rerenderSRO(): void {
    this.dtTriggerSRO.next();
    this.dtTrigger2SRO.next();
  }
}

