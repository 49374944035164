<main class="prospect-detail">
    <div class="tab-slider--nav p-relative mt-40">
      <ul class="nav nav-tabs">
        <li class="nav-item" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Informasi Pelanggan</li>
        <li class="nav-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Informasi Produk</li>
        <li class="nav-item" [class.active]="viewMode == 'tab3'" rel="tab3" (click)="viewMode ='tab3';populateData();">Result</li>
      </ul>
    </div>
  
  <div class="tab-slider--container" [ngSwitch]="viewMode">
      <div id="tab1" class="" *ngSwitchCase="'tab1'">
        <div class="white-container p-20">
          <div class="div-text-right">
            <p class="text-red text-large" *ngIf="compareDate(dataProspectDetail?.prospectResult.dtmSla)">
              <em class="fas fa-circle"></em>
              Over SLA
            </p>     
            <p class="text-green text-large" *ngIf="!compareDate(dataProspectDetail?.prospectResult.dtmSla)">
              <em class="fas fa-circle"></em>
              Under SLA
            </p>  
          </div>
          <div class="row no-margin">
              <div class="col-lg-6 left-side">
                  <p class="text-grey text-large">
                    Source Data
                  </p>
                  <p class="text-large text-darkergrey">
                    {{typeName(dataProspectDetail?.type)}}
                  </p>
              </div>
              <div class="col-lg-6 right-side">
                  <p class="text-grey text-large">
                   Tanggal Turun Data
                  </p>
                  <p class="text-large text-darkergrey">
                    {{dataProspectDetail?.prospectDetail.dtmProspect ? (dataProspectDetail?.prospectDetail.dtmProspect | date: 'yyyy-MM-dd HH:mm:ss') : '-' }}
                  </p>
              </div>
          </div>
  
          <div class="row no-margin">
            <div class="col-lg-6 left-side">
                <p class="text-grey text-large">
                  Agent Name
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.agentUserId ? dataProspectDetail.prospectDetail.agentUserId : '-'}}
                </p>
            </div>
            <div class="col-lg-6 right-side">
                <p class="text-grey text-large">
                  POLICY_END_DT
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.dtmPolicyEnd ? (dataProspectDetail?.prospectDetail.dtmPolicyEnd | date: 'yyyy-MM-dd') : '-'}}
                </p>
              
            </div>
        </div>
  
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Go Live Date
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.dtmGolive ? (dataProspectDetail?.prospectDetail.dtmGolive | date: 'yyyy-MM-dd') : '-'}}
              </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Period of
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.periodOf ? dataProspectDetail.prospectDetail.periodOf : '-'}}
              </p>
            </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Policy No
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.aab2000PolicyNo ? dataProspectDetail.prospectDetail.aab2000PolicyNo : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Contract Number
              </p>
              <p class="text-large text-darkergrey pointer">
                {{dataProspectDetail?.prospectDetail.custAgreementNo ? dataProspectDetail?.prospectDetail.custAgreementNo : '-'}}
                <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editContractNum()" *ngIf="userInfo.roleId == 'TM'">
              </p>
              <div class="editContractNum row div-text-center d-none" >
                  <div class="col-lg-6 pl-0">
                    <form>
                      <div class="form-group">
                        <input (keypress)="numberOnly($event)" type="text" class="form-control" [(ngModel)]="inputContractNumber" id="inputContractNumber" name="inputContractNumber" style="height: 40px;" aria-describedby="contractNum" placeholder="Contract Number">
                      </div>
                    </form>
                  </div>
                  <div class="row col-lg-6 no-padding space-between">
                    <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                    <button type="submit" class="btn btn-primary col-lg-5" (click)="changeContractNo()">Submit</button>
                  </div>
              </div>
          </div>
        </div>
  
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Customer Name
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.custName ? dataProspectDetail.prospectDetail.custName : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Mobile Phone 1
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.custMobileNo1 ? dataProspectDetail.prospectDetail.custMobileNo1 : '-'}}
              </p>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Mobile Phone 2
              </p>
              <p class="text-large text-darkergrey pointer">
                {{dataProspectDetail?.prospectDetail.custMobileNo2 ? dataProspectDetail.prospectDetail.custMobileNo2 : '-'}}
                <img src="../../../assets/images/icons/ic-edit.svg" alt="ic-edit" class="pl-5" (click)="editPhone()" *ngIf="userInfo.roleId == 'TM'">
              </p>
              <div class="editPhone row div-text-center d-none" >
                <div class="col-lg-6 pl-0 pt-10">
                  <form>
                    <div class="form-group">
                      <input (keypress)="numberOnly($event)" type="text" class="form-control" [(ngModel)]="inputMobileNo2" id="inputMobileNo2" name="inputMobileNo2" style="height: 40px;" aria-describedby="phoneNum" placeholder="Mobile Phone Number">
                      <h8 class="text-muted div-text-left">Format : 0xxxxxxx</h8>
                    </div>
                  </form>
                </div>
                <div class="row col-lg-6 no-padding space-between">
                  <button type="submit" class="btn btn-cancel btn-secondary col-lg-5">Cancel</button>
                  <button type="submit" class="btn btn-primary col-lg-5" (click)="checkMobileNo2()">Submit</button>
                </div>
              </div>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Email
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.custEmail ? dataProspectDetail.prospectDetail.custEmail : '-'}}
              </p>
          </div>
        </div>
  
        <div class="full-row">
          <p class="text-grey text-large">
            Address
          </p>
          <p class="text-large text-darkergrey">
            {{dataProspectDetail?.prospectDetail.custHomeAddress ? dataProspectDetail.prospectDetail.custHomeAddress : '-'}}
          </p>
        </div>
  
        </div>
          <div class="btn-custom p-relative mr-5 mt-40">
            <img src="../../../assets/images/button/Button-right-arrow.svg" alt="btn-next" class="logo-btn right img-30"/>
            <button class="btn btn-purple btn-next div-text-center mb-40" type="button" (click)="viewMode ='tab2'">Next</button>
          </div>
      </div>

      <div id="tab2" class="mt-40" *ngSwitchCase="'tab2'">
        <div class="white-container p-20">
          <div class="div-text-right">
            <p class="text-red text-large" *ngIf="compareDate(dataProspectDetail?.prospectResult.dtmSla)">
              <em class="fas fa-circle"></em>
              Over SLA
            </p>     
            <p class="text-green text-large" *ngIf="!compareDate(dataProspectDetail?.prospectResult.dtmSla)">
              <em class="fas fa-circle"></em>
              Under SLA
            </p>  
          </div>
          <div class="row no-margin">
              <div class="col-lg-6 left-side">
                  <p class="text-grey text-large">
                    TAF Branch
                  </p>
                  <p class="text-large text-darkergrey">
                    {{dataProspectDetail?.prospectDetail.branch?.name ? dataProspectDetail.prospectDetail.branch?.name : '-'}}
                  </p>
              </div>
              <div class="col-lg-6 right-side">
                  <p class="text-grey text-large">
                    Model Kendaraan
                  </p>
                  <p class="text-large text-darkergrey">
                    {{dataProspectDetail?.prospectDetail.vehicleModel ? dataProspectDetail.prospectDetail.vehicleModel : '-'}}
                  </p>
              </div>
          </div>
  
          <div class="row no-margin">
            <div class="col-lg-6 left-side">
                <p class="text-grey text-large">
                  Nomor Polisi
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.vehicleLicensePlate ? dataProspectDetail.prospectDetail.vehicleLicensePlate : '-'}}
                </p>
            </div>
            <div class="col-lg-6 right-side">
                <p class="text-grey text-large">
                  START TLO 
                </p>
                <p class="text-large text-darkergrey">
                  {{dataProspectDetail?.prospectDetail.startTlo ? dataProspectDetail.prospectDetail.startTlo : '-'}}
                </p>
            </div>
        </div>
  
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Tenure 
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.tenor ? dataProspectDetail.prospectDetail.tenor : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                OTR 
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.otr ? dataProspectDetail.prospectDetail.otr : '-'}}
              </p>
          </div>
        </div>
         
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Premi Upgrade Compre 1thn
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.th1PremiUpgradeCompre ? dataProspectDetail.prospectDetail.th1PremiUpgradeCompre : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Premi Perluasan 1Thn
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.th1PremiPerluasan ? dataProspectDetail.prospectDetail.th1PremiPerluasan : '-'}}
              </p>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Premi TPL 1 thn (Rp 10juta)
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.th1PremiTpl ? dataProspectDetail.prospectDetail.th1PremiTpl : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Total Premi Upgrade 1 Thn
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.th1TotalPremiUpgrade ? dataProspectDetail.prospectDetail.th1TotalPremiUpgrade : '-'}}
              </p>
          </div>
        </div>

        
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Premi Upgrade Compre all tenor
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.allPremiUpgradeCompre ? dataProspectDetail.prospectDetail.allPremiUpgradeCompre : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Premi Perluasan all tenor 
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.allPremiPerluasan ? dataProspectDetail.prospectDetail.allPremiPerluasan : '-'}}
              </p>
          </div>
        </div>

        
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Premi TPL All tenor (Rp 10 juta)
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.allPremiTpl ? dataProspectDetail.prospectDetail.allPremiTpl : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Admin Fee
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.adminFee ? dataProspectDetail.prospectDetail.adminFee : '-'}}
              </p>
          </div>
        </div>

        
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Total Premi Upgrade all tenor
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.allTotalPremiUpgrade ? dataProspectDetail.prospectDetail.allTotalPremiUpgrade : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                No Virtual account
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.virtualAccNo ? dataProspectDetail.prospectDetail.virtualAccNo : '-'}}
              </p>
          </div>
        </div>

        
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Permata
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.permataNo ? dataProspectDetail.prospectDetail.permataNo : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Mandiri
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.mandiriNo ? dataProspectDetail.prospectDetail.mandiriNo : '-'}}
              </p>
          </div>
        </div>

        
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                BCA
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.bcaNo ? dataProspectDetail.prospectDetail.bcaNo : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Notes
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectDetail.notes1 ? dataProspectDetail.prospectDetail.notes1 : '-'}}
              </p>
          </div>
        </div>

        
        <div class="row no-margin">
          <div class="col-lg-6 left-side">
              <p class="text-grey text-large">
                Upload Date 
              </p>
              <p class="text-large text-darkergrey">
                {{dataProspectDetail?.prospectResult.dtmCrt ? (dataProspectDetail?.prospectResult.dtmCrt | date: 'yyyy-MM-dd HH:mm:ss') : '-'}}
              </p>
          </div>
          <div class="col-lg-6 right-side">
              <p class="text-grey text-large">
                Called by
              </p>
              <p class="text-large text-darkergrey">
                {{userInfo?.user.name ? userInfo?.user.name : '-'}}
              </p>
          </div>
        </div>
  
        </div>
  
        <div class="w-100 div-text-right mb-40">
            <div class="btn-custom-2 p-relative mr-5 mt-40">
              <img src="../../../assets/images/button/Button-left-arrow.png" alt="btn-next" class="logo-btn left img-30"/>
              <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab1'">Back</button>
            </div>
            <div class="btn-custom-2 p-relative mr-5 mt-40">
              <img src="../../../assets/images/button/Button-right-arrow.png" alt="btn-next" class="logo-btn right img-30"/>
              <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab3';populateData();">Next</button>
            </div>
        </div>
        
      </div>

      <div id="tab3" class="mb-40" *ngSwitchCase="'tab3'">
        <div class="white-container p-20 result-tab">
            <div class="row no-margin w-100">
              <div class="col-lg-3 col-sm-6 mt-10">
                  <!-- <div class="radio-button">
                    <div class="custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                    </div>  
                  </div> -->
                  <label class="f-14px" for="defaultUnchecked">Has been Called?</label>
              </div>
            <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{hasBeenCalledSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let lovItem of hasBeenCalledLov; let i = index;" (click)="hasBeenCalledOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                </div>
              </div>
            </div>    
          </div>
  
          <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1'">
              <div class="col-lg-3 col-sm-6 mt-10">
                  <!-- <div class="radio-button">
                    <div class="custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                    </div>  
                  </div> -->
                  <label class="f-14px" for="defaultUnchecked">Connectivity</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{connectivitySelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of connectivityLov; let i = index;" (click)="connectivityOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                  </div>
                </div>
            </div>
          </div>

          <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1'">
            <div class="col-lg-3 col-sm-6 mt-10">
                <!-- <div class="radio-button">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  </div>  
                </div> -->
                <label class="f-14px" for="defaultUnchecked">Contactivity </label>
            </div>
            <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{contactivitySelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let lovItem of contactivityLov; let i = index;" (click)="contactivityOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>                
                </div>
              </div>
            </div>
          </div>
  
          <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1'">
            <div class="col-lg-3 col-sm-6 mt-10">
                <!-- <div class="radio-button">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  </div>  
                </div> -->
                <label class="f-14px" for="defaultUnchecked">Probing Action</label>
            </div>
            <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{probingActionSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let lovItem of probingActionLov; let i = index;" (click)="probingActionOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                </div>
              </div>
            </div>
          </div>  
          <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1'">
            <div class="col-lg-3 col-sm-6 mt-10">
              <!-- <div class="radio-button">
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                </div>  
              </div> -->
              <label class="f-14px" for="defaultUnchecked">Result</label>
            </div>
            <div class="col-lg-9 col-sm-6 row">
              <div class="div-text-left w-100">
                <div ngbDropdown class="d-inline-block col-lg-8">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{resultSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of resultLov; let i = index;" (click)="resultOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
                </div>
                <form *ngIf="resultKeySelected == 'L009-2' || resultKeySelected == 'L010-3'" class="form-inline col-lg-4">
                  <div class="form-group custom-calendar">
                    <div class="input-group form-calendar">
                      <input class="form-control " [minDate]="minDatePicker" [(ngModel)]="promiseDate" placeholder="Appointment Date" name="dp" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off">
                      <i class="calendar-icon" (click)="d.toggle()"></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
  
          <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L010-4' || resultKeySelected == 'L009-3'">
            <div class="col-lg-3 col-sm-6 mt-10">
                <!-- <div class="radio-button">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  </div>  
                </div> -->
                <label class="f-14px" for="defaultUnchecked">Result Reason (Not Interested) </label>
            </div>
            <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{resultReasonSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let lovItem of resultReasonLov; let i = index;" (click)="resultReasonOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                </div>
              </div>
            </div>   
          </div>

          <div class="row no-margin w-100 pt-20" *ngIf="resultKeySelected == 'L009-1' || resultKeySelected == 'L010-1' || resultKeySelected == 'L010-2'">
            <div class="col-lg-3 col-sm-6 mt-10">
                <!-- <div class="radio-button">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  </div>  
                </div> -->
                <label class="f-14px" for="defaultUnchecked">Interested Status </label>
            </div>
            <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{interestedStatusSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let lovItem of interestedStatusLov; let i = index;" (click)="interestedStatusOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                </div>
              </div>
            </div>   
          </div>

          <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L009-3'">
            <div class="col-lg-3 col-sm-6 mt-10">
                <!-- <div class="radio-button">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  </div>  
                </div> -->
                <label class="f-14px" for="defaultUnchecked">Tenor Perubahan Asuransi</label>
            </div>
            <div class="col-lg-9 col-sm-6">
              <div ngbDropdown class="d-inline-block">
                <div class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{tenorPerubahanAsuransiSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem *ngFor="let lovItem of tenorPerubahanAsuransiLov; let i = index;" (click)="tenorPerubahanAsuransiOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                </div>
              </div>
            </div>   
          </div>

          <div class="row no-margin w-100 pt-20" *ngIf="hasBeenCalledKeySelected == 'L013-1' && connectivityKeySelected == 'L002-1' && contactivityKeySelected == 'L007-1' && resultKeySelected != 'L009-3'">
            <div class="col-lg-3 col-sm-6 mt-10">
                <!-- <div class="radio-button">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios" mdbInput>
                  </div>  
                </div> -->
                <label class="f-14px" for="defaultUnchecked">Skema Pembayaran</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div ngbDropdown class="d-inline-block">
                  <div class="p-relative dropdown">
                      <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle style="width: 300px;">{{skemaPembayaranSelected}}</button>
                      <em class="fas fa-caret-down down-dropdown"></em>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem *ngFor="let lovItem of skemaPembayaranLov; let i = index;" (click)="skemaPembayaranOnChange(lovItem.key, lovItem.value)">{{lovItem.value}}</button>
                  </div>
                </div>
                <!-- <div class="mt-20">
                  <div class="form-group">
                    <textarea class="form-control" id="description" name="description" [(ngModel)]="description" rows="2" placeholder="Description"></textarea>
                  </div>
                </div> -->
            </div>   
          </div>

          <div class="row no-margin w-100 pt-20">
            <div class="col-lg-3 col-sm-6 mt-10">
                <label class="text-large">Description</label>
            </div>
            <div class="col-lg-9 col-sm-6">
                <div class="form-group custom-input">
                    <textarea class="form-control" id="description" name="description" [(ngModel)]="description" rows="1" placeholder="Description"></textarea>
                </div>
            </div>    
          </div>

          <!-- Attachment -->
          <div class="row no-margin w-100 pt-20">        
            <div class="row no-margin w-100 pt-20 pb-40">
              <div class="col-lg-3 col-sm-6 mt-10 text-large">
                  <label>Attachment</label>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="wrapper-upload div-text-center" style="flex-wrap: wrap;">
                    <div>
                      <img [ngStyle]="{'width': (srcFileName != '') ? '70px' : 'auto'}" [src]="srcImgTemp" alt="drop-file"> 
                    </div>
                    <div class="mt-10 text-center">
                      <span>{{srcFileName}}</span>
                    </div>
                  </div>
                  <div class="mt-20">
                    <div class=" mt-20 p-relative">
                      <button class="btn btn-purple p-relative mobile-choose-file w-100" (click)="fileUpload.click()">Choose File
                          <img src="../../../assets/images/icons/ic-upload-blue.png" alt="btn-plus" class="ic-upload"/>
                          <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event)" style="display:none" accept="image/*,application/pdf">
                      </button>
                      <button class="btn btn-purple clear-img-btn mobile-clear" (click)="clearImgUpload()" [hidden]="(base64ImgString == '' || base64ImgString == null) && srcImgTemp == '../../../assets/images/drop-file.png'">Clear</button>
                    </div>
                    <div [hidden]="!showButtonShowFileExisting" class="btn-show-file">
                      <img src="../../../assets/images/icons/ic-download-blue.png" alt="btn-plus" class="ic-download"/>
                      <button (click)="openFileExisting()" class="btn btn-purple ml-30">Download File Existing</button>
                    </div>
                    <div [hidden]="!showButtonShowImageExisting" class="btn-show-file">
                      <img src="../../../assets/images/icons/ic-download-blue.png" alt="btn-plus" class="ic-download"/>
                      <button (click)="openImage()" class="btn btn-purple ml-30">Download File Existing</button>
                    </div>
                  </div>
                  <!-- <div class="btn-left mt-20">
                    <div class="btn-custom p-relative">
                      <img src="../../../assets/images/icons/ic-upload-blue.png" alt="btn-plus" class="logo-btn img-30"/>
                      <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event)" style="display:none" accept="image/*,application/pdf">
                      <button class="btn btn-purple ml-30" (click)="fileUpload.click()">Choose File</button>
                      <button class="btn btn-purple clear-img-btn" (click)="clearImgUpload()" [hidden]="(base64ImgString == '' || base64ImgString == null) && srcImgTemp == '../../../assets/images/drop-file.png'">Clear</button>
                    </div>
                    <div [hidden]="!showButtonShowFileExisting" [ngClass]="srcImgTemp != '../../../assets/images/drop-file.png' ? 'l-355' : '' " class="btn-show-file">
                      <button (click)="openFileExisting()" class="btn btn-purple ml-30">Download File Existing</button>
                    </div>
                    <div [hidden]="!showButtonShowImageExisting" [ngClass]="srcImgTemp != '../../../assets/images/drop-file.png' ? 'l-355' : '' " class="btn-show-file">
                      <button (click)="openImage()" class="btn btn-purple ml-30">Download File Existing</button>
                    </div>
                  </div> -->
              </div>    
            </div>
          </div>
        </div>
        <!-- Button -->
        <div class="w-100 div-text-right mb-40">
          <div class="btn-custom-2 p-relative mr-5 mt-40">
            <img src="../../../assets/images/button/Button-left-arrow.png" alt="btn-next" class="logo-btn left img-30"/>
            <button class="btn btn-purple btn-next div-text-center" type="button" (click)="viewMode ='tab2'">Back</button>
          </div>
          <div class="btn-custom-2 p-relative mr-5 mt-40" *ngIf="userInfo.roleId == 'TM'">
            <img src="../../../assets/images/icons/ic-upload-blue.png" alt="btn-plus" class="logo-btn left img-30"/>
            <button class="btn btn-purple btn-next" type="button" (click)="saveForm()">Save</button>
          </div>
          <div class="mr-5 mt-40" *ngIf="userInfo.roleId == 'TM'">
            <button class="btn btn-violet div-text-center" type="button" (click)="submitForm()">Submit</button>
          </div>
        </div>

     </div>
  </div>   
</main>
  