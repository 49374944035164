import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard-cso',
  templateUrl: './dashboard-cso.component.html',
  styleUrls: ['./dashboard-cso.component.css']
})
export class DashboardCsoComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  viewMode = 'tab1';

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  dtTriggerCSO: Subject<any> = new Subject();
  dtTrigger2CSO: Subject<any> = new Subject();


  productList: any;
  dataProspects: any;

  dtOptionsCSO: any = {};
  dtOptions2CSO: any = {};
  productSelected: any;
  productKeySelected: any = "";

  filterDate: any = "";
  filterDateDailyString: any = "";
  filterDateMonthlyString: any = "";
  dataDashboard: any;

  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  dashboardCSORole: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.dashboardCSORole = this.userInfo.roleId;

    this.productKeySelected = 'SD';

    this.getListProducts();
    
    let dateToday = moment().format('YYYY-MM-DD');
    let dateMonth = moment().format('YYYY/M');
    this.filterDate = "";
    this.filterDateDailyString = "";
    this.filterDateMonthlyString = "";

    this.dtOptionsCSO = {
      ajax: {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCSORole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateToday,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function(data){
          return data;
        }
      },
      columns: [
        {
          data: "userId",
          render: function(data){
            return data == '-' ? null : data; 
          }
        },
        {
          data: "source",
          render: function(data){
            return data == null ? '-' : data; 
          }
        },  
        {
          data: "dbCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }, 
        // {
        //   data: "resultInprogressCount",
        //   render: function(data){
        //     return data == null ? '-' : data; 
        //   }
        // }, 
        {
          data: "resultInterestedCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }, 
        {
          data: "resultNfuCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }, 
        {
          data: "resultNotInterestedCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }
      ],
      columnDefs: [
        { className: "bg-darkerblue text-white", targets: 1 },
        { className: "bg-navy text-white", targets: 2 }
      ]
    }  
    
    this.dtOptions2CSO = {
      ajax: {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCSORole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateMonth,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function(data){
          return data;
        }
      },
      columns: [
        {
          data: "userId",
          render: function(data){
            return data == '-' ? null : data; 
          }
        },
        {
          data: "source",
          render: function(data){
            return data == null ? '-' : data; 
          }
        },  
        {
          data: "dbCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }, 
        // {
        //   data: "resultInprogressCount",
        //   render: function(data){
        //     return data == null ? '-' : data; 
        //   }
        // }, 
        {
          data: "resultInterestedCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }, 
        {
          data: "resultNfuCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }, 
        {
          data: "resultNotInterestedCount",
          render: function(data){
            return data == null ? '-' : data; 
          }
        }
      ],
      columnDefs: [
        { className: "bg-darkerblue text-white", targets: 1 },
        { className: "bg-navy text-white", targets: 2 }
      ]
    }   

  }

  getListProducts(){
    let tmpRequest = {
      currentUserId: this.userInfo.userId
    }

    this.apiUtil.invokeAPI("SH_PRODUCT", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.productList = res.data;
        // console.log(this.productList);
        this.productKeySelected = this.productList[0].key;
        this.productSelected = this.productList[0].value;

        this.productOnChange(this.productKeySelected, this.productSelected);

        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
  }


  productOnChange(key, value) {
    this.productSelected = value;
    this.productKeySelected = key;
    let dateToday = moment().format('YYYY-MM-DD');
    let dateMonth = moment().format('YYYY/M');

    // //Filtering for product SD
    let optionPromiseCSO = new Promise((resolve, reject) => {
      this.dtOptionsCSO.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCSORole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateToday,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptions2CSO.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCSORole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + dateMonth,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      resolve("success");
    });

    //Finish Promise render datatables again
    optionPromiseCSO.then(() => {
      this.rerenderSEH();
    });

    // //END
  }

  filterDashboard(){
    //Read Calendar
    let yearString = this.filterDate.year;
    let monthString = this.filterDate.month.toString().length == 1 ? "0" + this.filterDate.month : this.filterDate.month;
    let dayString =  this.filterDate.day.toString().length == 1 ? "0" + this.filterDate.day : this.filterDate.day;
    this.filterDateDailyString = yearString + "-" + monthString + "-" + dayString;
    this.filterDateMonthlyString = yearString + "/" + monthString;
    //End

    //Promise Set URL New Ajax
    let optionPromiseCSO = new Promise((resolve, reject) => {
      this.dtOptionsCSO.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCSORole + "_DAILY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + this.filterDateDailyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token }
      }

      this.dtOptions2CSO.ajax = {
        url: this.apiConfig.API_URL + this.apiConfig.API_DEFINITION["DASHBOARD_" + this.dashboardCSORole + "_MONTHLY"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected + "/" + this.filterDateMonthlyString,
        type: "GET",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
      }

      resolve("success");
    });
    //End

    //Finish Promise render datatables again
    optionPromiseCSO.then(() => {
      this.rerenderSEH();
    });
  }

  clearFilter(){
    window.location.reload();
  }

  ngAfterViewInit() {
    this.dtTriggerCSO.next();
    this.dtTrigger2CSO.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerCSO.unsubscribe();
    this.dtTrigger2CSO.unsubscribe();
  }

  rerenderSEH(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
      });
    });
    this.dtTriggerCSO.next();
    this.dtTrigger2CSO.next();
  }

}
