import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Idle } from 'idlejs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'taf-crm-fe';

  constructor(
    private router: Router
  ){
    const idle = new Idle()
    .whenNotInteractive()
    .within(15)
    .do(
      () => {
        console.log("idle event 15 minutes triggered");
        localStorage.clear();
				this.router.navigate(['/']);
      }
    ).start();
  }

}
