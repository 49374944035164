import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ApiConfig {
	constructor() {
		// console.log("Loading Api Config...")
	}

	// API_URL = "http://localhost:7090/connector";
	// API_URL_PAGE = "http://localhost:7090/connectorpage";
	// API_URL_LOGIN = "http://localhost:7090";
	// API_URL = "https://claris.taf.co.id/crmsales-cms/connector";
	// API_URL_PAGE = "https://claris.taf.co.id/crmsales-cms/connectorpage";
	// API_URL_LOGIN = "https://claris.taf.co.id/crmsales-cms";
	// API_URL = "https://claris-uat.taf.co.id/crmsales-cms/connector";
	// API_URL_PAGE = "https://claris-uat.taf.co.id/crmsales-cms/connectorpage";
	// API_URL_LOGIN = "https://claris-uat.taf.co.id/crmsales-cms";
	// API_URL = "https://claris-qa.taf.co.id/crmsales-cms/connector";
	// API_URL_PAGE = "https://claris-qa.taf.co.id/crmsales-cms/connectorpage";
	// API_URL_LOGIN = "https://claris-qa.taf.co.id/crmsales-cms";
	// API_URL = "http://34.34.221.117:9292/crmsales-cms/connector";
	// API_URL_PAGE = "http://34.34.221.117:9292/crmsales-cms/connectorpage";
	// API_URL_LOGIN = "http://34.34.221.117:9292/crmsales-cms";
	// API_URL = "https://claris-cms-uat.taf.co.id/crmsales-cms/connector";
	// API_URL_PAGE = "https://claris-cms-uat.taf.co.id/crmsales-cms/connectorpage";
	// API_URL_LOGIN = "https://claris-cms-uat.taf.co.id/crmsales-cms";
	API_URL = "https://claris-cms.taf.co.id/crmsales-cms/connector";
	API_URL_PAGE = "https://claris-cms.taf.co.id/crmsales-cms/connectorpage";
	API_URL_LOGIN = "https://claris-cms.taf.co.id/crmsales-cms";
	API_CRM = "/v1/crmsales/crm/api";


	API_DEFINITION = {
		LOGIN : {
			METHOD 		: "POST",
			ENDPOINT 	: "/logincrmfe",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		RESEND_OTP_USER : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/login/resendOtp",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		SUBMIT_OTP_USER : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/login/verifyOtp",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		GET_ROLE_USER : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/login/getRoleUser",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		GET_ROLE_MENU : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/login/getRoleMenu",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TM_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tm/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TM_DAILY_STATUS : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tm/dailystatus",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TM_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tm/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TM_MONTHLY_STATUS : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tm/monthlystatus",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TL_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tl/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TL_DAILY_STATUS : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tl/dailystatus",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TL_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tl/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TL_MONTHLY_STATUS : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tl/monthlystatus",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_TL_PERFORMANCE : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/tl/performance",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SPV_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/spv/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SPV_DAILY_STATUS : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/spv/dailystatus",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SPV_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/spv/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SPV_MONTHLY_STATUS : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/spv/monthlystatus",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SPV_PERFORMANCE : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/spv/performance",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_CDE_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/cde/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_CDE_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/cde/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_CDETL_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/cdetl/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_CDETL_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/cdetl/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SH_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/sh/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SH_DAILY_PENDING : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/sh/dailypending",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SH_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/sh/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SO_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/so/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SO_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/so/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SRO_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/sro/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SRO_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/sro/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_CSO_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/cso/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_CSO_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/cso/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SEH_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/seh/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_SEH_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/seh/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_BH_DAILY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/bh/daily",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		DASHBOARD_BH_MONTHLY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/dashboard/bh/monthly",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		FORGOT_PASSWORD : {
			METHOD 		: "POST",
			ENDPOINT 	: "/resetcrmfe",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		RESET_PASSWORD : {
			METHOD 		: "POST",
			ENDPOINT 	: "/loginchangecrm",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_TM_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/tm/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_CDE_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/cde/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SH_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/sh/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SH_REASSIGN_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/sh/prospect/reassignlist",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SEH_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/seh/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_BH_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/bh/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SRO_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/sro/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SO_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/so/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_CSO_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/cso/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_TCSO_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/tcso/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_JURO_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/juro/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_DERO_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/dero/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_AE_LIST : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/ae/prospect/list",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_TM_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_CDE_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/cde",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SHSO_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/so",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SHSRO_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/sro",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_CSO_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/cso",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_TCSO_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/tcso",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_JURO_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/juro",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_DERO_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/dero",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_AE_DETAIL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/ae",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		LIST_LOV : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/lov",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		CHANGE_PASSWORD : {
			METHOD 		: "POST",
			ENDPOINT 	: "/changecrmfe",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_TM_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/tm/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_TL_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/tl/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_SPV_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/spv/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_CDE_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/cde/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_SRO_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/sro/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_SO_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/so/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_SH_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/sh/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_CSO_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/cso/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_TCSO_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/tcso/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_JURO_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/juro/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_DERO_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/dero/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_AE_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/ae/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_SEH_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/seh/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_BH_LIST : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/bh/customerhandling/list",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		HANDLING_CUSTOMER_HISTORY : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/costomerhandling/handlinghistory",
			TYPE 		: "PATH_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/tm",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},		
		PROSPECT_UPDATE : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/prospect/update",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_CDE_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/cde",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SRO_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/sro",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_SO_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/so",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_CSO_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/cso",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_TCSO_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/tcso",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_JURO_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/juro",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_DERO_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/dero",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_AE_SUBMIT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectsubmit/ae",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		PROSPECT_CHECK : {
			METHOD	   : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/check",
			TYPE	   : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_ENTRY : {
			METHOD     : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/entry/tm",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_ENTRY_SO_SD : {
			METHOD     : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/entry/so",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_ENTRY_CSO : {
			METHOD     : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/entry/cso",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_ENTRY_TCSO : {
			METHOD     : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/entry/tcso",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_ENTRY_JURO : {
			METHOD     : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/entry/juro",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_ENTRY_DERO : {
			METHOD     : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/entry/dero",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_ENTRY_AE : {
			METHOD     : "POST",
			ENDPOINT   : this.API_CRM + "/prospectsubmit/entry/ae",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_BRANCHES : {
			METHOD     : "GET",
			ENDPOINT   : this.API_CRM + "/prospect/branches",
			TYPE       : "",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		PROSPECT_BRANCH_DEALER : {
			METHOD     : "GET",
			ENDPOINT   : this.API_CRM + "/prospect/branchdealer",
			TYPE       : "QUERY_PARAM",
			HEADER     : {
				"Content-Type" : "application/json"
			}
		},
		LIST_BRAND : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/vehicles/brand",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		LIST_MODEL : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/vehicles/model",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		LIST_TYPE : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/vehicles/type",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		CHILD_USER : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/users",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		CHILD_CDE_USER : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/cde/users",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		CHILD_SH_USER : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/sh/users",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		CHILD_SEH_USER : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/seh/users",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		CHILD_BH_USER : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/bh/users",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		SH_PRODUCT : {
			METHOD 		: "GET",
			ENDPOINT 	: this.API_CRM + "/prospect/userproducts",
			TYPE 		: "QUERY_PARAM",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		ASSIGN_PROSPECT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectassign/tm",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		ASSIGN_CDE_PROSPECT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectassign/cde",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		ASSIGN_CDESH_PROSPECT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectassign/cdesh",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		ASSIGN_SH_PROSPECT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectassign/sh",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		ASSIGN_REASSIGN_SH_PROSPECT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectassign/sh/reassign",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		ASSIGN_SEH_PROSPECT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectassign/seh",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		},
		ASSIGN_BH_PROSPECT : {
			METHOD 		: "POST",
			ENDPOINT 	: this.API_CRM + "/prospectassign/bh",
			TYPE 		: "",
			HEADER		: {
				"Content-Type": "application/json"
			}
		}
	}
}
