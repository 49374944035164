import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
import * as moment from 'moment';

@Component({
  selector: 'app-prospect-detail-sh-sro',
  templateUrl: './prospect-detail-sh-sro.component.html',
  styleUrls: ['./prospect-detail-sh-sro.component.css']
})
export class ProspectDetailShSroComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  viewMode = 'tab1';
  model: NgbDateStruct;
  idDetail: any;
  dataProspectDetail: any;
  dateToday: any;

  connectivityLov: any;
  contactivityLov: any;
  probingActionLov: any;
  resultLov: any;
  interestedStatusLov: any;
  resultReasonLov: any;

  connectivitySelected: any;
  connectivityKeySelected: any = "";
  connectivityReason: any = "";

  contactivitySelected: any;
  contactivityKeySelected: any = "";
  contactivityReason: any = "";

  probingActionSelected: any;
  probingActionKeySelected: any = "";

  resultSelected: any;
  resultKeySelected: any = "";
  interestedStatusSelected: any;
  interestedStatusKeySelected: any = "";
  resultReasonSelected: any;
  resultReasonKeySelected: any = "";

  promiseDate: NgbDateStruct;
  promiseDateString: any = "";

  nextRoleIdSelected: any = "";
  description: any = "";

  srcImgTemp: any = '../../../assets/images/drop-file.png';
  srcImgTempDetail: any = '../../../assets/images/drop-file.png';
  base64ImgString: any = "";
  srcFileName: any = "";

  custAppNo: any = "";
  custAppDate: any = "";

  isPopulated: any = 0;
  isPopulatedDetail: any = 0;

  showButtonShowFileExisting: any = false;
  showButtonShowFileExistingDetail: any = false;

  minDatePicker: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, 
    day: new Date().getDate()
  }

  ngOnInit(): void {
    this.spinner.show();
    this.idDetail = this.route.snapshot.params.id;

    let tmpRequest = {
      id: this.idDetail
    };

    this.apiUtil.invokeAPI("PROSPECT_SHSRO_DETAIL", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        // console.log("Prospect Detail", res);
        this.dataProspectDetail = res.data;

        this.spinner.hide();

        this.getLovConnectivity('L001');
        this.getLovContactivity('L006');
        this.getLovResult('L009');
        this.getLovResultReason('L003');
        this.getLovProbingAction();
        this.getLovInterestedStatus("L011");
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getLovConnectivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.connectivityLov = res.data;
        // this.connectivityKeySelected = this.connectivityLov[0].key;
        // this.connectivitySelected = this.connectivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovContactivity(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.contactivityLov = res.data;
        // this.contactivityKeySelected = this.contactivityLov[0].key;
        // this.contactivitySelected = this.contactivityLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovProbingAction(){
    this.spinner.show();

    let tmpRequest = {
      type: "L008"
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.probingActionLov = res.data;
        // this.probingActionKeySelected = this.probingActionLov[0].key;
        // this.probingActionSelected = this.probingActionLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResult(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultLov = res.data;
        // this.resultKeySelected = this.resultLov[0].key;
        // this.resultSelected = this.resultLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovInterestedStatus(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.interestedStatusLov = res.data;
        // this.interestedStatusKeySelected = this.interestedStatusLov[0].key;
        // this.interestedStatusSelected = this.interestedStatusLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  getLovResultReason(type){
    this.spinner.show();

    let tmpRequest = {
      type: type
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.resultReasonLov = res.data;
        // this.resultReasonKeySelected = this.resultReasonLov[0].key;
        // this.resultReasonSelected = this.resultReasonLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  connectivityOnChange(key, value){
    this.connectivityKeySelected = key;
    this.connectivitySelected = value;

    //Reset value if not connected
    if(key != 'L001-1' && key != 'L002-1'){
      this.contactivityKeySelected = null;
      this.contactivitySelected = null;

      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.promiseDate = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  contactivityOnChange(key, value){
    this.contactivityKeySelected = key
    this.contactivitySelected = value;

    //Reset value if not contacted
    if(key != 'L006-1' && key != 'L007-1'){
      this.probingActionKeySelected = null;
      this.probingActionSelected = null;

      this.resultKeySelected = null;
      this.resultSelected = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;

      this.promiseDate = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    //End
  }

  probingActionOnChange(key, value){
    this.probingActionKeySelected = key
    this.probingActionSelected = value;
  }

  nextRoleIdOnChange(value){
    this.nextRoleIdSelected = value;
  }

  resultOnChange(key, value){
    this.resultKeySelected = key
    this.resultSelected = value;

    if(key == "L010-4"){
      this.promiseDate = null;

      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.description = null;
      this.srcImgTemp = "../../../assets/images/drop-file.png";
    }
    else if(key == "L010-1" || key == "L010-2"){
      this.promiseDate = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;
    }
    else if(key == "L010-3"){
      this.interestedStatusKeySelected = null;
      this.interestedStatusSelected = null;

      this.resultReasonKeySelected = null;
      this.resultReasonSelected = null;
    }
  }

  resultReasonOnChange(key, value){
    this.resultReasonKeySelected = key
    this.resultReasonSelected = value;
  }

  interestedStatusOnChange(key, value){
    this.interestedStatusKeySelected = key
    this.interestedStatusSelected = value;
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.base64ImgString = reader.result;
      
      if(this.base64ImgString.includes("application/pdf")){
        this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
      }
      else{
        this.srcImgTemp = (this.domSanitizer.bypassSecurityTrustResourceUrl(this.base64ImgString) as any).changingThisBreaksApplicationSecurity;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
       console.log("No file selected!");
       return
    }
    let file: File = event.target.files[0];

    await this.getBase64(file);
  }

  compareDate(dateSla){
    let dateSlaRaw = new Date(dateSla);
    let dateSlaa = moment(dateSlaRaw).format('YYYY-MM-DD HH:mm:ss');

    let dateToday = moment().format('YYYY-MM-DD HH:mm:ss');

    if(dateSlaa < dateToday){
      return true;
    }
    else{
      return false;
    }
  }

  openFileExisting(){
    let base64String  = this.dataProspectDetail.prospectResult.attachment.split(',')[1];
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  openFileExistingDetail(){
    let base64String  = this.dataProspectDetail.prospectDetail.attachment.split(',')[1];
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  openImage(){
    let base64String  = this.dataProspectDetail.prospectDetail.attachment;
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
    downloadLink.href = linkSource;
    downloadLink.download = fileName + '.' + base64String.split('/')[1].split(';')[0];
    downloadLink.click();
  }

  populateData(){
    if(this.isPopulated == 0){
      console.log("populate data first");
      //Set Prospect Result if exist

      if(this.interestedStatusLov){
        this.interestedStatusKeySelected = this.dataProspectDetail.prospectResult.interestedStatus != null ? this.dataProspectDetail.prospectResult.interestedStatus : null;
        if(this.interestedStatusKeySelected != null){
          this.interestedStatusSelected = this.interestedStatusLov.find(el => el.key === this.interestedStatusKeySelected).value ? this.interestedStatusLov.find(el => el.key === this.interestedStatusKeySelected).value : null;
        }
        else{
          this.interestedStatusSelected = null;
        }
      }

      if(this.resultReasonLov){
        this.resultReasonKeySelected = this.dataProspectDetail.prospectResult.resultReason != null ? this.dataProspectDetail.prospectResult.resultReason : null;
        if(this.resultReasonKeySelected){
          this.resultReasonSelected = this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value ? this.resultReasonLov.find(el => el.key === this.resultReasonKeySelected).value : null;
        }
        else{
          this.resultReasonSelected = null;
        }
      }

      this.connectivityKeySelected = this.dataProspectDetail.prospectResult.connectivity != null ? this.dataProspectDetail.prospectResult.connectivity : null;
      if(this.connectivityKeySelected != null){
        this.connectivitySelected = this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value ? this.connectivityLov.find(el => el.key === this.connectivityKeySelected).value : null;
      }
      else{
        this.connectivitySelected = null;
      }
      this.connectivityReason = this.dataProspectDetail.prospectResult.connectivityReason != null ? this.dataProspectDetail.prospectResult.connectivityReason : "";

      this.contactivityKeySelected = this.dataProspectDetail.prospectResult.contactivity != null ? this.dataProspectDetail.prospectResult.contactivity : null;
      if(this.contactivityKeySelected != null){
        this.contactivitySelected = this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value ? this.contactivityLov.find(el => el.key === this.contactivityKeySelected).value : null;
      }
      else{
        this.contactivitySelected = null;
      }
      this.contactivityReason = this.dataProspectDetail.prospectResult.contactivityReason != null ? this.dataProspectDetail.prospectResult.contactivityReason : null;
      
      this.probingActionKeySelected = this.dataProspectDetail.prospectResult.probingAction != null ? this.dataProspectDetail.prospectResult.probingAction : null;
      if(this.probingActionKeySelected != null){
        this.probingActionSelected = this.probingActionLov.find(el => el.key === this.probingActionKeySelected).value ? this.probingActionLov.find(el => el.key === this.probingActionKeySelected).value : null;
      }
      else{
        this.probingActionSelected = null;
      }
      
      this.resultKeySelected = this.dataProspectDetail.prospectResult.result != null ? this.dataProspectDetail.prospectResult.result : null;
      if(this.resultKeySelected != null){
        this.resultSelected = this.resultLov.find(el => el.key === this.resultKeySelected).value ? this.resultLov.find(el => el.key === this.resultKeySelected).value : null;
      }
      else{
        this.resultSelected = null;
      }

      if(this.dataProspectDetail.prospectResult.promiseDate != null){
        //Assign value to ngbDate
        let dateCustomer = moment(this.dataProspectDetail.prospectResult.promiseDate, "YYYY-MM-DD").format("YYYY-M-D");
        let yearSt = Number(dateCustomer.split('-')[0]);
        let monthSt = Number(dateCustomer.split('-')[1]);
        let daySt = Number(dateCustomer.split('-')[2]);
        this.promiseDate = new NgbDate(yearSt, monthSt, daySt);
      }
      else{
        this.promiseDate = null;
      }

      this.custAppNo = this.dataProspectDetail.prospectResult.custAppNo != null ? this.dataProspectDetail.prospectResult.custAppNo : null;
      if(this.dataProspectDetail.prospectResult.custAppDate != null){
        let appDate = moment(this.dataProspectDetail.prospectResult.custAppDate, "YYYY-MM-DD").format("YYYY-M-D");
        let yearSt = Number(appDate.split('-')[0]);
        let monthSt = Number(appDate.split('-')[1]);
        let daySt = Number(appDate.split('-')[2]);
        this.custAppDate = new NgbDate(yearSt, monthSt, daySt);
      }
      else{
        this.custAppDate = null;
      }

      this.nextRoleIdSelected = this.dataProspectDetail.prospectResult.nextRoleIdTemp != null ? this.dataProspectDetail.prospectResult.nextRoleIdTemp : "CDE";

      this.description = this.dataProspectDetail.prospectResult.description != null ? this.dataProspectDetail.prospectResult.description : null;
      
      if(this.dataProspectDetail.prospectResult.attachment != null){
        if(this.dataProspectDetail.prospectResult.attachment.split(',')[0].includes("image")){
          this.srcImgTemp = this.dataProspectDetail.prospectResult.attachment != null ? this.dataProspectDetail.prospectResult.attachment : "../../../assets/images/drop-file.png"; 
        }
        else{
          this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
          this.showButtonShowFileExisting = true;
        }
      }
      else{
        this.srcImgTemp = "../../../assets/images/drop-file.png"; 
      }           
      //End

      this.isPopulated = 1;
    }
    else{
      
    }
  }

  populateDetail(){
    if(this.isPopulatedDetail == 0){
      //Populate Detail
      if(this.dataProspectDetail.prospectDetail.attachment != null){
        if(this.dataProspectDetail.prospectDetail.attachment.split(',')[0].includes("image")){
          this.srcImgTempDetail = this.dataProspectDetail.prospectDetail.attachment != null ? this.dataProspectDetail.prospectDetail.attachment : "../../../assets/images/drop-file.png"; 
        }
        else{
          this.srcImgTempDetail = "../../../assets/images/icons/prospect-blue.png";
          this.srcFileName = "ATTACHMENT_" + this.dataProspectDetail.branchId + "_" + this.dataProspectDetail.product + "_" + this.dataProspectDetail.id;
          this.showButtonShowFileExistingDetail = true;
        }
      }
      else{
        this.srcImgTempDetail = "../../../assets/images/drop-file.png"; 
      }  
      //End

      this.isPopulatedDetail = 1;
    }
    else{
      
    }
  }

  productName(prodId){
    switch (prodId) {
      case "SD":
        return "Siap Dana";
      case "NC":
        return "New Car";
      case "KI":
        return "Kinto";
      case "IU":
        return "Insurance Upgrade";
      case "IR": 
        return "Insurance Renewal";
      default:
        return prodId;
    }
  }

  typeName(typeId){
    switch (typeId) {
      case "UP":
        return "Upload";
      case "EN":
        return "Entry";
      case "DB":
        return "Database";
      default:
        return typeId;
    }
  }

}
