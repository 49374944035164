<main class="customer-handling">
    <div class="">
        <div class="row">
            <!-- <div ngbDropdown class="d-inline-block">
                <div  *ngIf="handlingRole == 'TM' && userInfo.isAsuransi == 'Y'" class="p-relative dropdown">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>{{insuranceProductNameSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="insuranceProductOnChange('IR', 'Insurance Renewal')">Insurance Renewal</button>
                <button ngbDropdownItem (click)="insuranceProductOnChange('IU', 'Insurance Upgrade')">Insurance Upgrade</button>
              </div>
            </div> -->
            
            <div id="tab1" class="table-custom custom-white tab-slider--body mt-40 w-100 pr-10 pl-10">
                <table datatable [dtOptions]="dtOptions" class="row-border hover rounded" id="myTable">
                    <thead class="head-custom">
                        <tr class="rounderleft rounderright header-blue">
                            <!-- <th class=" ">No</th> -->
                            <th class=" ">Nama Customer</th>
                            <th class=" ">Tanggal Turun Data</th>
                            <th class=" ">Cabang</th>
                            <th class=" ">Nama CSO</th>
                            <th class=" ">Last Follow Up Date</th>
                            <th class=" ">Prospect Status</th>
                            <th class=" ">Status</th>
                            <th class=" ">Detail</th>
                        </tr>
                      </thead>
                      <!-- <tbody>
                        <tr class="rounderrow">
                            <<td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class="text-underline" [routerLink]="['/homepage/customer-handling-detail']"><a class="text-blue">Detail</a></td>
                          </tr>
                      </tbody> -->
                </table> 
            </div>
        </div>
    </div>
</main>