import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router,RouterLink } from '@angular/router';
declare var $: any;
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Api } from 'src/app/utility/Api';
import { ApiConfig } from 'src/app/utility/ApiConfig';

@Component({
  selector: 'app-customer-handling-seh',
  templateUrl: './customer-handling-seh.component.html',
  styleUrls: ['./customer-handling-seh.component.css']
})
export class CustomerHandlingSehComponent implements OnInit {
  constructor(
    private calendar: NgbCalendar,
    private apiUtil: Api,
    private apiConfig: ApiConfig,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  viewMode = 'tab1';

  productList: any;

  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  dtTriggerSEH: Subject<any> = new Subject();
  dtOptions: any = {};

  productSelected: any;
  productKeySelected: any = "";
  product: any = "";

  model: NgbDateStruct;
  date: {year: number, month: number};
  userData = JSON.parse(localStorage.getItem("userData"));
  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  handlingRole: any;

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.handlingRole = this.userInfo.roleId;

    this.getListProducts(); 

    this.dtOptions = {
      processing: true,
	    serverSide: true,
      ajax: {
        url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+ this.handlingRole +"_LIST"].ENDPOINT + "/" + this.userInfo.userId + "/" + "SD",
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function (d) {
          return JSON.stringify(d);
        }
      },
      columns: [
        {
          data: "custName",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: "dtmCrt", 
          render: function(data){
            return moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "branchName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "userName",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "dtmLastFu",
          render: function(data){
            return data == null ? '-' : moment(data).format("DD-MMM-YYYY");
          }
        }, 
        {
          data: "custAgreementStatus",
          render: function(data){
            return data == null ? '-' : data;
          }
        }, 
        {
          data: "status",
          render: function(data){
            return data == null ? '-' : data;
          }
        },
        {
          data: null,
          render: function(data, type, row){
            return "<button type='button' class='btn btn-menus text-blue'><a href='/homepage/customer-handling-detail/" + data['id'] + "'><i class='mdi'></i>Detail</button>";
          }
        }
      ],
    }
  }

  getListProducts(){
    let tmpRequest = {
      currentUserId: this.userInfo.userId
    }
  
    this.apiUtil.invokeAPI("SH_PRODUCT", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.productList = res.data;
        // console.log(this.productList);
        this.productKeySelected = this.productList[0].key;
        this.productSelected = this.productList[0].value;

        this.productOnChange(this.productKeySelected, this.productSelected);
  
        this.spinner.hide();
      }
  
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
  }

  productOnChange(key, value) {
    this.productKeySelected = key;
    this.productSelected = value;
    
    // //Filtering for product SD
    let optionPromiseSEH = new Promise((resolve, reject) => {
      this.dtOptions.ajax = {
        url: this.apiConfig.API_URL_PAGE + this.apiConfig.API_DEFINITION["HANDLING_"+ this.handlingRole +"_LIST"].ENDPOINT + "/" + this.userInfo.userId + "/" + this.productKeySelected,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        headers: { 'Authorization': "Bearer " + this.userData.access_token },
        data: function (d) {
          return JSON.stringify(d);
        }
      };

      resolve("success");
    });

    //Finish Promise render datatables again
    optionPromiseSEH.then(() => {
      this.rerenderSEH();
    });

    // //END
  }

  ngAfterViewInit() {
    this.dtTriggerSEH.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerSEH.unsubscribe();
  }

  rerenderSEH(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
      });
    });
    this.dtTriggerSEH.next();
  }

}
