import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
declare function toggleBurgerMenu(): any;
declare function showNotifMenu(): any;
declare function showDocsMenu(): any;
declare function showSettingsMenu(): any;

@Component({
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
