import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeLayoutComponent } from './layout/home-layout.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginExpiredComponent } from './pages/login-expired/login-expired.component';
const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'login-expired', component: LoginExpiredComponent },
  {
    path: 'homepage',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/home-layout.module').then(m => m.HomeLayoutModule)
      }
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
