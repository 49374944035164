<div class="col-sm-5 login-form-wrapper">
    <div class="login-wrapper my-auto">

        <form class="custom-input">
            <div class="form-group">
                <div class="input-container">
                    <div class="input-addon">
                        <img src="../../assets/images/icons/input-pass.svg">
                    </div>
                    <input type="password" [(ngModel)]="inputOldPassword" id="oldPassword" name="oldPassword" class="form-control" placeholder="Old Password" autocomplete="off">
                    <button type="button" class="btn btn-eye" id="sh-oldPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="form-group">
                <div class="input-container">
                    <div class="input-addon">
                        <img src="../../assets/images/icons/input-pass.svg">
                    </div>
                    <input type="password" [(ngModel)]="inputPassword" id="newPassword" name="newPassword" class="form-control" placeholder="New Password" autocomplete="off" (keyup)="checkExecOld()">
                    <button type="button" class="btn btn-eye" id="sh-newPassword"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="form-group mb-4">
                <div class="input-container">
                    <div class="input-addon">
                        <img src="../../assets/images/icons/input-pass.svg">
                    </div>
                    <input type="password" [(ngModel)]="inputPasswordConfirm" id="newPasswordConfirm" name="newPasswordConfirm" class="form-control" placeholder="Confirm Password" autocomplete="off" (keyup)="checkExec()">
                    <button type="button" class="btn btn-eye" id="sh-newPasswordConfirm"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="form-group text-center m-t-20" *ngIf="errorFlag">
                <div class="col-xs-12">
                    <span class="text-danger">{{errorMsg}}</span>
               </div>
            </div>
            <div class="p-relative single-button">
                <button [disabled]="submitBtn" type="button" (click)="changePassSubmit()" class="btn btn-block login-btn mt-40">Submit</button>
                <img src="../../assets/images/button/Button-right-arrow.png" class="right-arrow" alt="right-arrow">
            </div>
        </form>
    
    </div>
</div>
<ngx-spinner></ngx-spinner>