<main class="container-fluid dashboard">   
    <div class="tab-slider--nav p-relative">
        <ul class="nav nav-tabs custom-tab">
          <li class="nav-item" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Daily Activity</li>
          <li class="nav-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Monthly Activity</li>
        </ul>
        <div class="dropdown-dashboard div-text-center">
            <!-- <div ngbDropdown class="d-inline-block mr-15" *ngIf="userInfo.roleId == 'SEH'">
                <div *ngIf="userInfo.roleId == 'SEH'" class="p-relative dropdown dropodown-product">
                    <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>{{productSelected}}</button>
                    <em class="fas fa-caret-down down-dropdown"></em>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem *ngFor="let product of productList; let i = index" (click)="productOnChange(product.key, product.value)">{{product.value}}</button>
                </div>
            </div> -->
          <div ngbDropdown class="d-inline-block dropdown-calendar">
            <form  class="form-inline col-lg-3 ">
              <div class="form-group custom-calendar">
                <div class="input-group form-calendar">
                  <input class="form-control " [(ngModel)]="filterDate" name="dp" placeholder="Assigned Date" ngbDatepicker #d="ngbDatepicker" (dateSelect)="filterDashboard()" autocomplete="off" (click)="d.toggle()">
                  <i class="calendar-icon" (click)="d.toggle()"></i>
                </div>
              </div>
            </form>
              <!-- <div class="p-relative dropdown">
                  <button class="btn-dropdown dropdown-toogle" id="dropdownBasic1" ngbDropdownToggle>19 Sept 2021</button>
                  <em class="fas fa-caret-down down-dropdown"></em>
              </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem>12 Sept 2021</button>
              <button ngbDropdownItem>08 Agst 2021</button>
            </div> -->
          </div>
            <div class="p-relative">
              <button class="btn btn-clear ml-10 pointer" (click)="clearFilter()" type="button">Clear Filter</button>
            </div>

        </div>
        
    </div>

    <div class="tab-slider--container">
        <div id="tab1" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab2'">
            <table datatable [dtOptions]="dtOptionsCSO" [dtTrigger]="dtTriggerCSO" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">CSO</th>                  
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY CSO</th>
                    </tr>
                    <tr>
                      <!-- <th class="br-white">IN PROGRESS</th> -->
                      <th class="br-white">INTERESTED</th>
                      <th class="bg-navy">NFU</th>
                      <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                    </tr>
                  </thead>
            </table>

        </div>

        <div id="tab2" class="table-custom tab-slider--body mt-40" [hidden]="viewMode == 'tab1'">

            <table datatable [dtOptions]="dtOptions2CSO" [dtTrigger]="dtTrigger2CSO" class="row-border hover rounded" style="width: 100% !important;" id="myTable">
                <thead class="head-custom">
                    <tr class="rounderleft">
                        <th class="br-white" rowspan="2">CSO</th>
                        <th class="br-white" rowspan="2">SOURCE</th>
                        <th class="br-white" rowspan="2">DATABASE</th>
                        <th class="bg-darkblue br-white bb-white" colspan="4">RESULT BY CSO</th>
                    </tr>
                    <tr>
                      <!-- <th class="br-white">IN PROGRESS</th> -->
                      <th class="br-white">INTERESTED</th>
                      <th class="bg-navy">NFU</th>
                      <th class="bg-darkerblue br-white">NOT INTERESTED</th>
                    </tr>
                  </thead>
            </table>
            
        </div>
    </div>
</main>
