import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from '../utility/Api';
import { ApiConfig } from '../utility/ApiConfig';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  screatKey = "claris";
  constructor(
    private router: Router,
    private apiUtil: Api,
    private config: ApiConfig,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private http: HttpClient   
  ) { }

  inputUser: any;
  inputPassword: any;
  userData: any;
  inputOtp: any;

  userInfo = JSON.parse(localStorage.getItem("userInfo"));
  httpOptions: any = {
    "Content-Type": "application/json"
  };

  closeResult = '';
  @ViewChild('contentOtp') contentOtp: ElementRef;

  ngOnInit() {

    $("#sh-password").on('click', function() {
      if ($("#password").prop("type") == "password") {
          $("#password").prop("type", "text");
          
          $(this).find('i').removeClass('fa-eye-slash');
          $(this).find('i').addClass('fa-eye');
      } else {
          $("#password").prop("type", "password");
          
          $(this).find('i').removeClass('fa-eye');
          $(this).find('i').addClass('fa-eye-slash');
      }
    });

    //Untuk Cek Token
    if(this.userInfo != null){
      let tmpRequest = {
        userId: this.userInfo.userId,
      };
  
      this.apiUtil.invokeAPI("GET_ROLE_USER", tmpRequest).subscribe(
        res => {
          if(res.state.status == '200'){
            this.router.navigate(['/homepage']);
          }
        }, 
        err => {
          if(err.status == '401'){
            this.router.navigate(['/']);
          }
          else{
            this.router.navigate(['/']);
          }
        }
      );
    }
    else{
			this.router.navigate(['/']);
		}
    //End

    // setTimeout(()=>{
    //   this.otpOpen();
    // }, 1500);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  alphanumericOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // console.log(charCode);
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode > 96 && charCode < 123)) {
      return true;
    }
    return false;
  }

  otpOpen(){
    this.modalService.open(this.contentOtp, {ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  loginSubmit(){
    this.spinner.show();
    
    let tmpRequest = {
		  username: this.encrypt(this.inputUser,this.screatKey),
      password: this.encrypt(this.inputPassword, this.screatKey)
    }

    this.apiUtil.invokeAPI("LOGIN", tmpRequest).subscribe(res => {
		  if(res.access_token != "" && res.access_token != null){
        this.userData = res;
        let userId = JSON.parse(window.atob(this.userData.access_token.split('.')[1]));

        // Get Menu First
        this.httpOptions["authorization"] = this.userData.token_type + " " + this.userData.access_token;
        let httpHeaders = new HttpHeaders(this.httpOptions);
        this.http.get(this.config.API_URL + this.config.API_DEFINITION.GET_ROLE_MENU.ENDPOINT + "?userId=" + userId.user_name,  
          { headers: httpHeaders }
        ).subscribe(
          res => {
            let resp: any = res;

            if(resp.state.status == '200'){
              if(resp.state.error == 'emptymenu'){
                this.toastr.error("Empty Menu Assigned");
                this.router.navigate(['/']);
                this.spinner.hide();
              }
              else{
                //Call Request OTP
                let tmpRequest2 = {
                  id: userId.user_name
                }

                this.http.post(this.config.API_URL + this.config.API_DEFINITION.RESEND_OTP_USER.ENDPOINT, 
                  tmpRequest2, 
                  { headers: httpHeaders }
                ).subscribe(
                  res => {
                    let resp: any = res;

                    if(resp.state.status == '200'){
                      this.spinner.hide();
                      this.toastr.success(resp.state.error)
                      this.otpOpen();
                    }
                    else{
                      console.log(resp);
                      this.toastr.error(resp.state.error);
                      this.spinner.hide();
                    }
                  },
                  (err) => {
                    console.log(err);
                    this.toastr.error(err);
                    this.spinner.hide();
                  }
                );
                //End Request OTP

                // //Pass through OTP
                // localStorage.setItem("userData", JSON.stringify(this.userData));
                // let tmpRequest2 = {
                //   userId: userId.user_name
                // };
    
                // this.apiUtil.invokeAPI("GET_ROLE_USER", tmpRequest2).subscribe(
                //   res => {
                //     if(res.state.status == '200'){
                //       localStorage.setItem("userInfo", JSON.stringify(res.data[0]));
                //       // this.toastr.success("Login Success");
                //       this.spinner.hide();
                //       this.modalService.dismissAll();
    
                //       if(res.data[0].roleId == "TCSO" || res.data[0].roleId == "JURO" || res.data[0].roleId == "DERO" || res.data[0].roleId == "AE"){
                //         this.router.navigate(['/homepage/prospect']);
                //       }
                //       else{
                //         this.router.navigate(['/homepage']);
                //       }
                      
                //     }
                //     else{
                //       console.log(res);
                //       this.toastr.error(res);
                //       this.spinner.hide();
                //     }
                //   }, 
                //   (err) => {
                //     console.log(err);
                //     this.toastr.error(err);
                //     this.spinner.hide();
                //   }
                // );
                // //End

              }

            }
          },
          (err) => {
            this.toastr.error("Get Menu Error " + err);
            this.router.navigate(['/']);
          }
        );
        // End
        
		  }
      else if(res.error_description == 'User credentials have expired'){
        this.spinner.hide();
        this.router.navigate(['/login-expired', tmpRequest]);
      }
      else{
        console.log(res.error_description);
        this.toastr.error("Username or Password is Incorrect");
        this.spinner.hide();
		  }
	  }, (err) => {
      console.log(err);
      this.toastr.error(err);
      this.spinner.hide();
	  });
  }

  resendOtp(){
    this.spinner.show();

    let userId = JSON.parse(window.atob(this.userData.access_token.split('.')[1]));
    let tmpRequest = {
      id: userId.user_name
    };

    this.httpOptions["authorization"] = this.userData.token_type + " " + this.userData.access_token;
    let httpHeaders = new HttpHeaders(this.httpOptions);
    this.http.post(this.config.API_URL + this.config.API_DEFINITION.RESEND_OTP_USER.ENDPOINT, 
      tmpRequest, 
      { headers: httpHeaders }
    ).subscribe(
      res => {
        let resp: any = res;
        if(resp.state.status == '200'){
          console.log(resp);
          this.spinner.hide();
          this.toastr.success(resp.state.error)
        }
        else{
          console.log(resp);
          this.toastr.error(resp.state.error);
          this.spinner.hide();
        }
      }, 
      (err) => {
        console.log(err);
        this.toastr.error(err);
        this.spinner.hide();
      }
    );
  }

  otpSubmit(){
    this.spinner.show();

    let userId = JSON.parse(window.atob(this.userData.access_token.split('.')[1]));
    let tmpRequest = {
      id: userId.user_name,
      otp: this.inputOtp
    };

    this.httpOptions["authorization"] = this.userData.token_type + " " + this.userData.access_token;
    let httpHeaders = new HttpHeaders(this.httpOptions);
    this.http.post(this.config.API_URL + this.config.API_DEFINITION.SUBMIT_OTP_USER.ENDPOINT, 
      tmpRequest, 
      { headers: httpHeaders }
    ).subscribe(
      res => {
        let resp: any = res;

        if(resp.state.status == 200){
          if(resp.state.error == "OK"){
            // this.toastr.success(resp.state.error);

            localStorage.setItem("userData", JSON.stringify(this.userData));
            let tmpRequest2 = {
              userId: userId.user_name
            };

            this.apiUtil.invokeAPI("GET_ROLE_USER", tmpRequest2).subscribe(
              res => {
                if(res.state.status == '200'){
                  localStorage.setItem("userInfo", JSON.stringify(res.data[0]));
                  // this.toastr.success("Login Success");
                  this.spinner.hide();
                  this.modalService.dismissAll();

                  if(res.data[0].roleId == "TCSO" || res.data[0].roleId == "JURO" || res.data[0].roleId == "DERO" || res.data[0].roleId == "AE"){
                    this.router.navigate(['/homepage/prospect']);
                  }
                  else{
                    this.router.navigate(['/homepage']);
                  }
                  
                }
                else{
                  console.log(res);
                  this.toastr.error(res);
                  this.spinner.hide();
                }
              }, 
              (err) => {
                console.log(err);
                this.toastr.error(err);
                this.spinner.hide();
              }
            );

          }
          else{
            this.toastr.error(resp.state.error);
          }
        }

        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.toastr.error(err);
        this.spinner.hide();
      }
    );
  }

  encrypt(value: string, key:string) {
    let enc = CryptoJS.AES.encrypt(value, key);
    let encc = enc.toString();
    console.log("ENCCC", encc);
    return encc;
  }

  decrypt(textToDecrypt: string, key: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, key).toString(CryptoJS.enc.Utf8);
  }

}
