import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';
declare var $: any;
@Component({
  selector: 'app-prospect-new-dero-sd',
  templateUrl: './prospect-new-dero-sd.component.html',
  styleUrls: ['./prospect-new-dero-sd.component.css']
})
export class ProspectNewDeroSdComponent implements OnInit {
  constructor(
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  userInfo = JSON.parse(localStorage.getItem("userInfo"));

  errorFlag: any = false;
  errorMsg: any;
  nonDataBaseCheck: any;
  typeLov: any;
  inputs = [];
  branchProspect : any;
  // nonDatabaseLov: any;
  resultExisting: any;
  resultExis: any;
  resultMobile : any;
  resultName : any;
  resultAddress : any;
  resultBranch : any;
  resultType : any;

  branchSelected: any;
  branchIdSelected: any = "";
  nonDatabaseSelected: any;
  nonDatabaseKeySelected: any = "";
  dataProspectCheck: any;
  verificationSelected : any;
  verificationKeySelected : any ="";
  existingTafSelected : any;
  existingTafKeySelected : any = "";
  custAppNoSelected: any;
  custAppNoKeySelected: any = "";
  typeSelected : any;
  typeKeySelected : any = "";
  textSelected : any;
  textKeySelected : any = "";
  idDetail: any;
  custName: any = "";
  custId: any = "";
  custNo: any = "";
  notes1: any = "";
  custAppNo: any;
  isExisting: any;
  vehicleLicensePlate: any = "";
  vehicleEngineNo: any = "";
  vehicleIdentificationNo: any = "";
  custPhoneNo: any = "";
  custType: any = "";
  custHomeAddress: any = "";
  branchName : any = "";
  name: any = "";
  product: any = "";
  // produsctSD: any = "SD";
  // productNC: any = "nc";
  custAgreementNo: any = "";
	custAgreementStatus: any = "";
  custMobileNo1: any = [];
	custMobileNo2: any = "";

  srcImgTemp: any = '../../../assets/images/drop-file.png';
  base64ImgString: any = "";
  srcFileName: any = "";

  vehicleLicensePlateReturned: any = "";
  vehicleEngineNoReturned: any = "";
  vehicleIdentificationNoReturned: any = "";

  formProspect : any = {
    id : "",
    userCrt : "",
    product : "",
    branchId : "",
    prospectResult : {
      attachment: ""
    },
    prospectDetail : {
      custId: "",
      custNo: "",
      custName: "",
      custAppNo: "",
      custAgreementNo: "",
	    custAgreementStatus: "",
      custType: "",
      custHomeAddress: "",
      notes1: "",
      custPhoneNo: "",
      custMobileNo1: "",
	    custMobileNo2: "",
      vehicleLicensePlate: "",
      vehicleEngineNo: "",
      vehicleIdentificationNo: "",
      isExisting: ""
      // branchDealer : {
      //   branch : {
      //     name: "",
      //   }
      // }
    }
  };
  formProspectCheck : any = {
    prospectDetail : {
        custId: "",
        custNo: "",
        custName: "",
        vehicleLicensePlate: "",
        vehicleEngineNo: "",
        vehicleIdentificationNo: "",
        isExisting: "",
        custAppNo: "",
        branchName: "",
    }
  };

  ngOnInit(): void {
    this.spinner.show();
    this.idDetail = this.route.snapshot.params.id;

    let tmpRequest = {
      id: this.idDetail
    };
    
    this.custAppNo = "";
    this.verificationSelected = "- Customer Verification -";
    this.existingTafSelected = "No";
    // this.typeSelected = "- Customer Type -";
    this.getLovNonDataBase();
    this.getBranchData();

    this.branchSelected = "Branch";
    this.branchIdSelected = "Branch";
    this.branchOnChange(this.userInfo.branch.id, this.userInfo.branch.name);

    let branchId = this.userInfo.branch.id;
    // let branchId = '0000';
    if(branchId == '0000'){
      setTimeout(function(){
        $("button.input-readOnly").removeClass('input-readOnly');
      }, 750);
    }
  }

  verificationOnChange(key, value){
    this.verificationSelected = value;
    this.verificationKeySelected = key;
  }

  existingTafOnChange(key, value) {
    this.existingTafSelected = value;
    this.existingTafKeySelected = key;
  }

  typeOnChange(key, value) {
    this.typeSelected = value;
    this.typeKeySelected = key;
  }
  branchOnChange(key, value){
    this.branchSelected = value;
    this.branchIdSelected = key;
  }

  getLovNonDataBase(){
    this.spinner.show();

    let tmpRequest = {
      type: "L012"
    };

    this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
      if(res.state.status == '200'){
        this.typeLov = res.data;
        console.log(this.typeLov);
        this.typeKeySelected = this.typeLov[0].key;
        this.typeSelected = this.typeLov[0].value;
        this.spinner.hide();
      }

    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
	  });

  }

  getBranchData(){
    this.spinner.show();

    let tmpRequest = {
    };

    this.apiUtil.invokeAPI("PROSPECT_BRANCHES", tmpRequest).subscribe(res => {
      if (res.state.status == '200'){
        this.branchProspect = res.data;
        // this.branchSelected = this.branchProspect[0].name;
        // this.branchIdSelected = this.branchProspect[0].id;
        this.spinner.hide();
      }
    },(err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
    
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.base64ImgString = reader.result;
      
      if(this.base64ImgString.includes("application/pdf")){
        this.srcImgTemp = "../../../assets/images/icons/prospect-blue.png";
        this.srcFileName = file.name;
      }
      else{
        this.srcImgTemp = (this.domSanitizer.bypassSecurityTrustResourceUrl(this.base64ImgString) as any).changingThisBreaksApplicationSecurity;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
       console.log("No file selected!");
       return
    }
    let file: File = event.target.files[0];

    if(file.size < (5000 * 1024)){
      await this.getBase64(file);
    }
    else{
      this.toastr.error("Attachment more than 5 MB");
    }
  }

  clearImgUpload(){
    this.srcImgTemp = "../../../assets/images/drop-file.png"; 
    this.base64ImgString = null;
    this.srcFileName = "";
  }

  change(obj: any, check: any){
    let evtCheck = false
    if(check?.path?.length) {
      //For old Google chrome
      evtCheck = check?.path[0]?.checked
    } else {
      //For newest
      evtCheck = check?.target.checked
    }
    if(evtCheck) {
      this.inputs.push(obj);
    }
    else{
      this.inputs = this.arrayRemove(this.inputs, obj);
    } 
  }

  arrayRemove(arr, value) {
    return arr.filter(function(ele){
        return ele != value;
    });
  }

  checkPhone1(){
    if(this.custMobileNo1 == this.custMobileNo2){
      this.errorFlag = true;
      this.errorMsg = "Phone cant not same";
    }
    else{
      this.errorFlag = false;      
    }
  }

  checkPhone2(){
    if(this.custMobileNo2 == this.custMobileNo1){
      this.errorFlag = true;
      this.errorMsg = "Phone cant not same";
    }
    else{
      this.errorFlag = false;      
    }
  }

  check(){
    this.spinner.show();

    if (this.verificationKeySelected == '2') {
      console.log(this.verificationKeySelected);
      this.formProspectCheck.prospectDetail.custId = null;
      this.formProspectCheck.prospectDetail.custNo = this.custNo != "" ? this.custNo : null;
      this.formProspectCheck.prospectDetail.vehicleLicensePlate = null;
      this.formProspectCheck.prospectDetail.vehicleEngineNo = null;
      this.formProspectCheck.prospectDetail.vehicleIdentificationNo = null;
    }
    else if (this.verificationKeySelected == '1') {
      console.log(this.verificationKeySelected);
      this.formProspectCheck.prospectDetail.custId = this.custId != "" ? this.custId : null;
      this.formProspectCheck.prospectDetail.custNo = null;
      this.formProspectCheck.prospectDetail.vehicleLicensePlate = null;
      this.formProspectCheck.prospectDetail.vehicleEngineNo = null;
      this.formProspectCheck.prospectDetail.vehicleIdentificationNo = null;
    }
    else if (this.verificationKeySelected == '3') {
      console.log(this.verificationKeySelected);
      this.formProspectCheck.prospectDetail.custId = null;
      this.formProspectCheck.prospectDetail.custNo = null;
      this.formProspectCheck.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlate != "" ? this.vehicleLicensePlate: null;
      this.formProspectCheck.prospectDetail.vehicleEngineNo = null;
      this.formProspectCheck.prospectDetail.vehicleIdentificationNo = null;
    }
    else if (this.verificationKeySelected == '4') {
      console.log(this.verificationKeySelected);
      this.formProspectCheck.prospectDetail.custId = null;
      this.formProspectCheck.prospectDetail.custNo = null;
      this.formProspectCheck.prospectDetail.vehicleLicensePlate = null;
      this.formProspectCheck.prospectDetail.vehicleEngineNo = this.vehicleEngineNo != "" ? this.vehicleEngineNo: null;
      this.formProspectCheck.prospectDetail.vehicleIdentificationNo = null;
    }
    else if (this.verificationKeySelected == '5') {
      console.log(this.verificationKeySelected);
      this.formProspectCheck.prospectDetail.custId = null;
      this.formProspectCheck.prospectDetail.custNo = null;
      this.formProspectCheck.prospectDetail.vehicleLicensePlate = null;
      this.formProspectCheck.prospectDetail.vehicleEngineNo = null;
      this.formProspectCheck.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo != "" ? this.vehicleIdentificationNo: null;
    }

    this.apiUtil.invokeAPI("PROSPECT_CHECK", this.formProspectCheck).subscribe(res => {
      console.log(res);
      if(res.state.status == '200') {
        this.resultExisting = res.data.prospectDetailList[0].isExisting;
        this.resultExis = res.data.prospectDetailList[0].custAppNo;
        this.resultMobile = res.data.prospectDetailList[0].custMobileNo1;
        this.resultName = res.data.prospectDetailList[0].custName;
        this.resultAddress = res.data.prospectDetailList[0].custHomeAddress;
        this.resultBranch = res.data.prospectDetailList[0].branchName;
        this.resultType = res.data.prospectDetailList[0].custType;

        this.vehicleLicensePlateReturned = res.data.prospectDetailList[0].vehicleLicensePlate;
        this.vehicleIdentificationNoReturned = res.data.prospectDetailList[0].vehicleIdentificationNo;
        this.vehicleEngineNoReturned = res.data.prospectDetailList[0].vehicleEngineNo;

        this.existingTafSelected = this.resultExisting;
        this.custAppNoSelected = this.resultExis;
        this.custMobileNo1 = this.resultMobile;
        this.custName = this.resultName;
        this.custHomeAddress = this.resultAddress;
        this.branchIdSelected = this.resultBranch;
        this.typeSelected = this.resultType;
        this.formProspectCheck.prospectDetail.custAppNo = this.resultExis;
        if (this.existingTafSelected == "true") {
          this.toastr.success("Existing TAF Customer");
          this.existingTafSelected = "Yes";
          if (this.branchIdSelected){
            this.branchSelected = this.branchIdSelected;
          }
          if (this.typeSelected == "P") {
            this.typeSelected = "Personal";
          } else if (this.typeSelected == "C"){
            this.typeSelected = "Company";
          }
        } else{
          this.toastr.error("Not Existing TAF Customer");
          this.existingTafSelected = "No";
          this.getLovNonDataBase();
          // this.branchSelected = "Branch";
          // this.branchIdSelected = "Branch";
          
          this.branchOnChange(this.userInfo.branch.id, this.userInfo.branch.name);
        }
      }
      this.spinner.hide();
      
    },(err) => {
      console.log(err);
      this.toastr.error("Backend API Connection is Offline");
      this.spinner.hide();
	  });
  }
  submitForm(){
    if(this.custName == "" && this.custMobileNo1 == "" && this.branchSelected == 'Branch'){
      this.toastr.error("Please Fill Customer Name, Mobile Phone and TAF's Branch");
    } else if(this.custName != "" && ((this.custMobileNo1[0] == "0") && (this.custMobileNo1.length >= '5' && this.custMobileNo1 < '20')) && this.branchSelected != 'Branch'){
      this.submit();
    }
    else if(this.custName == "") {
      this.toastr.error("Please Fill Customer Name");
    }
    else if(this.custMobileNo1 == "") {
      this.toastr.error("Please Fill Mobile Phone");
    }
    else if(this.custMobileNo1[0] != '0') {
      this.toastr.error("Invalid Format Mobile Phone");
    }
    else if(this.custMobileNo1.length < '5' ) {
      this.toastr.error("Mobile number error at least 5 digits");
    }
    else if(this.branchSelected == 'Branch') {
      this.toastr.error("Please Fill TAF's Branch");
    }
  }

  submit(){
    // this.formProspect.id = this.idDetail;
    this.formProspect.userCrt = this.userInfo.userId;
    if(this.userInfo.isSiapDana == "Y") {
      this.formProspect.product = "SD";
    } else if (this.userInfo.isNewCar == "Y") {
      this.formProspect.product = "NC";
    }
    // this.formProspect.product = this.produsctSD;
    if (this.formProspect.product == "SD") {
      this.formProspect.prospectDetail.custName = this.custName != "" ? this.custName : null;
    if (this.verificationKeySelected == '1' ) {
          this.formProspect.prospectDetail.custId = this.custId != "" ? this.custId : null;
          this.formProspect.prospectDetail.custNo = this.custNo ;
          this.formProspect.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlate ;
          this.formProspect.prospectDetail.vehicleEngineNo = this.vehicleEngineNo ;
          this.formProspect.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo ;
    }
    if (this.verificationKeySelected == '2' ) {
          this.formProspect.prospectDetail.custId = this.custId;
          this.formProspect.prospectDetail.custNo = this.custNo != "" ? this.custNo : null;
          this.formProspect.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlate ;
          this.formProspect.prospectDetail.vehicleEngineNo = this.vehicleEngineNo ;
          this.formProspect.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo ;
    }
    if (this.verificationKeySelected == '3' ) {
          this.formProspect.prospectDetail.custId = this.custId;
          this.formProspect.prospectDetail.custNo = this.custNo;
          this.formProspect.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlate != "" ? this.vehicleLicensePlate : null ;
          this.formProspect.prospectDetail.vehicleEngineNo = this.vehicleEngineNo ;
          this.formProspect.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo ;
    }
    if (this.verificationKeySelected == '4' ) {
          this.formProspect.prospectDetail.custId = this.custId;
          this.formProspect.prospectDetail.custNo = this.custNo;
          this.formProspect.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlate ;
          this.formProspect.prospectDetail.vehicleEngineNo = this.vehicleEngineNo != "" ? this.vehicleEngineNo : null ;
          this.formProspect.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo ;
    }
    if (this.verificationKeySelected == '5' ) {
          this.formProspect.prospectDetail.custId = this.custId;
          this.formProspect.prospectDetail.custNo = this.custNo;
          this.formProspect.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlate ;
          this.formProspect.prospectDetail.vehicleEngineNo = this.vehicleEngineNo ;
          this.formProspect.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNo != "" ? this.vehicleIdentificationNo : null ;
    }

    this.formProspect.prospectDetail.vehicleLicensePlate = this.vehicleLicensePlateReturned != "" ? this.vehicleLicensePlateReturned : null;
    this.formProspect.prospectDetail.vehicleEngineNo = this.vehicleEngineNoReturned != "" ? this.vehicleEngineNoReturned : null;
    this.formProspect.prospectDetail.vehicleIdentificationNo = this.vehicleIdentificationNoReturned != "" ? this.vehicleIdentificationNoReturned :null;

    this.formProspect.branchId = this.branchIdSelected != "" ? this.branchIdSelected : null;
    this.formProspect.prospectDetail.custAgreementNo = this.custAgreementNo;
	  this.formProspect.prospectDetail.custAgreementStatus = this.custAgreementStatus;
    this.formProspect.prospectDetail.custMobileNo1 = this.custMobileNo1 != "" ? this.custMobileNo1 : null;
	  this.formProspect.prospectDetail.custMobileNo2 = this.custMobileNo2;
    this.formProspect.prospectDetail.custHomeAddress = this.custHomeAddress != "" ? this.custHomeAddress : null;
    this.formProspect.prospectDetail.notes1 = this.notes1 != "" ? this.notes1 : null;
    this.formProspect.prospectDetail.custType = this.typeKeySelected != "" ? this.typeKeySelected: null;
    this.formProspect.prospectDetail.custPhoneNo = this.custPhoneNo;
    this.formProspect.prospectDetail.isExisting = this.existingTafSelected == "Yes" ? 'Y' : 'N';
    this.formProspect.prospectDetail.custAppNo = this.formProspectCheck.prospectDetail.custAppNo;
    this.formProspect.prospectResult.attachment = this.base64ImgString != "" ? this.base64ImgString : null;
    
    this.apiUtil.invokeAPI("PROSPECT_ENTRY_DERO", this.formProspect).subscribe(res => {
      if(res.state.status == '200'){
        if(res.state.error.split(";")[0] == "400"){
          this.toastr.error(res.state.error.split(";")[1]);
        }
        else{
          this.toastr.success("Insert Success");
          this.router.navigate(['/homepage/prospect-dero']);
        }

        this.spinner.hide();
      }
      else{
        console.log(res);
        // this.toastr.error(res);
        this.spinner.hide();
      }
    }, 
    (err) => {
      console.log(err);
      // this.toastr.error(err);
      this.spinner.hide();
    });
  }
}
//   getNonDataBase(type) {
//     this.spinner.show();

//     let tmpRequest = {
//       type: type
//     };

//     this.apiUtil.invokeAPI("LIST_LOV", tmpRequest).subscribe(res => {
//       if(res.state.status == '200'){
//         this.nonDataBaseCheck = res.data;
//         this.nonDataBaseSelected = this.nonDataBaseCheck[0].value;
//         this.spinner.hide();
//       }

//     },(err) => {
//       console.log(err);
//       this.toastr.error(err);
//       this.spinner.hide();
// 	  });
//   }


}
