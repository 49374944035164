<div class="login container-fluid">
    <div class="row">
        <div class="col-sm-5 login-form-wrapper">
            <div class="brand-wrapper mb-80">
                <img src="../../assets/images/taf_logo.svg" alt="logo" class="logo">
            </div>
            <div class="col-sm-7 px-0 d-none logo-mobile">
                <img src="../../assets/images/tomi_toyota.svg" alt="login image">
            </div>
            <div class="login-wrapper my-auto">
                <h1 class="login-title text-blue text-bold">Welcome !</h1>
                <p class="font-roboto f-15r text-light-grey pb-20">Please log in to your account</p>

                <form class="custom-input">
                    <div class="form-group">
                        <div class="input-addon">
                            <img src="../../assets/images/icons/input-username.svg">
                        </div>
                        <input type="text" [(ngModel)]="inputUser" name="username" id="username" class="form-control" placeholder="Username" autocomplete="off">
                    </div>
                    <div class="form-group mb-4">
                        <div class="input-container">
                            <div class="input-addon">
                                <img src="../../assets/images/icons/input-pass.svg">
                            </div>
                            <input type="password" (keypress)="alphanumericOnly($event)" [(ngModel)]="inputPassword" id="password" name="password" class="form-control" placeholder="Password" autocomplete="off" (keydown.enter)="loginSubmit()">
                            <button type="button" class="btn btn-eye" id="sh-password"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <a [routerLink]="['/forgot-password']" class="forgot-password-link float-right text-large">Forgot Password</a>
                   <div class="p-relative single-button">
                    <button type="button" (click)="loginSubmit()" class="btn btn-block login-btn mt-80">Login</button>
                    <img src="../../assets/images/button/Button-right-arrow.png" class="right-arrow" alt="right-arrow">
                   </div>
                </form>
            </div>
        </div>
        <div class="col-sm-7 px-0 d-none d-sm-block login-img-wrapper">
            <img src="../../assets/images/tomi_toyota.svg" alt="login image" class="login-img h-100vh">
        </div>
    </div>
</div>
<ng-template #contentOtp let-modal>
    <div class="modal-header">
        <button type="button" class="close text-grey font-roboto" aria-label="Close" (click)="modal.dismiss('Cross click')" style="font-size: 30px !important;">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
      <div>
        <p class="text-largest text-blue">Please Enter OTP</p>
      </div>
      <div>
        <p class="text-small text-light-grey">Enter the OTP that we just send to your email *</p>
      </div>
      <div class="mt-25">
        <div class="row no-margin w-100">
          <div class="col-lg-12 col-sm-12 pl-0 pr-0">
              <div class="form-group">
                <input type="text" class="form-control" onkeyup="this.value = this.value.toUpperCase();" [(ngModel)]="inputOtp" id="inputOtp" name="inputOtp" placeholder="Please enter OTP code here" />
              </div>
          </div>    
        </div>
      </div>
    </div>
    <div class="modal-footer mt-15">
        <div class="row w-100">
            <p class="text-smaller text-light-grey">Don't receive the code ? <a href="javascript:void(0);" (click)="resendOtp()">Resend</a></p>
        </div>
        <div class="row w-100 div-text-center mt-10">
            <div class="col-lg-4 col-sm-6">
                <button type="button" class="btn btn-purple w-100" (click)="otpSubmit()" style="padding: 8px !important;">Submit</button>
            </div>
        </div>
    </div>
</ng-template>
<ngx-spinner></ngx-spinner>
