import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeLayoutComponent } from './layout/home-layout.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { NavbarModule } from './navbar/navbar.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProspectComponent } from './pages/prospect/prospect.component';
import { ProspectDetailComponent } from './pages/prospect-detail/prospect-detail.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ProspectNewComponent } from './pages/prospect-new/prospect-new.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CustomerHandlingComponent } from './pages/customer-handling/customer-handling.component';
import { CostumerHandlingDetailComponent } from './pages/costumer-handling-detail/costumer-handling-detail.component';
import { ProspectDetailRenewComponent } from './pages/prospect-detail-renew/prospect-detail-renew.component';
import { ProspectDetailUpgradeComponent } from './pages/prospect-detail-upgrade/prospect-detail-upgrade.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginExpiredComponent } from './pages/login-expired/login-expired.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ProspectNewCarComponent } from './pages/prospect-new-car/prospect-new-car.component';
import { ProspectCdeComponent } from './pages/prospect-cde/prospect-cde.component';
import { ProspectDetailSdCdeComponent } from './pages/prospect-detail-sd-cde/prospect-detail-sd-cde.component';
import { DashboardCdeComponent } from './pages/dashboard-cde/dashboard-cde.component';
import { DashboardShComponent } from './pages/dashboard-sh/dashboard-sh.component';
import { ProspectShComponent } from './pages/prospect-sh/prospect-sh.component';
import { ProspectShSroComponent } from './pages/prospect-sh-sro/prospect-sh-sro.component';
import { ProspectShSoComponent } from './pages/prospect-sh-so/prospect-sh-so.component';
import { CustomerHandlingShComponent } from './pages/customer-handling-sh/customer-handling-sh.component';
import { ProspectInsuranceComponent } from './pages/prospect-insurance/prospect-insurance.component';
import { ProspectNewCdeComponent } from './pages/prospect-new-cde/prospect-new-cde.component';
import { ProspectDetailShSoComponent } from './pages/prospect-detail-sh-so/prospect-detail-sh-so.component';
import { ProspectDetailShSroComponent } from './pages/prospect-detail-sh-sro/prospect-detail-sh-sro.component';
import { ProspectSroComponent } from './pages/prospect-sro/prospect-sro.component';
import { ProspectSoComponent } from './pages/prospect-so/prospect-so.component';
import { ProspectDetailSroComponent } from './pages/prospect-detail-sro/prospect-detail-sro.component';
import { ProspectDetailSoComponent } from './pages/prospect-detail-so/prospect-detail-so.component';
import { ProspectNewSoSdComponent } from './pages/prospect-new-so-sd/prospect-new-so-sd.component';
import { ProspectDetailCsoSdComponent } from './pages/prospect-detail-cso-sd/prospect-detail-cso-sd.component';
import { ProspectDetailCsoNcComponent } from './pages/prospect-detail-cso-nc/prospect-detail-cso-nc.component';
import { ProspectNewCsoSdComponent } from './pages/prospect-new-cso-sd/prospect-new-cso-sd.component';
import { ProspectNewCsoNcComponent } from './pages/prospect-new-cso-nc/prospect-new-cso-nc.component';
import { ProspectCsoComponent } from './pages/prospect-cso/prospect-cso.component';
import { ProspectTcsoComponent } from './pages/prospect-tcso/prospect-tcso.component';
import { ProspectJuroComponent } from './pages/prospect-juro/prospect-juro.component';
import { ProspectDeroComponent } from './pages/prospect-dero/prospect-dero.component';
import { ProspectNewTcsoNcComponent } from './pages/prospect-new-tcso-nc/prospect-new-tcso-nc.component';
import { ProspectNewTcsoSdComponent } from './pages/prospect-new-tcso-sd/prospect-new-tcso-sd.component';
import { ProspectNewJuroNcComponent } from './pages/prospect-new-juro-nc/prospect-new-juro-nc.component';
import { ProspectNewJuroSdComponent } from './pages/prospect-new-juro-sd/prospect-new-juro-sd.component';
import { ProspectNewDeroNcComponent } from './pages/prospect-new-dero-nc/prospect-new-dero-nc.component';
import { ProspectNewDeroSdComponent } from './pages/prospect-new-dero-sd/prospect-new-dero-sd.component';
import { ProspectAeComponent } from './pages/prospect-ae/prospect-ae.component';
import { DashboardBhComponent } from './pages/dashboard-bh/dashboard-bh.component';
import { DashboardSehComponent } from './pages/dashboard-seh/dashboard-seh.component';
import { CustomerHandlingCsoComponent } from './pages/customer-handling-cso/customer-handling-cso.component';
import { DashboardCsoComponent } from './pages/dashboard-cso/dashboard-cso.component';
import { ProspectBhComponent } from './pages/prospect-bh/prospect-bh.component';
import { ProspectSehComponent } from './pages/prospect-seh/prospect-seh.component';
import { CustomerHandlingSehComponent } from './pages/customer-handling-seh/customer-handling-seh.component';
import { CustomerHandlingBhComponent } from './pages/customer-handling-bh/customer-handling-bh.component';
import { ProspectNewAeSdComponent } from './pages/prospect-new-ae-sd/prospect-new-ae-sd.component';
import { ProspectNewAeNcComponent } from './pages/prospect-new-ae-nc/prospect-new-ae-nc.component';
import { ProspectShReassignComponent } from './pages/prospect-sh-reassign/prospect-sh-reassign.component';
import { ProspectSpvDeleteComponent } from './pages/prospect-spv-delete/prospect-spv-delete.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeLayoutComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    ProspectComponent,
    ProspectDetailComponent,
    ProspectNewComponent,
    CustomerHandlingComponent,
    CostumerHandlingDetailComponent,
    ProspectDetailRenewComponent,
    ProspectDetailUpgradeComponent,
    LoginExpiredComponent,
    ChangePasswordComponent,
    ProspectNewCarComponent,
    ProspectCdeComponent,
    ProspectDetailSdCdeComponent,
    DashboardCdeComponent,
    DashboardShComponent,
    ProspectShComponent,
    ProspectShSroComponent,
    ProspectShSoComponent,
    CustomerHandlingShComponent,
    ProspectInsuranceComponent,
    ProspectNewCdeComponent,
    ProspectDetailShSoComponent,
    ProspectDetailShSroComponent,
    ProspectSroComponent,
    ProspectSoComponent,
    ProspectDetailSroComponent,
    ProspectDetailSoComponent,
    ProspectNewSoSdComponent,
    ProspectDetailCsoSdComponent,
    ProspectDetailCsoNcComponent,
    ProspectNewCsoSdComponent,
    ProspectNewCsoNcComponent,
    ProspectCsoComponent,
    ProspectTcsoComponent,
    ProspectJuroComponent,
    ProspectDeroComponent,
    ProspectNewTcsoNcComponent,
    ProspectNewTcsoSdComponent,
    ProspectNewJuroNcComponent,
    ProspectNewJuroSdComponent,
    ProspectNewDeroNcComponent,
    ProspectNewDeroSdComponent,
    ProspectAeComponent,
    DashboardBhComponent,
    DashboardSehComponent,
    CustomerHandlingCsoComponent,
    DashboardCsoComponent,
    ProspectBhComponent,
    ProspectSehComponent,
    CustomerHandlingSehComponent,
    CustomerHandlingBhComponent,
    ProspectNewAeSdComponent,
    ProspectNewAeNcComponent,
    ProspectShReassignComponent,
    ProspectSpvDeleteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SidebarModule,
    NavbarModule,
    NgbModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgxSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
