import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Api } from 'src/app/utility/Api';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private router: Router,
    private apiUtil: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  inputUserName: any;
  inputUserEmail: any;

  ngOnInit(): void {
  }

  forgotPassSubmit(){
    this.spinner.show();

    let tmpRequest = {
		  username: this.inputUserName,
      email: this.inputUserEmail
    }

    this.apiUtil.invokeAPI("FORGOT_PASSWORD", tmpRequest).subscribe(
      res => {
        if(res.status == '200'){
          this.spinner.hide();
          this.toastr.success(res.error);
          this.router.navigate(['/']);
        }
        else{
          this.spinner.hide();
          this.toastr.error(res.error + " ; " + res.message);
        }
      }, 
      (err) => {
        console.log(err);
        this.toastr.error(err);
        this.spinner.hide();
      }
    );
  }

  forgotPassCancel(){
    this.router.navigate(['/']);
  }

}
